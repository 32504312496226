import { useContext } from 'react'
import LeftNav from '../../utils/LeftNav'
import NavItem from "../../utils/NavItem";
import {Context} from "../tracker/Context/GlobalState";
import {PortalContext} from "../../GlobalState";
import TrackerLink from "../tracker/Utils/TrackerLink";


export default function ManagementNav(props){
    const {
        collapsed,
        toggleCollapse,
    } = useContext(Context)
    const {employee:{admin}} = useContext(PortalContext)

	const manager = admin && admin > 0 && admin <= 10

    const links = [
        {
            title:'Maintenance',
            href:'/nml/maintenance/',
            icon:'fa-cogs',
            links:[
                {
                    title:'Assignments',href:'/nml/maintenance/assignments'
                },
                {
                    title:'Email Templates',href:'/nml/maintenance/email_templates'
                },
				manager && {
                    title:'Research',href:'/nml/maintenance/research'
                },
				manager && {
            		title:'Docuware Doc Types',href:'/nml/maintenance/docuware_doc_types'
				},
				{
					title:'Reviewers By State',href:'/nml/maintenance/reviewers_by_state'
				},
                {
                    title:'State Maintenance',href:'/nml/maintenance/state_maintenance'
                },
                // {
                //     title:'Template Editor',href:'/nml/maintenance/template_editor'
                // },
            ]
        },
    ]

    return(
        <LeftNav
            collapsed={collapsed}
            collapse={toggleCollapse}
            noSearch
            icon={'csg'}
            title={'Maintenance'}
            back={'/nml/tracker/dashboard'}
            backBtnTitle={'Go to Tracker'}
        >
            <TrackerLink/>
            {links.map(link=>(
                <NavItem
                    key={Math.random()}
                    info={link}
                    collapsed={collapsed}
                />
            ))}
        </LeftNav>
    )
}