import Head from 'next/head'
import NmlNav from "./NmlNav";
import MaintenanceLayout from "../maintenance/MainteanceLayout";
import ReportLayout from "../reports/ReportLayout";
import {useContext} from "react";
import {Context} from "./Context/GlobalState";
import {useRouter} from "next/router";
import RightNav from '../../../components/nml/tracker/file/RightNav';

export default function TrackerLayout({children}) {
	const {pathname} = useRouter()
	const {collapsed, fileBarCollapsed, selectedFile} = useContext(Context)
	return (
		<div className={'page-container'}>
			<Head><title>Portal Tracker</title></Head>
			<NmlNav/>
			<div className={'main-content' + (collapsed ? '-collapsed' : '')}>
				{pathname.startsWith('/nml/maintenance') ?
					<MaintenanceLayout>
						<Head><title>Portal Tracker Maintenance</title></Head>
						{children}
					</MaintenanceLayout>
					:
					pathname.startsWith('/nml/report') ?
						<ReportLayout>
							<Head><title>Portal Tracker Reports</title></Head>
							{children}
						</ReportLayout>
						:
						pathname.startsWith('/nml/tracker/request') ?
							<div className={'extra-content'}>
								<Head><title>New Request</title></Head>
								{children}
							</div>
							:
							pathname.startsWith('/nml/tracker/dashboard') ?
								children
								:
								<>
									<Head><title>Portal Tracker - {selectedFile?.ncs_lno}</title></Head>
									<div className={'file-content' + (fileBarCollapsed ? '-collapsed' : '')}>
										<div className={'m-3'}>
											{children}
										</div>
									</div>
									<RightNav/>
								</>
				}
			</div>
		</div>
	)
}