import _ from 'lodash';
import {
	NOTIFICATION_LETTER_MODAL,
	SEARCH_NOTIFICATION_LETTERS,
	CLEAR_LETTER_SEARCH,
	SET_NOTIFICATION_LETTERS,
	NOTIFICATION_LETTER_LOADING,
	REVIEW_NOTIFICATION_LETTER
} from './action';

export default function NotificationLetterModal(
    state = {
        notification_letter_modal:false,
        notification_letters:[],
        filtered_notification_letters:[],
        search_letters:'',
        isLoading:true
    },
    action
) {
	switch (action.type) {
		case SET_NOTIFICATION_LETTERS:
			return {...state,notification_letters:action.letters};
		case NOTIFICATION_LETTER_MODAL:
			return {...state,notification_letter_modal:action.open,search_letters:'',show_letters:1,notification_letters:[],filtered_notification_letters:[]};
		case SEARCH_NOTIFICATION_LETTERS:
			let letter_results = _.filter(state.notification_letters,function(letter){
				let regex = new RegExp(action.search+'.*',"i");
				return _.lowerCase(letter.sec_fulladd).match(regex) || _.lowerCase(letter.sec_comp).match(regex);
			});
			return {...state,filtered_notification_letters:letter_results,search_letters:action.search};
		case CLEAR_LETTER_SEARCH:
			return {...state,filtered_notification_letters:[],search_letters:''};
		case NOTIFICATION_LETTER_LOADING:
			return {...state,isLoading:action.set};
		case REVIEW_NOTIFICATION_LETTER:
			let index = _.findIndex(state.notification_letters,['nl_id',action.viewed.nl_id]);
			state.notification_letters[index].viewed = action.viewed.viewed;
			return {...state};
		default:
			return state;
	}
}