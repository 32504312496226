import Collapse from '../../../utils/Collapse'
import EmployeeDropdown from "../../../utils/EmployeeDropdown";
import React, {Fragment, useContext} from "react";
import {Context} from "../Context/GlobalState";

export default function NavQueue(props) {
	const {
		baseSearch,
		dropdowns,
		historyEmpId,
		setSearchAggs,
		queueEmpId
	} = useContext(Context)
	const selectedEmp = queueEmpId > 0 && dropdowns?.employees?.find(emp=>emp.EmpID===queueEmpId)

	const queues = [
		{
			name:'Notices',
			startOpen:true,
			total:19,
			sections:[
				{name:'Imports',count:5},
				{name:'Research',count:6},
				{name:'Prep',count:1},
				{name:'Review',count:4},
				{name:'Printing',count:3},
			]
		},
		{
			name:'Liens',
			startOpen:false,
			total:4,
			sections:[
				{name:'Research',count:2},
				{name:'Prep',count:1},
				{name:'Filing',count:1},
			]
		},
		{
			name:'Bond Claims',
			startOpen:false,
			total:2,
			sections:[
				{name:'Research',count:1},
				{name:'Prep',count:1},
			]
		},
		{
			name:'Bookmarked',
			startOpen:true,
			total:25,
			sections:[
				{name:'Sent to AFU',count:5},
				{name:'Waiting on Client',count:2},
				{name:'Jill Training',count:3},
				{name:'Quatrro',count:15},
			]
		},
	]

	return (
		<>
			{dropdowns?.employees?.length > 0 &&
			<div className={'form-group'}>
				<div className={'row'}>
					<div className={'col-12'}>
						<EmployeeDropdown
							employees={dropdowns.employees}
							btnStyle={'outline-secondary border-0 text-left'}
							onClick={(empId) => {
								baseSearch(empId,historyEmpId)
								setSearchAggs({})
							}}
							title={
								selectedEmp ?
									<Fragment>
										{selectedEmp.FirstName} {selectedEmp.LastName}
										<span className={'float-right'}>
													<span className={'fas fa-caret-down'}/>
												</span>
									</Fragment>
									:
									'-- Select --'
							}
							noDropdownToggle={true}
						/>
					</div>
				</div>
			</div>
			}
			{queues.map((queue,i)=>(
				<Collapse
					className={i===0?'pt-1':' border-top pt-2 mt-3'}
					title={<><strong>{queue.name} {queue?.total && '('+queue?.total+')'}</strong></>}
					startOpen={queue.startOpen}
					key={i}
				>
					{queue?.sections?.map((section,i)=>(
						<div className={'row py-1'} key={i}>
							<div className={'col-12'}>
								<button className={'btn btn-block btn-outline-secondary border-0 text-left'}>
									{section?.name} <span className={'float-right'}>{section?.count}</span>
								</button>
							</div>
						</div>
					))}
				</Collapse>
			))}
		</>
	)
}