import {useState, useEffect, useContext} from 'react'
import {AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal} from "@azure/msal-react";
import NmlLayout from "./nml/tracker/NmlLayout";
import UCCLayout from "./ucc/UCCLayout";
import ClientMaintenanceLayout from "./clientMaintenance/ClientMaintenanceLayout";
import ToolsLayout from "./tools/ToolsLayout";
import AdministratorLayout from "./administrator/AdministratorLayout";
import {PortalContext} from "./GlobalState";
import Header from "./Header";
import {InteractionType} from "@azure/msal-browser";

export default function AppLayout(props) {
	const {auth,employee} = useContext(PortalContext)
	const isAuthenticated = useIsAuthenticated()
	const msal = useMsal();
	const {inProgress} = msal

	useEffect(()=>{
		if(!auth.loading && !employee?.api_key && auth?.user?.email){
			auth.getEmployee(auth?.user?.email)
		}
	},[auth,employee])

	useEffect(()=>{
		// console.log('initial load',inProgress)
		if(inProgress===InteractionType.None && !isAuthenticated){
			// console.log('no auth user')
			auth.signIn()
		}
	},[inProgress,isAuthenticated])

	function clearLocalAndReload() {
		if (window) {
			window.localStorage.clear()
			window.location.reload()
		}
	}

	// console.log('auth',auth?.user)

	return (
		<>
			<Header/>
			<UnauthenticatedTemplate>
				<div className={'row'}>
					<div className={'col-12'}>
						<div className={'align-center mt-3 text-center'}>
							{auth?.error?.message?.length > 0 && <h4 className={'text-danger'}>*{auth?.error?.message}*</h4>}
							Not signed in <br />
							<button
								className={'btn btn-primary'}
								onClick={auth.signIn}
							>
								<span className={'far fa-sign-in'}/> Sign In
							</button>
							<div>
								<button className={'btn btn-secondary mt-2'} onClick={clearLocalAndReload}>
									<span className={'far fa-times'}/> Clear and Refresh
								</button>
							</div>
						</div>
					</div>
				</div>
			</UnauthenticatedTemplate>
			<AuthenticatedTemplate>
				{employee?.api_key ?
					<AppDisplay {...props}/>
					:
					<div className={'row'}>
						<div className={'col-12 text-center'}>
							<h4>No Employee Info</h4>
							{auth.loading && <h4><span className={'far fa-spinner fa-spin'}/> Loading...</h4>}
							{auth?.user?.email && !employee?.api_key &&
							<button
								className={'btn btn-primary'}
								onClick={()=>{auth.getEmployee(auth?.user?.email)}}
								disabled={auth.loading}
							>
								<span className={'far fa-'+(auth?.loading?'spinner fa-spin':'plus')}/> Get Info
							</button>}
							<div>
								<button className={'btn btn-secondary mt-2'} onClick={clearLocalAndReload}>
									<span className={'far fa-times'}/> Clear and Refresh
								</button>
							</div>
						</div>
					</div>
				}
			</AuthenticatedTemplate>
		</>
	)
}

function AppDisplay({router,pageProps,Component}){
	return router.pathname.startsWith('/nml') ?
		<NmlLayout>
			<Component {...pageProps}/>
		</NmlLayout>
		:
		router.pathname.startsWith('/ucc') ?
			<UCCLayout>
				<Component {...pageProps}/>
			</UCCLayout>
			:
			router.pathname.startsWith('/client') ?
				<ClientMaintenanceLayout>
					<Component {...pageProps}/>
				</ClientMaintenanceLayout>
				:
				router.pathname.startsWith('/tools') ?
					<ToolsLayout>
						<Component {...pageProps}/>
					</ToolsLayout>
					:
					router.pathname.startsWith('/administrator') ?
						<AdministratorLayout>
							<Component {...pageProps}/>
						</AdministratorLayout>
						:
						<Component {...pageProps}/>
}