import dayjs from 'dayjs'
// import customParseFormat from 'dayjs/plugin/customParseFormat'
// dayjs.extend(customParseFormat)

export default function formatDate(date,format = 'MM/DD/YYYY'){
	if(date) {
		if(date == '0000-00-00'){
			return 'None';
		}
		else if(date == '0000-00-00 00:00:00'){
			return 'TBD';
		}
		else {
			let return_date = dayjs(date).format(format);
			// let day = dayjs(date).format(format)
			// console.log('day js format',day)
			return return_date == '12/31/1969' ? 'None':return_date;
		}
	}
	else{
		return '';
	}
}