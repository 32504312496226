import React, {useState, useEffect, Fragment} from 'react'
import JsonCompare from "../../utils/JsonCompare";

export default function AddPerson({contacts,update,list}) {
	const [ toList, setToList ] = useState([])
	const [ addNewPerson, setAddNewPerson ] = useState('')

	useEffect(()=>{
		JsonCompare(list,toList) && setToList(list)
	},[list])

	function getInputPosition(){
		const { offsetTop,offsetLeft,clientHeight } = document.getElementById('newPersonSearch')
		return {
			top:offsetTop+clientHeight,
			left:offsetLeft
		}
	}

	function updateToList(newToList){
		setToList(newToList)
		update(newToList)
	}

	function filteredContacts(){
		const list = contacts.filter(c=>(
			c.FirstName.toLowerCase().indexOf(addNewPerson.toLowerCase()) > -1 ||
			c.LastName.toLowerCase().indexOf(addNewPerson.toLowerCase()) > -1
		))

		if(list.length === 1){
			updateToList([...toList,{...list[0]}])
			setAddNewPerson('')
			return contacts
		}
		else{
			return list
		}
	}

	function onkeydown({key}){
		if(key === 'Backspace' && addNewPerson.length === 0){
			const updatedList = [...toList]
			updatedList.pop()
			updateToList(updatedList)
		}
	}

	function onChange({target}){
		setAddNewPerson(target.value)
	}

	return (
		<Fragment>
			<div className={'toList'} style={{padding:5,margin:5,borderBottom:'1px solid rgb(222, 226, 230)'}}>
				{toList.map(to=>(
					<span
						style={{padding: "8px", margin: "4px", transition: '.5s'}}
						key={to.cnt_id}
						className="badge badge-default"
					>
										{to.FirstName} {to.LastName}
						<span
							style={{marginLeft: "4px"}}
							className="far fa-times pointer"
							onClick={() => {updateToList(toList.filter(t=>t.cnt_id !== to.cnt_id))}}
						/>
									</span>
				))}
				<input
					className="type-tags-input form-control"
					id={'newPersonSearch'}
					type="text"
					style={{
						border:"0px",
						boxShadow: "inset 0px 0px 0px 0px white",
						width: "200px",
						display: 'inline-block',
						paddingLeft: "0px",
						borderBottom: '1px solid rgb(222, 226, 230)'
					}}
					placeholder="Add New Person"
					value={addNewPerson}
					onChange={onChange}
					onKeyDown={onkeydown}
				/>
				{addNewPerson?.length > 0 &&
				<ul
					className={'list-group'}
					style={{
						maxWidth:400,
						position:"absolute",
						top:getInputPosition().top,
						left:getInputPosition().left,
						maxHeight:500,
						overflow:'auto'
					}}
				>
					{filteredContacts().map(contact=>(
						contact?.cnt_id &&
						<li
							key={contact.cnt_id}
							className={'list-group-item pointer'}
							onClick={()=>{
								updateToList([...toList,{...contact}])
								setAddNewPerson('')
							}}
						>
							{contact.FirstName} {contact.LastName}
						</li>
					))}
				</ul>}
			</div>
			<h4 className={'p-3'}>No Messages</h4>
		</Fragment>
	)
}