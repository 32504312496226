import axios from '../../axios'
import {useMsal} from "@azure/msal-react";

export function email(cabinet,contacts,file,service){
	if(cabinet === 'nml'){
		const {
			ncs_lno,
			cnt_id,
			reference_no,
			debtor,
			name
		} = file || {}
		const subject =
			`NCS #: ${ncs_lno}`+
			(reference_no ? ` / Your File #: ${reference_no}`:``)+
			(debtor ? ` / Debtor: ${debtor?.name}`:``)+
			(name ? ` / Project: ${name}`:``)

		return {
			subject,
			to:cnt_id && contacts?.length > 0 ? contacts.filter(contact=>parseInt(contact.CNT_ID) === parseInt(cnt_id))?.[0]?.CNTEMAIL : []
		}
	}
	else if(cabinet === 'ucc'){
		return {
			subject:('NCS Credit UCC Service: UCC#'+file?.ucc_no + " (" + file?.debtor1 + ") " + (file?.debt_cr !== "" && file?.debt_cr != null ?  "Client# " + file?.debt_cr : '')),
			to: service?.cntemail?.length > 0 ? [service?.cntemail] : []
		}
	}
	else{
		return {
			subject: '',
			to: ''
		}
	}
}