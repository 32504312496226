import React, {useState, useEffect, useContext, Fragment, useRef } from 'react'

export default function CaptureButton(props) {
	const canvasRef = useRef(null)

	function takeScreenshot() {
		navigator.mediaDevices.getDisplayMedia()
			.then(stream => {
				const track = stream.getVideoTracks()[0]
				const imageCapture = new ImageCapture(track);
				setTimeout(()=>{
					imageCapture.grabFrame()
						.then(imageBitMap=>{
							canvasRef.current.width = imageBitMap.width
							canvasRef.current.height = imageBitMap.height
							canvasRef.current.getContext('2d').drawImage(imageBitMap,0,0)
							track.stop()
							props.capture(canvasRef.current.toDataURL())
						})
						.catch(err => {
							console.log('image cap error',err)
							track.stop()
						})
				},500)
			})
			.catch(error => {
				console.log('media error',error)
			})
	}
	// console.log('image',image)
	return (
		<Fragment>
			<button
				className={'btn btn-default '+(props.className?props.className:'')}
				onClick={takeScreenshot}
				type={'button'}
			>
				<span className={'far fa-plus'}/> Screenshot
			</button>
			<canvas ref={canvasRef} style={{display:'none'}} />
		</Fragment>
	)
}