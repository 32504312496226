import React from "react";

export default function TypeIcon({type, size}){
	const iconSize = size ?? ''
	switch(type) {
		case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
		case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
		case "application/vnd.ms-word.document.macroEnabled.12":
		case "application/vnd.ms-word.template.macroEnabled.12":
		case "application/msword":
			return <span className={"fas fa-file-word "+iconSize}/>;
		case "application/vnd.ms-excel":
		case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
			return <span className={"fas fa-file-excel "+iconSize}/>;
		case "application/pdf":
			return <span className={"fas fa-file-pdf "+iconSize}/>;
		case "image/png":
			return <span className={"fas fa-image "+iconSize}/>
		default:
			return <span className={"fas fa-file "+iconSize}/>;
	}
}