import GlobalState from "./Context/GlobalState";
import NavItem from "./Components/NavItem";
import Modal from './Components/Modal'

export default function Index(props){
	return(
		<GlobalState>
			<NavItem/>
			<Modal/>
		</GlobalState>
	)
}