import _ from 'lodash';
import {
	FETCH_UCC_LIST,
	SHOW_MORE,
	SORT_UCC_LIST,
	CHANGE_SORT_DIRECTION,
	CHANGE_SELECTED,
	FIND_DIMENSIONS,
	CHANGE_FILE_LIST_LOADING,
	UPDATE_FILE,
	UPDATE_PARAMETERS,
	REMOVE_FILE,
	UPDATE_TIME,
	UPDATE_CURRENT_IN,
	MOVE_SELECTED,
	MOVE_UP_LIST,
	openFile
} from './action';

const defaultState = {
	end:100,
	sortBy:'first_follow_up',
	sortDirection:'asc',
	isLoading:false,
	file_list_height:900,
	updated:new Date(),
	files:[]
}

export function sortList(files,sortBy,sortDirection) {
	// console.log('sorting list',{files,sortBy,sortDirection})
	let sorted;
	if(sortBy == 'STATE_EXP'){
		let no_exp = [];
		let exp = [];
		_.forEach(files,file => {
			if(file[sortBy] == '0000-00-00' || null){
				no_exp.push(file);
			}
			else{
				exp.push(file);
			}
		});
		let sorted_exp = _.values(_.orderBy(exp,[sortBy],[sortDirection]));
		sorted = _.concat(sorted_exp,no_exp);
	}
	else if(files && files.length > 0 && files[0] != false){
		sorted = _.values(_.orderBy(files, function (file) {
			if (file[sortBy] !== null) {
				if(sortBy == 'UCC_NO'){
					return file[sortBy];
				}
				else{
					return file[sortBy].toUpperCase()
				}
			} else {
				console.log('null sort');
			}
		}, [sortDirection]));
	}

	return _.orderBy(sorted,['priority'],['desc']);
}

export default function FileListReducer(state = {...defaultState}, action) {
	switch (action.type) {
		case FETCH_UCC_LIST:
			// console.log('fetching list',action)
			if(action.list[0] == false || _.isEmpty(action.list) || action.list.result == false){
				// console.log('list is empty',action)
				return {...state, files: action.list};
			}
			const files = sortList(action.list, state.sortBy, state.sortDirection);
			let selectedUcc = files[0].UCC_NO;
			// console.log('sorted',files,selectedUcc)
			return {...state,files,selectedUcc};
		case SHOW_MORE:
			return {...state,end:action.payload};
		case SORT_UCC_LIST:
			return {...state,files:action.sort_list,sortBy:action.sort,selectedUcc:action.sorted_selected_ucc,end:100};
		case CHANGE_SORT_DIRECTION:
			return {...state,files:action.sorted_files,sortDirection:action.sortDirection,selectedUcc:action.selectedUcc,end:100};
		case CHANGE_SELECTED:
			return {...state,selectedUcc:action.payload};
		case FIND_DIMENSIONS:
			return {...state,file_list_height:action.file_list_height};
		case CHANGE_FILE_LIST_LOADING:
			return {...state,isLoading:action.set};
		case UPDATE_FILE:
			return {...state,files:state.files.map(file=>{
				if(file.UCC_NO == action.file.UCC_NO){
					// console.log('changing',action.file,file)
					return action.file
				}
				else{
					return file
				}
			})}
		case UPDATE_PARAMETERS:
			return {...state,sortDirection:'asc',end:100,...action.parameters};
		case REMOVE_FILE:
			let new_files = [];
			if(state.files) {
				state.files.map(row => {
					if (row.UCC_NO !== action.ucc_no) {
						new_files.push(row);
					}
				});
				return {...state,files:new_files,selectedUcc:new_files[0].UCC_NO};
			}
			else{
				return {...state};
			}
		case UPDATE_TIME:
			return {...state,updated:new Date()};
		case UPDATE_CURRENT_IN:
			if(!state?.files?.result && state?.files?.length > 0) {
				const updateCurrentIn = state?.files?.map(file=> {
					const current_in = action.current_in.filter(current => (current.ucc_no == file.UCC_NO))[0]
					// console.log('update current in',action.current_in,file,current_in)
					if (current_in) {
						return {
							...file,
							current_in: current_in.emp_id,
							current_in_emp_name: current_in.current_in_emp_name
						}
					} else {
						return {...file, current_in: null, current_in_emp_name: null}
					}
				})
				return {...state,files:[...updateCurrentIn]}
			}
			else{
				return {...state};
			}
		case MOVE_SELECTED:
			return {...state,selectedUcc:action.selectedUcc}
		case MOVE_UP_LIST:
			let current_move_index = _.findIndex(state.files,['UCC_NO',state.selectedUcc]);
			if(current_move_index > 0){
				current_move_index--;
				const selectedUcc = state.files[current_move_index].UCC_NO
				openFile(selectedUcc)
				return {...state, selectedUcc};
			}
			else{
				return {...state}
			}
		default:
			return state;
	}
}