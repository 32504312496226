import axios from "../../axios";

import {
	updateFile,
	setIsLoading,
	isEmpty,
	setAlerts,
	CHANGE_FILE_DETAIL_LOADING,
	CHANGE_FILE_LIST_LOADING,
	NOTE_SERVICES,
} from './action'
import Cookies from 'js-cookie'
const employee = Cookies.getJSON('employee')
const getEmpID = function(){return Cookies.getJSON('employee')?.emp_id}

export const SET_NOTES = 'set_notes';
export function setNotes(notes){
	return dispatch => {
		dispatch({
			type:SET_NOTES,
			notes:notes.notes,
			filtered:notes.filtered
		})
	}
}
export function getNotes(ucc_no){
	const emp_id = getEmpID()
	return dispatch => {
		dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING,true))
		axios.get(`/portal/ucc/1/notes/${ucc_no}/${emp_id}/json`)
			.then(({data}) => {
				dispatch(setNotes(data))
			})
			.then(()=>{
				dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING,false))
			})
	}
}

export function saveNote(note) {
	const emp_id = getEmpID()
	note.emp_id = emp_id;
	return dispatch => {
		dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING,true))
		if(!isEmpty(note.reply)){
			axios.post(`/portal/ucc/1/reply/note/json`,note)
				.then(({data}) => {
					dispatch(setNotes(data.notes));
					dispatch(updateFile(data.file));
					dispatch(setAlerts(data.alerts))
				})
				.then(()=>{
					dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING,false))
				})
		}
		else if (_.toInteger(note.id) > 0) {
			axios.put(`/portal/ucc/1/update/note/json`,note)
				.then(({data}) => {
					dispatch(setNotes(data.notes));
					dispatch(updateFile(data.file));
					dispatch(setAlerts(data.alerts))
				})
				.then(()=>{
					dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING,false))
				})
		}
		else {
			axios.post(`/portal/ucc/1/add/note/json`,note)
				.then(({data}) => {
					dispatch(setNotes(data.notes));
					dispatch(updateFile(data.file));
					dispatch(setAlerts(data.alerts))
				})
				.then(()=>{
					dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING,false))
				})
		}
	}
}

export function deleteNote(id){
	const emp_id = getEmpID()
	return dispatch => {
		dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING,true))
		axios.patch(`/portal/ucc/1/delete/note/${id}/${emp_id}/json`)
			.then(({data}) => {
				dispatch(setNotes(data.notes))
				dispatch(setAlerts(data.alerts))
				dispatch(updateFile(data.file));
			})
			.then(()=>{
				dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING,false))
			})
	}
}

const defaultState = {
	notes:[],
	filtered:[],
	services:[]
}

export default function NoteReducer(state = {...defaultState}, action) {
	switch (action.type) {
		case SET_NOTES:
			return {...state,notes:action.notes,filtered:action.filtered}
		case NOTE_SERVICES:
			return {...state,services:action.services}
		default:
			return state;
	}
}
