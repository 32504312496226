import React, {useState, useContext, Fragment} from 'react'
import SortableTable from "../../utils/SortableTable";
import Viewer from "react-viewer";
import axios from "../../axios";
import {PortalContext} from "../../GlobalState";

export default function AddGDrive({docs,addDocuments,docsToAdd,uploadedDocs,download,moveToGDrive}) {
	const {employee} = useContext(PortalContext)
	const [ inDirectory, setInDirectory ] = useState([])
	const [ previewImages, setPreviewImages ] = useState([])
	const [ showViewer, setShowViewer ] = useState(false)
	const [ showUpload, setShowUpload ] = useState(false)
	const api_url = `${process.env.API_HOST}/docuware/3/g_drive/preview_viewer/json?api_key=${employee.api_key}`

	function openClick(currentState,newState){
		setInDirectory([...inDirectory,newState.name])
	}

	function backClick(){
		const directory = inDirectory
		directory.pop()
		setInDirectory([...directory])
	}

	function findDirectory(){
		let docList = showUpload ? uploadedDocs : docs
		if(inDirectory.length > 0){
			for (let i = 0; i < inDirectory.length; i++) {
				docList = docList.find(doc=>doc.name === inDirectory[i])?.files ?? []
			}
		}
		return docList.filter(doc=>!docsToAdd.find(add=>add.gDrive && add.location === doc.location))
	}

	function closeViewer(){
		setShowViewer(false)
	}

	function showUploadedDocs(){
		setShowUpload(!showUpload)
	}

	function getGDrivePreview(name){
		axios.get(`${api_url}&filename=${encodeURIComponent(name)}&uploaded=${showUpload}`)
			.then(({data})=>{
				setPreviewImages(data)
				setShowViewer(true)
			})
	}

	const customRow = (row)=>(
		<Fragment>
			{!showUpload && <button
				className={'btn btn-outline-secondary float-right'}
				onClick={()=>{
					if(row.is_directory){
						openClick(docs,row)
					}
					else {
						addDocuments([{...row}], true);
					}
				}}
			>
				{row.is_directory ?
					<Fragment><span className={'far fa-folder-open'}/> Open</Fragment>
					:
					<Fragment><span className={'far fa-plus'}/> Add</Fragment>
				}
			</button>}
			{!row.is_directory &&
			<Fragment>
				{showUpload && <button
					className={'btn btn-outline-secondary float-right mr-1'}
					onClick={()=>{moveToGDrive(row.name)}}
				>
					<span className={'far fa-file-export'}/>
				</button>}
				{/*<button*/}
				{/*	className={'btn btn-outline-secondary float-right mr-1'}*/}
				{/*	onClick={()=>{download(showUpload,row.name)}}*/}
				{/*>*/}
				{/*	<span className={'far fa-download'}/>*/}
				{/*</button>*/}
				{row.name.includes('.pdf') &&
				<button
					className={'btn btn-outline-secondary float-right mr-1'}
					onClick={()=>{getGDrivePreview(row.name)}}
				>
					<span className={'far fa-eye'}/>
				</button>
				}
			</Fragment>
			}
		</Fragment>
	)

	return (
		<div className={'col-12 mt-2'}>
			<h4 className={'d-flex align-items-center justify-content-between'}>
				{showUpload ? 'Uploaded': 'G Drive'} Docs
				<span className={'float-right'}>
					<button className={'btn btn-outline-secondary'} onClick={showUploadedDocs} type={'button'}>
						<span className={'far fa-folder-open'}/> {showUpload ? 'Files' : 'Uploaded'}
					</button>
				</span>
			</h4>
			{inDirectory?.length > 0 &&
			<div className={'row mb-2'}>
				<div className={'col-12 d-flex align-items-center'}>
					<button className={'btn btn-outline-secondary'} onClick={backClick}type={'button'}>
						<span className={'far fa-chevron-left'}/> Back
					</button>
					<h5 className={'mb-0 ml-2'}>In {inDirectory[inDirectory.length - 1]}</h5>
				</div>
			</div>
			}
			<SortableTable
				headers={[
					{ name:'Name', value:'name'},
					{ name:'Created', value:'created', date:true },
					{
						name: !showUpload &&
							<button
								className={'btn btn-outline-secondary float-right'}
								onClick={() => {
									const docs = findDirectory().filter(doc => !doc.is_directory)
									addDocuments([...docs], true);
								}}
								type={'button'}
							>
								<span className={'far fa-plus'}/> All
							</button>,
						value: true,
						custom: customRow
					}
				]}
				info={findDirectory()}
			/>
			<Viewer
				onClose = {closeViewer}
				visible = {showViewer}
				images = {previewImages}
				activeIndex = {0}
				zIndex={2000}
				customToolbar = {(toolbar) => {
					return [...toolbar, {
						key: 'dw-link',
						render: <span
							className={'far fa-external-link-alt'}
						/>,
						onClick: ()=>{window.open(url, "_blank");}
					}]
				}}
				scalable={false}
				zoomSpeed={.5}
				onMaskClick={closeViewer}
				noImgDetails={true}
				downloadable={false}
			/>
		</div>
	)
}