import React, { useState, useEffect, useReducer } from 'react'
import axios from "../../../axios"
export const ROOT_URL = '/ncs/employee/1'
export const startPath = '/administrator/emp_maintenance'
export const EmployeeContext = React.createContext({
	employees:[]
})

export default function EmployeeMaintenanceContext(props){
	// const [ employees, dispatch ] = useReducer(employeeReducer,{employees:[]})
	const [ employees, setEmployees ] = useState([])
	const [ isLoading, setLoading ] = useState(true)
	const [ states, setStates ] = useState([])

	useEffect(()=>{
		axios.get(`${ROOT_URL}/maintenance/json`)
			.then(({data}) => {
				setEmployees(data)
				setLoading(false)
			})
		axios.get(`${ROOT_URL}/state-codes/json`)
			.then(({data}) => {
				setStates(data)
			})
	},[])

	return(
		<EmployeeContext.Provider
			value={{
				employees,
				setEmployees,
				isLoading,
				states
			}}
		>
			{props.children}
		</EmployeeContext.Provider>
	)
}