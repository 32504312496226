import React, {useState, useEffect, useContext, Fragment} from 'react'
import {DocuwareContext} from "../Context/GlobalState";
import Footer from "./Footer";
import ServiceDropdown from "./ServiceDropdown";
import TypeIcon from "../../utils/TypeIcon";
import FileUploadBtn from "../../utils/FileUploadBtn";
import CaptureButton from "../../utils/CaptureButton";
import FileDropper from "../../utils/FileDropper";
import makeKeysLowerCase from "../../utils/makeKeysLowerCase";
import LoadingSpinner from "../../utils/LoadingSpinner";
import AddGDrive from "./AddGDrive";
import NotificationLetterDropdown from './NotificationLetterDropdown'
import Alert from "../../utils/Alert";

export default function DocAdd(props) {
	const {
		nlId,
		docsToAdd,
		docTypes,
		setDocTypes,
		cabinet,
		changeDocsToAdd,
		addScreenshot,
		modal,
		uploadDocsToDocuware,
		addDocsToUpload,
		setDocsToAdd,
		combineDocsToDocuware,
		removeDocFromAdd,
		services,
		setServices,
		service,
		gDriveDocs,
		getGDriveDocs,
		gDriveLoading,
		gDriveUploadedDocs,
		getUploadedGDriveDocs,
		downloadGDrive,
		moveToGDrive,
		getDocumentTypes,
		docsToAddError,
		removeDocErrorMessage
	} = useContext(DocuwareContext)
	const [ combineDocs, setCombineDocs ] = useState(false)
	const initialCombineInfo = {
		view_online:false,
		document_type:props.docType?props.docType:docTypes?.length > 0 ? docTypes[0].value:'',
		serv_id:'',
		note:''
	}
	if(cabinet === 'ucc'){
		initialCombineInfo.nl_id = null
	}
	const [ combineInfo, setCombineInfo ] = useState({...initialCombineInfo})
	const [ docAddLoading, setDocAddLoading ] = useState(false)
	const [ dragOver, setDragOver ] = useState(false)

	useEffect(()=>{
		getGDriveDocs()
		getUploadedGDriveDocs()
	},[])

	useEffect(()=>{
		docTypes?.length === 0 && getDocumentTypes()
	},[docTypes])

	useEffect(()=>{
		// console.log('use effect',props.docType)
		if(props.docType){
			// console.log('setting doc type',props.docType)
			setDocTypes([{value:props.docType}])
			changeCombineInfo('document_type',props.docType)
		}
	},[props.docType])

	useEffect(()=>{
		if(props.serv_id){
			setServices({open:[{...props.service}],closed:[]})
		}
	},[props.service])

	useEffect(()=>{
		docsToAdd?.length <= 1 && setCombineDocs(false)
		setDocAddLoading(false)
	},[docsToAdd])

	useEffect(()=>{
		changeCombineInfo('document_type',props.docType?props.docType:docTypes?.[0]?.value)
	},[docTypes])

	useEffect(()=>{
		// console.log('services',services)
		let setService
		if(service){
			setService = service
		}
		else{
			setService = props.serv_id ? props.serv_id : services?.open ? services.open[0] : services?.closed ? services?.closed[0] : ''
		}
		setService = makeKeysLowerCase(setService)
		// console.log('set service',setService,service)
		let value = ''
		if(cabinet === 'ucc'){
			value = setService?.serv_id ?? ''
		}
		else if(cabinet === 'nml'){
			value = props.serv_id ? props.serv_id : setService?.serv_key ?? ''
		}
		changeCombineInfo('serv_id',value)
	},[services])

	useEffect(()=>{
		if(nlId){
			changeCombineInfo('nl_id',nlId)
		}
	},[nlId])

	function changeCombineInfo(name,value){
		setCombineInfo({...combineInfo,[name]:value})
	}

	function uploadClick(){
		setDocAddLoading(true)
		if(combineDocs){
			combineDocsToDocuware(combineInfo,()=>{
				// setCombineDocs(false)
				setDocAddLoading(false)
				// setCombineInfo({...initialCombineInfo})
				if(props.onSubmit){
					props.onSubmit()
				}
			})
		}
		else{
			uploadDocsToDocuware(props.onSubmit?props.onSubmit:false)
		}
	}

	function addDocuments(files,gDrive = false){
		addDocsToUpload(files,props.serv_id ?? false,gDrive)
	}

	const propsService = props.service ? makeKeysLowerCase(props.service) : undefined

	// console.log('docs to add',docsToAdd,combineInfo)

	return (
		<Fragment>
			<FileDropper setDragState={(val)=>{setDragOver(val)}} fileRetrieved={addDocuments}>
				{docsToAddError?.length > 0 &&
					docsToAddError?.map((error,i)=>{
						return <Alert key={i} onClick={()=>{removeDocErrorMessage(error.id)}}>
							{error.desc}
						</Alert>
					})
				}
				<div className={modal?'modal-body mt-0':'mt-3'}>
					<div className={'row'}>
						<div className={'col-12'}>
							<FileUploadBtn onChange={(e) => {
								addDocuments(e.target.files)
							}}/>
							<CaptureButton className={'ml-1'} capture={(img)=>{addScreenshot(img,props.docType,props.serv_id)}}/>
							{docsToAdd?.length > 1 &&
							<div className={'form-check form-check-inline ml-2'}>
								<input
									className={'form-check-input pointer'}
									type={'checkbox'}
									checked={combineDocs}
									onChange={() => {
										setCombineDocs(!combineDocs)
									}}
								/>
								<label
									className={'form-check-label pointer'}
									onClick={() => {
										setCombineDocs(!combineDocs)
									}}
								>
									Combine Documents
								</label>
							</div>
							}
						</div>
						{combineDocs &&
						<div className={'col-12 mt-3'}>
							<div className={'card'}>
								<div className={'card-header'}>
									<strong className={'pl-2'}>Combine Docs</strong>
								</div>
								<div className={'card-body'}>
									<div className={'form-row'}>
										<div className={'form-group col-sm-2'}>
											<div className="custom-control custom-switch">
												<input
													type="checkbox"
													className="custom-control-input"
													id="combineSwitch"
													checked={combineInfo.view_online}
													onChange={() => {
														changeCombineInfo('view_online', !combineInfo.view_online)
													}}
												/>
												<label className="custom-control-label" htmlFor="combineSwitch">View
													Online</label>
											</div>
										</div>
										<div className={'form-group col'}>
											<label>Type</label>
											{props.serv_id ?
												<h5>{combineInfo.document_type}</h5>
												:
												<select
													className={'form-control'}
													onChange={({target}) => {
														changeCombineInfo('document_type', target.value)
													}}
													value={combineInfo.document_type}
												>
													{docTypes.map(type => (
														<option key={type.value}
																value={type.value}>{type.value}</option>
													))}
												</select>
											}
										</div>
										{cabinet !== 'cli' && <div className={'form-group col'}>
											<label>Service</label>
											{props.docType ?
												<h5>
													{cabinet === 'nml' ?
														propsService?.svc_text
														:
														(propsService.serv_desc + (propsService.amendtype ? '('+(propsService.amendtype)+')' : ''))
													}
												</h5>
												:
												<ServiceDropdown
													value={combineInfo.serv_id}
													onChange={({target}) => {
														changeCombineInfo('serv_id', target.value)
													}}
												/>
											}
										</div>}
										{cabinet === 'ucc' && <div className={'form-group col'}>
											<label>Notification Letter</label>
											<NotificationLetterDropdown
												value={combineInfo.nl_id}
												onChange={({target}) => {
													changeCombineInfo('nl_id', target.value)
												}}
											/>
										</div>}
									</div>
									{(cabinet === 'nml' || cabinet === 'cli') && <div className={'form-row'}>
										<div className={'form-group col'}>
											<label>
												Note
												{combineInfo?.note?.length > 40 &&
													<span className={'text-warning ml-1'}>
														<span className={'far fa-exclamation-triangle mr-1'}/>
														Text is too long
														<span className={'far fa-exclamation-triangle ml-1'}/>
													</span>
												}
											</label>
											<textarea
												className={'form-control'}
												value={combineInfo.note}
												rows={3}
												name={'note'}
												onChange={({target}) => {
													changeCombineInfo('note', target.value)
												}}
											/>
										</div>
									</div>}
								</div>
							</div>
						</div>}
						{gDriveLoading && <div className={'col-12'}><h4><LoadingSpinner/> G Drive Loading...</h4></div>}
						{gDriveDocs?.length > 0 &&
							<AddGDrive
								docs={gDriveDocs}
								addDocuments={addDocuments}
								docsToAdd={docsToAdd}
								uploadedDocs={gDriveUploadedDocs}
								download={downloadGDrive}
								moveToGDrive={moveToGDrive}
							/>
						}
						{docsToAdd?.length > 0 ?
							docsToAdd.map((doc, i) => (
								<div
									className={'m' + (i === docsToAdd.length - 1 ? 'y' : 't') + '-3 col-' + (combineDocs ? '6' : '12')}
									key={i}>
									<div className={'card'}>
										<div className={'card-header'}>
											<TypeIcon type={doc.file.type}/>
											<strong className={'pl-2'}>{doc.file.name}</strong>
											<span className={'float-right far fa-times pointer'} onClick={() => {
												removeDocFromAdd(doc.id)
											}}/>
										</div>
										{!combineDocs && <div className={'card-body'}>
											<div className={'form-row'}>
												<div className={'form-group col-sm-2'}>
													<div className="custom-control custom-switch">
														<input
															type={'checkbox'}
															className={'custom-control-input pointer'}
															id={"viewOnlineSwitch" + i}
															checked={doc.view_online}
															onChange={() => {
																changeDocsToAdd(doc, 'view_online', !doc.view_online)
															}}
														/>
														<label className="custom-control-label pointer"
															   htmlFor={"viewOnlineSwitch" + i}>View Online</label>
													</div>
												</div>
												<div className={'form-group col'}>
													<label>Type</label>
													{props.serv_id ?
														<h5>{doc.document_type}</h5>
														:
														<select
															className={'form-control'}
															onChange={({target}) => {
																changeDocsToAdd(doc, 'document_type', target.value)
															}}
															value={doc.document_type}
														>
															{docTypes.map(type => (
																<option
																	key={type.value}
																	value={type.value}
																>
																	{type.value}
																</option>
															))}
														</select>
													}
												</div>
												{cabinet !== 'cli' && <div className={'form-group col'}>
													<label>Service</label>
													{props.docType ?
														<h5>
															{cabinet === 'nml' ?
																propsService?.svc_text
																:
																(propsService.serv_desc + (propsService.amendtype ? '('+(propsService.amendtype)+')' : ''))
															}
														</h5>
														:
														<ServiceDropdown
															value={doc.serv_id}
															onChange={({target}) => {
																changeDocsToAdd(doc, 'serv_id', target.value)
															}}
														/>
													}
												</div>}
												{cabinet === 'ucc' && <div className={'form-group col'}>
													<label>Notification Letter</label>
													<NotificationLetterDropdown
														value={doc.nl_id}
														onChange={({target}) => {
															changeDocsToAdd(doc, 'nl_id', target.value)
														}}
													/>
												</div>}
											</div>
											{(cabinet === 'nml' || cabinet === 'cli') && <div className={'form-row'}>
												<div className={'form-group col'}>
													<label>
														Note
														{doc?.note?.length > 40 &&
															<span className={'text-warning ml-1'}>
																<span className={'far fa-exclamation-triangle mr-1'}/>
																Text is too long
																<span className={'far fa-exclamation-triangle ml-1'}/>
															</span>
														}
													</label>
													<textarea
														className={'form-control'}
														value={doc.note}
														rows={3}
														name={'note'}
														onChange={({target}) => {
															changeDocsToAdd(doc, target.name, target.value)
														}}
													/>
												</div>
											</div>}
										</div>}
									</div>
								</div>
							))
							:
							<div className={'col-12 d-flex align-items-center justify-content-center'} style={{minHeight:125}}>
								{dragOver ?
									<h4><span className={'far fa-file-download'}/> Drop file(s)</h4>
									:
									<h4><span className={'far fa-file-import'}/> Drag files here or click buttons to add Documents</h4>
								}
							</div>
						}
					</div>
				</div>
			</FileDropper>
			<Footer goBack={()=>{setDocsToAdd([])}} noBackBtn={props.noBackBtn}>
				{docsToAdd?.length > 0 && !props?.noUploadBtn &&
					<button
						className={'btn btn-primary'}
						disabled={docAddLoading}
						onClick={uploadClick}
						type={'button'}
					>
						<span className={'far fa-' + (docAddLoading ? 'spinner fa-spin' : 'file-upload')}/> Upload
					</button>
				}
			</Footer>
		</Fragment>
	)
}