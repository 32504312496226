// Renders a text input area that allows a user to create list of tags. 
// Users can freely enter text or select from dropdown list provided
// ( Component acts like Send To section of an email form )

import React, { Component } from 'react';
import { Tooltip,OverlayTrigger } from 'react-bootstrap';

export default class TypeTags extends Component {
    constructor(props) {
        super(props);

        this.previousSendLength = 0;

        this.state = {
            tagList: this.props.tagList ? this.props.tagList : [],
            input: "",
            inputError: 0
        }
    }

    addTag(text) {
        let tag = text.trim();

        if(this.state.tagList.indexOf(tag) != -1) {
            this.setState({input: ''});
        } else if(this.props.validate(tag)){
            let a = this.state.tagList.concat([tag]);
            
            this.setState((prev) => {
                return {
                    tagList: prev.tagList.concat([tag]),
                    input: "",
                    inputError: 0
                }
            });

            this.props.handleList(a);
        } else if (tag != "") {
            this.setState({inputError: 1});
        } else if (tag == "") {
            this.setState({inputError: 0});
        }
    }

    renderTags() {
        let renderArray = [];

        this.state.tagList.map((element, i) => {
            renderArray.push(
                <span style={{padding: "8px", margin: "4px", transition: '.5s'}} key={i} className="badge badge-default" >
                    {element}
                    <span 
                        style={{marginLeft: "4px"}}
                        className="far fa-times pointer"
                        onClick={() => {this.handleRemove(i)}}
                    />
                </span>);
        });

        // Renders a blank tag as the last element in the list which allows for a transition
        renderArray.push(
            <span style={{color: "grey", backgroundColor: "white", opacity:0, transition: '.5s', padding: "0px"}} key={this.state.tagList.length} className="badge" >
                &nbsp;
            </span>);

        return renderArray;
    }

    onInputChange(target) {
        this.setState({input: target.value});
        if((target.value.length - this.previousSendLength) > 1) {
            this.previousSendLength = 0;
            this.addTag(target.value);
            return;
        } else if(target.value.substr(target.value.length - 1) == " ") {
            this.addTag(target.value.slice(0, -1));
        };

        this.previousSendLength = target.value.length;
    }

    handleBlur = (e) => {
        this.addTag(e.target.value);
    }

    renderErrorBubble() {
        return <span style={{padding: "0px 8px", display:"inline-block", color: "red", float: "right", fontSize: "18px", margin: "4px"}} className="fas fa-exclamation-circle fa-2x"/>
    }

    handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            this.addTag(e.target.value);
        } else if (e.key === 'Backspace' && e.target.selectionEnd == 0) {
            let updatedArray = this.state.tagList;

            updatedArray.pop();

            this.setState({tagList: updatedArray});

            this.props.handleList(updatedArray);
        }
    }

    handleRemove = (i) => {
        let tempArray = this.state.tagList;
        tempArray.splice(i, 1);
        
        this.setState({
            tagList: tempArray
        })

        // console.log(tempArray);

        this.props.handleList(tempArray);
    }

    renderOptionList(textBoxId) {
        let displayArray = [];
        if(this.props.optionList) {
            this.props.optionList.forEach((item, i)=>{
                if(item.emailAddress != "" && !this.state.tagList.includes(item.emailAddress) ) {
                    displayArray.push(
                        <option key={i} value={item.emailAddress}>
                            {item.title}
                        </option>
                    );
                }
            })
        }

        return(
            <datalist id={textBoxId} onKeyDown={this.handleKeyPress}>
                {displayArray}
            </datalist>
        )
       
    }

    render() {
        // console.log(this.props.optionList)
        return (
            <div onClick={() => {document.getElementById(this.props.id).focus();}} style={{width: "100%", cursor: "text"}}>
                {this.renderTags()}
                <input 
                    className="type-tags-input form-control"
                    type="text" 
                    style={{border:"0px", boxShadow: "inset 0px 0px 0px 0px white", width: "250px", display: 'inline-block', paddingLeft: "0px"}}
                    id={this.props.id}
                    placeholder=""
                    value={this.state.input} 
                    onChange={event => this.onInputChange(event.target)}
                    // onKeyPress={this.handleKeyPress}
                    onKeyDown={this.handleKeyPress}
                    onBlur={this.handleBlur}
                    list={this.props.id + "-list"}
                />
                { this.renderOptionList(this.props.id + "-list") }
                { this.state.inputError ? this.renderErrorBubble() : "" }
            </div>
        )
    }
}

