// Bootstrap styled file picker button

import React, { Component } from 'react';

export default class LoadingSpinner extends Component {
    render() {
        const { id, classNames, bsStyle, bsIcon, style, pulse } = this.props;

        let iconStyle = "r";
        switch(bsStyle) {
            case "s":
            case "solid":
                iconStyle = "s";
                break;
            case "r":
            case "regular":
                iconStyle = "r";
                break;
            case "l":
            case "light":
                iconStyle = "l";
                break;
            default:
                iconStyle = "r";
                break;
        }

        switch(bsIcon) {
            case "spinner-third":
            case "sync":
            case "circle-notch":
            case "cog":
                var icon = bsIcon;
                break
            default:
                var icon = "spinner";
                break;
        }

        return (
            <span 
                id =        { id ? id : null }
                className = { "fa" + iconStyle + " fa-" + icon + 
                            (pulse ? " fa-pulse fa-fw" : " fa-spin") +
                            (classNames ? " " + classNames : "")}
                style =     { style ? style : null }
            />   
        )
    }
}