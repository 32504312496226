import axios from '../../../axios';

export const FETCH_SETTINGS = 'fetch_settings';
export const FETCH_DROPDOWNS = 'fetch_dropdowns';
export const ADD_SETTING = 'add_setting';
export const IS_LOADING = 'is_loading';
export const DELETE_ROW = 'delete_row';
export const CHANGE_DROPDOWN = 'change_dropdown';
export const COMPANY_SEARCH = 'company_search';
export const SELECT_COMPANY = 'select_company';

export function fetchSettings(empId) {
	return dispatch => {
		axios.get(`/ncs/employee/1/ucc/stage/settings/${empId}/json`)
			.then(settings => {
				dispatch({
					type:FETCH_SETTINGS,
					settings:settings.data
				})
			});
		axios.get(`/ncs/employee/1/ucc/stage/dropdowns/json`)
			.then(dropdowns => {
				dispatch({
					type:FETCH_DROPDOWNS,
					dropdowns:dropdowns.data
				})
			});
		dispatch(changeIsLoading(false));
	}
}

export function addSetting(reference,setting_to_add){
	return {
		type: ADD_SETTING,
		reference,
		setting_to_add
	}
}

export function changeIsLoading(set){
	return {
		type: IS_LOADING,
		set
	}
}

export function deleteRow(reference,id){
	return{
		type: DELETE_ROW,
		reference,
		id
	}
}

export function saveSettings(goBack){
	return (dispatch,getState) => {
		let post = getState().ucc_settings.settings;
		axios.post(`/ncs/employee/1/ucc/save/settings/json`,post)
			.then(response => {
				if(response.data.result == true){
					goBack()
					// window.location = previousPage ? previousPage.from.pathname+previousPage.from.search : '/administrator/emp_maintenance'
				}
				else{
					console.log("Wasn't able to save settings");
				}
			});
	}
}

export function changeDropdown(reference,id,label,value){
	return {
		type: CHANGE_DROPDOWN,
		reference,
		id,
		label,
		value
	}
}

const CancelToken = axios.CancelToken;
let cancel;

export function companySearch(value,id){
	if(cancel){
		cancel();
	}
	let results_array = {};
	return dispatch => {
		axios.get(`/cli/maintenance/1/client/search/json?q=${value+'*'}`, {cancelToken: new CancelToken(function executor(c) {
			cancel = c;
		})})
			.then(results=>{
				results_array[id] = results.data.results;
				dispatch({
					type: COMPANY_SEARCH,
					results:results_array
				})
			})
			.catch(function(thrown) {
				if(axios.isCancel(thrown)) {
					//console.log('Request canceled', thrown);
				}
			})
	}
}

export function selectCompany(id,cli_id,company){
	return {
		type: SELECT_COMPANY,
		id,
		cli_id,
		company
	}
}

export const CHANGE_CERT_SPOD = 'change_cert_spod';
export function changeCertSpod(value){
	return {
		type: CHANGE_CERT_SPOD,
		value
	}
}

export const CHANGE_CERT_SPOD_OPTION = 'change_cert_spod_option';
export function changeCertSpodOption(value){
	return {
		type: CHANGE_CERT_SPOD_OPTION,
		value
	}
}