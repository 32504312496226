import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk';
import reducers from './ucc_reducers';
import {Provider} from "react-redux";
const middleware = applyMiddleware(thunk);
const store = createStore(
	reducers,
	middleware
);
import {useRouter} from "next/router";
import Head from "next/head";
import ServiceSelected from "./components/ServiceSelected";

export default function UCCLayout({children}) {
	const {pathname} = useRouter()
	return <Provider store={store}>
		<>
			<Head><title>Portal UFile</title></Head>
			<ServiceSelected/>
			<div style={{
				position: 'absolute',
				top: 51,
				bottom: 0,
				overflow: 'auto',
				left: 0,
				right: 0
			}}>
				<div className={'container-fluid'} style={{height:'100%'}}>
					{children}
				</div>
			</div>
		</>
	</Provider>
}