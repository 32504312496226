import request from '../../../../utils/request'
import axios from "../../../../axios";
const CancelToken = axios.CancelToken;
let cancel

const ROUTE = '/nml/tracker/1'

export default class AssignmentsApi {
	static loadData() {
		return request({
			url: `${ROUTE}/assign/load/data/json`,
			method: 'GET',
		})
	}

	static employeeView(){
		return request({
			url: `${ROUTE}/assign/list/employee/assignments/json`,
			method: 'GET',
		})
	}

	static getTypeLocations(assginTypeId){
		return request({
			url: `${ROUTE}/assign/list/type/locations/${assginTypeId}/json`,
			method: 'GET',
		})
	}

	static addRule(data){
		return request({
			url:`${ROUTE}/assign/rule/json`,
			method:'POST',
			data
		})
	}

	static addSelector(data){
		return request({
			url:`${ROUTE}/assign/selector/json`,
			method:'POST',
			data
		})
	}

	static updateRule(ruleId,data){
		return request({
			url:`${ROUTE}/assign/rule/${ruleId}/json`,
			method:'PUT',
			data
		})
	}

	static deleteRule(ruleId){
		return request({
			url:`${ROUTE}/assign/rule/${ruleId}/json`,
			method:'DELETE'
		})
	}

	static addType(data){
		return request({
			url:`${ROUTE}/assign/type/json`,
			method:'POST',
			data
		})
	}

	static updateType(typeId,data){
		return request({
			url:`${ROUTE}/assign/type/${typeId}/json`,
			method:'PUT',
			data
		})
	}

	static deleteType(typeId){
		return request({
			url:`${ROUTE}/assign/type/${typeId}/json`,
			method:'DELETE'
		})
	}

	static searchForCompany(search){
		if(cancel){
			cancel()
		}
		return request({
			url:`/cli/maintenance/1/client/search/json?q=${encodeURI(search)}&simple=1`,
			method:'GET',
			cancelToken: new CancelToken(function executor(c) {
				cancel = c;
			})
		})
	}

	static deleteSelector(id){
		return request({
			url:`${ROUTE}/assign/rule/selector/${id}/json`,
			method:'DELETE'
		})
	}
}

