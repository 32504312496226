import React, {useState, useEffect, useContext} from 'react'
import DocPreviewer from "./DocPreviewer";

export default function DocFilePdf({id,cabinet}) {
	const [ preview, setPreview ] = useState(false)
	return (
		<span
			className={'pointer far fa-file-pdf h2'}
			onClick={()=>{setPreview(true)}}
		>
			<DocPreviewer
				docId={id}
				cabinet={cabinet}
				show={preview}
				onClose={()=>{setPreview(false)}}
			/>
		</span>
	)
}