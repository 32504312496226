import {useState} from 'react'
import DocPreviewer from "./DocPreviewer";
import TypeIcon from "../../utils/TypeIcon";

export default function DocPreviewIcon({ type, docId, cabinet }) {
	const [ preview, setPreview ] = useState(false)

	return (
		<div className={'pointer'} onClick={()=>{setPreview(true)}}>
			<TypeIcon type={type} size={'fa-2x'}/>
			<DocPreviewer docId={docId} cabinet={cabinet} show={preview} onClose={()=>{setPreview(false)}}/>
		</div>
	)
}