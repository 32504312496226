import React, { Component } from 'react';

export default class FileDropper extends Component {
    constructor(props) {
        super(props);

        this.dragEnterCounter = 0;
        
        this.state = {
            dragOver: false
        }
    }

    setMouseIsOver(value) {
		if(this.mouseIsOver != value){
            this.mouseIsOver = value;

            this.setState({dragOver:value})
            if(this.props.setDragState) {
                this.props.setDragState(this.mouseIsOver);
            }
		}
	}

    dragEnterHandler(ev) {
        this.dragEnterCounter++;
    }

    dragOverHandler(ev) {
        if(ev.dataTransfer.items[0].kind == 'file')
            this.setMouseIsOver(true);

		ev.dataTransfer.dropEffect = "copy"

        ev.preventDefault();
    }

    dropHandler(ev) {
        ev.preventDefault();
        this.setMouseIsOver(false);
        this.files = [];

        console.log('data transfer',ev.dataTransfer)

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    this.files.push(ev.dataTransfer.items[i].getAsFile());
                }
            }
            if (this.props.fileRetrieved) {
                this.props.fileRetrieved(this.files);
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                // console.log(ev.dataTransfer.files[i]);
                this.files.push(ev.dataTransfer.files[i]);
            }
            if (this.props.fileRetrieved) {
                this.props.fileRetrieved(this.files);
            }
        } 

        this.dragEnterCounter--;


        
        // Pass event to removeDragData for cleanup
        this.removeDragData(ev);
    }

    dragLeaveHandler(event){
        // console.log(event);
        this.dragEnterCounter--;
        if(this.dragEnterCounter == 0){
            this.setMouseIsOver(false);
        }
    }

    removeDragData(ev) {
		if (ev.dataTransfer.items) {
		  // Use DataTransferItemList interface to remove the drag data
          ev.dataTransfer.items.clear();
            this.files = null;
		} else {
		  // Use DataTransfer interface to remove the drag data
          ev.dataTransfer.clearData();
          this.files = null;
		}
	  }


    render(){  
        const { dragOver } = this.state;
        const { fileRetrieved, setDragState, children } = this.props;

        if(children) {
            return (
                <div 
                    id =            "dropzone"
					className =		{dragOver ? 'file-drag-over':''}
                    onDragEnter =   {(event) => {this.dragEnterHandler(event)}} 
                    onDrop =        {(event) => {this.dropHandler(event)}} 
                    onDragOver =    {(event) => {this.dragOverHandler(event)}} 
                    onDragLeave =   {(event) => {this.dragLeaveHandler(event)}}
                >
                    {children}
                </div>
            )
        } else {
            return (
                <label
                    id =            "dropzone" 
                    className =     "file-dropper"
                    onDragEnter =   {(event) => {this.dragEnterHandler(event)}} 
                    onDrop =        {(event) => {this.dropHandler(event)}} 
                    onDragOver =    {(event) => {this.dragOverHandler(event)}} 
                    onDragLeave =   {(event) => {this.dragLeaveHandler(event)}}
                >   
                    <span>
                        <span className="fas fa-paperclip"/>&nbsp; Drop or Click to Upload
                    </span>
                    <input 
                        style =     {{display: "none"} }
                        type =      "file" 
                        className = "form-control-file" 
                        onChange =  {(event)=>{fileRetreived}}
                        multiple =  {true}
                    />
                </label>
            )
        }
    }
}
