import EmployeeDropdown from "../../../utils/EmployeeDropdown";
import {Context, initialFilters} from "../Context/GlobalState";
import React, {Fragment, useContext, useEffect, useState} from "react";
import isEmpty from "../../../utils/isEmpty";
import Collapse from "../../../utils/Collapse";
import Link from "next/link";
import formatDate from "../../../utils/formatDate";
import Overlay from "../../../utils/Overlay";

export default function NavFilters(props) {
	const {
		filters,
		baseSearch,
		dropdowns,
		searchAggs,
		isLoading,
		queueEmpId,
		applyFilters,
		files,
		historyEmpId,
		setSearchAggs
	} = useContext(Context)
	const [ tempFilters, setFilters ] = useState({})

	useEffect(()=>{
		setFilters({...filters})
	},[filters])

	const selectedEmp = queueEmpId > 0 && dropdowns?.employees?.find(emp=>emp.EmpID===queueEmpId)

	const searchAggSections = isEmpty(searchAggs)?[]:[
		{
			title:'Count',
			items:[...searchAggs?.open],
			startOpen:true,
			filter:'open'
		},
		{
			title:'Clients',
			items:[...searchAggs?.cli_id],
			startOpen:true,
			filter:'cli_id'
		},
		{
			title:'Type',
			items:[...searchAggs?.type_id],
			startOpen:true,
			filter:'type_id'
		},
		{
			title:'Tracking',
			items:[...searchAggs?.tracking_status],
			startOpen:false,
			filter:'tracking_status'
		},
		{
			title:'Stage',
			items:[...searchAggs?.stage_id],
			startOpen:false,
			filter:'stage_id'
		},
		{
			title:'State',
			items:[...searchAggs?.state],
			startOpen:false,
			filter:'state'
		},
	]

	function changeFilter(name,value){
		const inArray = tempFilters[name]?.find(r=>r===value)
		setFilters({...tempFilters,[name]:inArray?tempFilters?.[name]?.filter(r=>r!==value):[...tempFilters?.[name],value]})
	}

	function clearFilters(filter){
		setFilters({...tempFilters,[filter]:[]})
	}

	const applyButton = <button
		className={'btn btn-outline-primary btn-sm'}
		onClick={()=>{
			applyFilters(tempFilters)
		}}
	>
		<span className={'far fa-check'}/>
	</button>
	return (
		<>
			{dropdowns?.employees?.length > 0 &&
			<div className={'form-group'}>
				<div className={'row'}>
					<div className={'col-12'}>
						<EmployeeDropdown
							employees={dropdowns.employees}
							btnStyle={'outline-secondary border-0 text-left'}
							onClick={(empId) => {
								baseSearch(empId,historyEmpId)
								setSearchAggs({})
							}}
							title={
								selectedEmp ?
									<Fragment>
										{selectedEmp.FirstName} {selectedEmp.LastName}
										<span className={'float-right'}>
													<span className={'fas fa-caret-down'}/>
												</span>
									</Fragment>
									:
									'-- Select --'
							}
							noDropdownToggle={true}
						/>
					</div>
				</div>
			</div>
			}
			{JSON.stringify(initialFilters) !== JSON.stringify(tempFilters) && !isLoading &&
			<button
				className={'btn btn-outline-danger btn-block btn-sm'}
				onClick={()=>{applyFilters({...initialFilters});setSearchAggs({})}}
			>
				<span className={'far fa-trash-alt'}/> Clear All Filters
			</button>
			}
			{!isLoading && files?.length > 0 &&
			<div className={'pt-2 mt-2 border-top'}>
				<strong>Total</strong>
				<span className={'float-right'}>{files?.length}</span>
			</div>
			}
			{!isLoading && searchAggSections?.length > 0 && <div className={'pt-2 mt-3 border-top'}>
				<h5 className={'text-center'}>Filters</h5>
			</div>}
			{searchAggSections?.map((agg,i)=>
					agg && <SearchItem
						key={i}
						{...agg}
						selectedFilters={tempFilters}
						first={i===0}
						onChange={changeFilter}
						clearFilters={clearFilters}
						applyBtn={applyButton}
						currentFilters={filters}
						search={baseSearch}
					/>
			)}
		</>
	)
}

function SearchItem({title,items,startOpen,onChange,filter,selectedFilters,currentFilters,applyBtn,clearFilters,link,first}){
	const [ viewMore, setMore ] = useState(false)
	const compare = JSON.stringify(currentFilters?.[filter]) !== JSON.stringify(selectedFilters?.[filter])

	return <Collapse
		className={first?'pt-1':' border-top pt-2 mt-3'}
		title={<strong>{title}</strong>}
		startOpen={startOpen}
	>
		{items.slice(0,viewMore?items.length:5).map((item,i)=>(
			link ?
				<Link
					key={i}
					to={`/nml/tracker/file/services?direct_no=${item.id}`}
				>
					<div className={'d-block pt-1'}>
						<div className={'d-flex justify-content-between'}>
							<span>{item.name}</span>
							<span>
								{formatDate(item.recentFileDate,'hh:mm a')}
							</span>
						</div>
					</div>
				</Link>
				:
				<div
					key={i}
					className="custom-control custom-checkbox pointer mt-1"
					onClick={()=>{onChange(filter,item.id)}}
				>
					<input
						type="checkbox"
						className="custom-control-input pointer"
						checked={selectedFilters?.[filter]?.includes(item.id) ?? false}
						onChange={()=>{onChange(filter,item.id)}}
					/>
					<label className="custom-control-label pointer w-100">
						<div className={'row'}>
							<div
								className={'col-9'}
							>
								{item?.name?.length > 14 ?
									<Overlay
										text={item.name}
									>
										<div className={'row'}>
											<div
												className={'col'}
												style={{
													textOverflow:'ellipsis',
													overflow:'hidden',
													whiteSpace:'nowrap'
												}}
											>
												{item.name}
											</div>
										</div>
									</Overlay>
									:
									item.name
								}
							</div>
							<div className={'col-3'}>
								<span className={'float-right'}>({item.count})</span>
							</div>
						</div>
					</label>
				</div>
		))}
		<div className={'row'+((items?.length > 5 || JSON.stringify(currentFilters?.[filter]) !== JSON.stringify(selectedFilters?.[filter]))? ' mt-2':'')}>
			<div className={'col-12'}>
				{items?.length > 5 &&
				<Fragment>
					<span className={'far fa-chevron-'+(viewMore?'up':'down')}/> <a href={'#'} onClick={()=>{setMore(!viewMore)}}>View {viewMore ?'Less':'More'}</a>
				</Fragment>
				}
				<span className={'float-right'}>
					{(compare || JSON.stringify(initialFilters?.[filter]) !== JSON.stringify(selectedFilters?.[filter])) && <button className={'btn btn-outline-danger btn-sm mr-1'} onClick={()=>{clearFilters(filter)}}>
						<span className={'far fa-trash-alt'}/>
					</button>}
					{compare && applyBtn}
				</span>
			</div>
		</div>
	</Collapse>
}