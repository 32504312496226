import React, {useContext} from 'react'
import {DocuwareContext} from "../Context/GlobalState";
import makeKeysLowerCase from "../../utils/makeKeysLowerCase";

export default function ServiceDropdown(props) {
	const { services, cabinet } = useContext(DocuwareContext)

	function optionDisplay(service){
		service = makeKeysLowerCase(service)
		let value = Math.random() * -1
		let display = 'Display'
		if(cabinet === 'ucc'){
			value = service.serv_id
			display = service.serv_desc + (service.amendtype ? '('+(service.amendtype)+')' : '')
		}
		else if(cabinet === 'nml'){
			value = props.serv_key ? props.serv_key : service.serv_key
			display = service.svc_text
		}
		return <option value={value} key={value}>{display}</option>
	}

	return (
		<select
			className={'form-control'}
			value={props.value}
			onChange={e=>{props.onChange(e)}}
		>
			{cabinet === 'nml' &&
				<option value={false}>None</option>
			}
			{services?.open?.length > 0 &&
			<optgroup label="Open">
				{services.open.map(service => (optionDisplay(service)))}
			</optgroup>
			}
			{services?.closed?.length > 0 &&
			<optgroup label="Closed">
				{services.closed.map(service => (optionDisplay(service)))}
			</optgroup>
			}
		</select>
	)
}