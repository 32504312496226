import React, {useState, useContext, useEffect, Fragment} from 'react'
import {DocuwareContext} from "../Context/GlobalState";
import DocList from "./DocList";
import DocDelete from "./DocDelete";
import DocAdd from './DocAdd'
import { Modal } from 'react-bootstrap';
import DocEdit from "./DocEdit";
import EmailForm from "../../utils/email_form/EmailForm";
import DwApi from "../api/DwApi";
import {email} from "../../utils/email_form/emailFunctions";
import isEmpty from "../../utils/isEmpty";
import JsonCompare from "../../utils/JsonCompare";
import {PortalContext} from "../../GlobalState";
import Link from "next/link";

export default function Docuware(props){
	const {
		screen,
		setQuery,
		setScreen,
		modal,
		checkedDocs,
		file,
		uncheckAllDocuments,
		errors,
		getServices,
		cabinet,
		showModal,
		setshowModal,
		query,
		fromList,
		modalSize,
		setDocsToAdd,
		service,
		services,
		getNotificationLettersByUccNo,
		fileNo,
		showTitle,
		addToList
	} = useContext(DocuwareContext)
	const {employee} = useContext(PortalContext)
	const [ contacts, setContacts ] = useState(props?.contacts ?? [])

	useEffect(()=> {
		let newQuery = {}
		if(cabinet === 'nml' && file.direct_no){
			newQuery = {direct_no:parseInt(file.direct_no)}
		}
		if(cabinet === 'ucc' && (file.ucc_no || service?.serv_id || props.servId)){
			if(service?.serv_id) {
				newQuery = {serv_id: service.serv_id}
			}
			else if(props.servId){
				newQuery = {serv_id: props.servId}
			}
			else if(file.ucc_no){
				newQuery = {ucc_no:file.ucc_no}
			}
		}
		if(cabinet === 'cli'){
			newQuery.cli_id = props.cli_id
			if(props.cli_no) {
				newQuery.cli_no = props.cli_no
			}
		}
		if(!isEmpty(newQuery) && JsonCompare(newQuery,query)) {
			setQuery(newQuery)
		}
	},[file])

	useEffect(()=>{
		props?.contacts && setContacts(props.contacts)
	},[props?.contacts])

	useEffect(()=>{
		// console.log('file',file)
		if((props.modal && showModal === true && contacts?.length === 0) || !props.modal){
			let div_id
			if(cabinet === 'ucc') {
				div_id = file?.div_id
			}
			else{
				div_id = file?.client?.div_id
			}
			div_id && DwApi.getContacts(div_id)
				.then(data => {
					setContacts(data)
				})

			if((file?.ucc_no || file?.ncs_lno || file?.direct_no) && services?.length === 0) {
				getServices(cabinet === 'ucc' ? {ucc_no: file.ucc_no} : file?.ncs_lno ? {ncs_lno: file.ncs_lno} : {direct_no: file?.direct_no})
				cabinet === 'ucc' && getNotificationLettersByUccNo(file.ucc_no)
			}
		}
	},[props.modal,showModal,file])

	const emailHeaders = email(cabinet,contacts,file,service)

	function display(){
		if(props.onlyAdd){
			return <DocAdd noBackBtn noUploadBtn={props?.noUploadBtn}/>
		}
		switch (screen) {
			case 'add':
				return <DocAdd noUploadBtn={props?.noUploadBtn}/>
			case 'delete':
				return <DocDelete/>
			case 'edit':
				return <DocEdit/>
			case 'email':
				let toList = contacts
				if(addToList){
					toList = [...toList,...addToList]
				}
				return <EmailForm
					contacts={toList}
					to={emailHeaders.to}
					attachments={checkedDocs}
					subject={emailHeaders.subject}
					cancelBtn={()=>{setScreen('list')}}
					sendBtn={()=>{setScreen('list');uncheckAllDocuments()}}
					closeBtn={()=>{setshowModal(false)}}
					file={file}
					service={service ? service : {}}
					emp={employee}
					cabinet={cabinet}
					modal={modal}
					from={employee?.Email}
					fromList={fromList}
				/>
			case 'list':
				return <DocList/>
			default:
				return <DocList/>
		}
	}

	function error(){
		return errors?.length > 0 &&
			<div className={'row'}>
				{errors.map((error,i)=>(
					<div className={'col-12'} key={i}>
						<div className={'alert alert-danger alert-dismissible fade show'}>
							{error.message}
							<button type="button" className="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
				))}
			</div>
	}

	function title(){
		let titleDisplay
		// console.log('file',file)
		if(cabinet === 'ucc'){
			titleDisplay = file?.ucc_no + ' ' + (file?.natcr_no ? `(Nat #${file.natcr_no}) `:'') + (file?.service ? <Fragment><span className={'fas fa-caret-right'}/> {file.service.SERV_DESC}</Fragment>:'')
		}
		else if(cabinet === 'nml'){
			titleDisplay = file?.ncs_lno
		}
		else if(cabinet === 'cli'){
			titleDisplay = props.cli_id
		}

		return  <small className="text-muted"><span className={'fas fa-caret-right'}/> {titleDisplay}</small>
	}

	function openModal(){
		if(cabinet==='cli'){
			setQuery({
				cli_id:props.cli_id,
				cli_no:props.cli_no
			})
		}
		setshowModal(true)
	}

	function titleDisplay(){
		// console.log('title',cabinet,file)
		let title = ''
		switch (cabinet){
			case 'cli':
				title = <Fragment>
					Client: {props.cli_id}
					{props.cli_no ? <Fragment><span className={'fas fa-caret-right ml-1'}/> Div: {props.cli_no}</Fragment> : ''}
				</Fragment>
				break;
			case 'ucc':
				title = file?.ucc_no
				break;
			default:
				title = ''
				break;
		}
		return <div className={'row'}>
			<div className={'col-12'}>
				<h3>{title}</h3>
			</div>
		</div>
	}

	const modalButton = <button className={props.btnStyle ?? 'btn btn-default'} onClick={openModal}>
		{props.children ?? <Fragment><span className={'fab fa-dochub'}/> {props?.iconOnly?'':'Docuware'}</Fragment>}
	</button>

	return(
		modal ?
			<Fragment>
				{cabinet === 'cli' ?
					<Fragment>
						<div className={'btn-group'}>
							{modalButton}
							<button className={(props.btnStyle ?? "btn btn-default") + " dropdown-toggle"} data-toggle="dropdown"/>
							<div className="dropdown-menu dropdown-menu-right dropdown-toggle-split">
								<a
									className="dropdown-item"
									href="#"
									onClick={()=>{
										setQuery({cli_id:props.cli_id});setshowModal(true)
									}}
								>
									All Client Docs
								</a>
							</div>
						</div>
					</Fragment>
					:
					<Fragment>{modalButton}</Fragment>
				}

				{/*{cabinet === 'cli' && </div>}*/}
				<Modal
					show={showModal}
					onHide={()=>{setshowModal(false);setDocsToAdd([])}}
					size={modalSize}
					className={'docuware-modal'}
					backdrop={screen==='email'?'static':true}
					onExit={()=>{setScreen('list')}}
				>
					<Modal.Header closeButton>
						<h4>Docuware  {title()}</h4>
					</Modal.Header>
					{error()}
					{display()}
				</Modal>
			</Fragment>
			:
			<Fragment>
				{error()}
				{cabinet === 'nml' && <div className={'row mb-3'}>
					<div className={'col'}>
						{file?.ncs_lno && <h2>{file?.ncs_lno} {file?.name && (' - ' + file.name)}</h2>}
					</div>
					{fileNo?.length > 0 && <div className={'col'}>
						<Link href={`/nml/tracker/file/services?direct_no=${fileNo}`}>
							<a className={'btn btn-default float-right'}>
								<span className={'far fa-ncs'}/> Open Tracker
							</a>
						</Link>
					</div>}
				</div>}
				{showTitle && titleDisplay()}
				{display()}
			</Fragment>
	)
}