
export default function EmailSignature({name,title,ext}) {
	// console.log('ext: ', ext);
	// console.log('title: ', title);
	// console.log('name: ', name);
	// console.log('check',name && name != 'undefined',title && title != 'undefined')

	return (
		<div id={'docuwareEmailSignature'}>
			<div>&nbsp;</div>
			{name && <div><font style={{fontFamily:'Arial'}} size="2">{name}</font></div>}
			{title && <div><font style={{fontFamily:'Arial'}} size="2">{title}</font></div>}
			<div>&nbsp;</div>
			<div>
				<img
					alt="NCS Credit"
					src="http://www.ncscredit.com/email/imgs//NCS_logo_tagline_groups.jpg"
					nosend="1" // eslint-disable-line react/no-unknown-property
					width="426"
					height="82"
				/>
			</div>
			<div>
				<font style={{fontFamily:'Arial'}} size="2">
					P.O. Box 24101<br/>
					Cleveland, OH
					44124<br/>
					800-826-5256, x{ext}<br/>
					440-461-6202 (Fax)<br/>
					<a href="http://www.ncscredit.com">www.ncscredit.com</a>
				</font>
			</div>
			<div>&nbsp;</div>
			<div>
				<font style={{fontFamily:'Arial'}} size="1">
					The information contained in this email
					and any attachments is intended for the named recipients only. It may contain
					confidential information. If you receive this e-mail in error, please notify the
					sender immediately via return e-mail. Thank you for your assistance.
				</font>
			</div>
			<div>&nbsp;</div>
		</div>
	)
}