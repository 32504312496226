import {
	CHANGE_PREPARED_BY_CLIENT
} from './action';

export default function CompleteReducer(
    state = {
        preparedByClient:0
    },
    action
) {
	switch (action.type) {
		case CHANGE_PREPARED_BY_CLIENT:
			return {...state,preparedByClient:action.set}
		default:
			return state;
	}
}