import React, { useState, useEffect } from 'react'
import DwApi from '../api/DwApi'
import dayjs from "dayjs";
import isEmpty from "../../utils/isEmpty";
import makeKeysLowerCase from '../../utils/makeKeysLowerCase'
import JsonCompare from "../../utils/JsonCompare";
export const DocuwareContext = React.createContext({})
import {useRouter} from "next/router";

function GlobalState(props){
	// console.log('docuware props',props)
	const cabinet = props.cabinet.toLowerCase()
	const router = useRouter()
	const getParams = router.query
	const [ isLoading, setLoading ] = useState(false)
	const [ screen, setScreen ] = useState(props.screen ? props.screen : 'list')
	const [ documents, setDocuments ] = useState(props.documents ? props.documents : [])
	const [ query, setQuery ] = useState({})
	const [ docTypes, setDocTypes ] = useState([])
	const [ search, setSearch ] = useState('')
	const [ checkedDocs, setCheckedDocs ] = useState([])
	const [ docsToAdd, setDocsToAdd ] = useState([])
	const [ errors, setErrors ] = useState([])
	const [ service, setService ] = useState(props?.service ? makeKeysLowerCase(props.service) : {})
	const [ servicesLoading, setServicesLoading ] = useState(false)
	const [ services, setServices ] = useState([])
	const [ file, setFile ] = useState({})
	const [ showModal, setshowModal ] = useState(false)
	const [ modalSize, setModalSize ] = useState(props.size ?? 'lg')
	const [ gDriveDocs, setGDriveDocs ] = useState([])
	const [ gDriveLoading, setGDriveLoading ] = useState(false)
	const [ gDriveUploadedDocs, setGDriveUploadedDocs ] = useState([])
	const [ notificationLetters, setNotificationLetters ] = useState([])
	const [ docsListTabSelected, setDocsListTabSelected ] = useState(cabinet)
	const [ cliDocs, setCliDocs ] = useState([])
	const [ docsToAddError, setDocsToAddError ]= useState('')
	const cabinetDocListSelected = docsListTabSelected === cabinet

	function dwSearch(){
		if(props.nl_id){
			getNotificationLetter(props.nl_id)
		}
		else {
			const checkIfModal = props.modal ? showModal : true
			if (!isEmpty(query) && checkIfModal) {
				setLoading(true)
				DwApi.search(cabinet, {query: query}, true).then(data => {
					data?.documents && setDocuments(data.documents)
					setLoading(false)
				})
				// console.log('dw search',props)
				if(cabinet !== 'cli'){
					let cli_id = false
					if(cabinet === 'nml'){
						cli_id = props?.file?.client?.cli_id
					}
					else{
						cli_id = props?.file?.CLI_ID
					}
					if(cli_id){
						cliMaintenanceSearch(cli_id)
					}
				}
			}
		}
	}

	function cliMaintenanceSearch(cli_id){
		DwApi.search('cli', {query: {cli_id}}, true).then(data => {
			data?.documents && setCliDocs(data.documents)
		})
	}

	useEffect(()=>{
		if(props?.uploadDocs && file?.direct_no){
			uploadDocsToDocuware(props?.uploadFinished)
		}
	},[props?.uploadDocs,file])

	useEffect(()=>{
		// console.log('get params',getParams)
		if(getParams?.docModal){
			setshowModal(true)
		}
	},[])

	useEffect(()=>{
		if(!isEmpty(props.file) && JsonCompare(props.file,file)) {
			setFile(makeKeysLowerCase(props.file))
		}
	},[props.file])

	function changeServices(newServices){
		let lowerCase = makeKeysLowerCase(newServices)
		if(lowerCase?.open){
			lowerCase.open = [...Object.values(lowerCase.open)]
		}
		if(lowerCase?.closed){
			lowerCase.closed = [...Object.values(lowerCase.closed)]
		}
		setServices(lowerCase)
	}

	useEffect(()=>{
		if(props?.services) {
			changeServices(props.services)
		}
	},[props.services])

	function getNotificationLettersByUccNo(uccNo){
		DwApi.getNotificationLettersByUccNo(uccNo)
			.then((data)=>{
				setNotificationLetters(data)
			})
	}

	function getNotificationLetter(nl_id){
		setLoading(true)
		DwApi.getNotificationLetter(nl_id)
			.then((data)=>{
				setDocuments(data.documents)
				setLoading(false)
			})
	}

	useEffect(()=>{
		if(props.nl_id){
			getNotificationLetter(props.nl_id)
		}
		else {
			dwSearch()
		}

		if (props.file && Object.keys(props.file).length === 0 && Object.keys(file).length === 0 && (props.fileNo || props.ucc_no || props.direct_no)) {
			const fileNo = props?.fileNo ?? props?.ucc_no ?? props?.direct_no
			if(fileNo) {
				DwApi.getFileInfo(cabinet, fileNo, props.servId)
					.then(data => {
						setFile(makeKeysLowerCase(data.file))
					})
			}
		}
	},[props.modal,showModal,cabinet,query,props.nl_id])

	function selectDocument(id){
		let changeDocs = docsListTabSelected === cabinet ? [...documents] : [...cliDocs]
		changeDocs = changeDocs.map(doc=>(doc.id === id ? {...doc,checked:(doc.checked?!doc.checked:true)}:{...doc}))
		if(cabinetDocListSelected){
			setDocuments(changeDocs)
		}
		else{
			setCliDocs(changeDocs)
		}
	}

	const selectAllDocuments = () => {
		let changeDocs = docsListTabSelected === cabinet ? [...documents] : [...cliDocs]
		const allSelected = changeDocs.filter(doc=>doc.checked).length === changeDocs.length
		if(cabinetDocListSelected){
			setDocuments(changeDocs.map(doc=>({...doc,checked:!allSelected})))
		}
		else{
			setCliDocs(changeDocs.map(doc=>({...doc,checked:!allSelected})))
		}
	}

	function uncheckAllDocuments(){
		if(cabinetDocListSelected){
			setDocuments(documents.map(doc=>({...doc,checked:false})))
		}
		else{
			setCliDocs(cliDocs.map(doc=>({...doc,checked:false})))
		}
	}

	function getDocumentTypes(){
		// console.log('getting doc types',cabinet)
		DwApi.getDocTypes(cabinet?.toUpperCase()??'NML').then(data=>{
			setDocTypes(data)
		})
	}

	function getCheckedDocs(docs){
		return docs?.length > 0 ? docs.filter(doc => doc.checked === true) : []
	}

	useEffect(()=>{
		setCheckedDocs(getCheckedDocs(cabinetDocListSelected?documents:cliDocs))
	},[documents,cliDocs,docsListTabSelected])

	function deleteDocs(){
		setLoading(true)
		DwApi.deleteDocuments(cabinet,{documents:checkedDocs}).then(data=>{
			setScreen('list')
			dwSearch()
		})
			.catch(displayErrors)
	}

	function addDocsToUpload(files,serv_id = false,gDrive = false,gDriveFolder = []){
		// console.log('adding docs',files,serv_id)
		let docs = []
		for(let i = 0;i < files.length;i++){
			const file = files[i]
			let upload = {};
			upload.id = Math.random()
			upload.view_online = false;
			if(cabinet === 'ucc'){
				upload.serv_id =  service.serv_id ? service.serv_id : services?.open?.length > 0 ? services.open[0].serv_id : services?.closed?.length > 0 ? services.closed[0].serv_id : null
				let ucc_no = null
				if(props?.file?.UCC_NO){
					console.log('found ucc no in file')
					ucc_no = props.file.UCC_NO
				}
				else if(props?.file?.ucc_no){
					ucc_no = props.file.ucc_no
				}
				else if(service?.ucc_no){
					ucc_no = service.ucc_no
				}
				else if(services?.open?.length > 0){
					ucc_no = services.open[0].ucc_no
				}
				else if(services?.closed?.length > 0){
					ucc_no = services.closed[0].ucc_no
				}
				if(!ucc_no){
					setDocsToAddError([...docsToAddError,{id:Math.random(),desc:`No UCC # was found to add with document: ${file?.name}, please try refreshing page and try again. If it still does not work, please contact IT`}])
				}
				upload.ucc_no = ucc_no
				upload.nl_id = props.nl_id ? props.nl_id : ''
			}
			if(cabinet === 'nml'){
				upload.note = "";
				upload.serv_id = serv_id
			}
			if(cabinet === 'cli'){
				upload.cli_id = props.cli_id
				upload.cli_no = props.cli_no
			}
			upload.document_type = file.docType ? file.docType : docTypes.length > 0 ? docTypes[0].value : null
			upload.file = file;
			upload.gDrive = gDrive
			if(gDrive){
				upload.location = file.location
				// upload.folder =
			}

			docs.push(upload)
		}

		setDocsToAdd([...docsToAdd,...docs])
		setScreen('add')
	}

	function removeDocErrorMessage(id){
		setDocsToAddError([...docsToAddError?.filter(err=>err.id!=id)])
	}

	function changeDocsToAdd(doc,name,value){
		setDocsToAdd(docsToAdd.map(add=>(add.id === doc.id ? {...doc,[name]:value}:{...add})))
	}

	function changeCheckedDocs(doc,name,value){
		// console.log('changing docs',doc,name,value)
		setCheckedDocs(checkedDocs.map(document=>(document.id === doc.id ? {...doc,[name]:value}:{...document})))
	}

	function uploadDocsToDocuware(callback = false){
		docsToAdd.forEach(doc => {
			// console.log('uploading docs',doc)
			let form = new FormData();
			form.append('file', doc.file)
			let metadata = {
				view_online: doc.view_online,
				document_type: doc.document_type,
			}
			if(cabinet === 'nml'){
				if(file.ncs_lno) {
					metadata.lien_no = file.ncs_lno
				}
				metadata.direct_no = file.direct_no
				metadata.serv_id = doc.serv_id
				metadata.note = doc.note
			}
			else if(cabinet === 'ucc') {
				if(!doc.ucc_no || doc?.ucc_no?.length === 0){

				}
				metadata.serv_id = doc.serv_id
				metadata.ucc_no = doc.ucc_no
				metadata.nl_id = doc.nl_id ?? null
			}
			else if(cabinet === 'cli') {
				metadata.cli_no = doc.cli_no
				metadata.cli_id = doc.cli_id
				metadata.note = doc.note
			}
			if(doc.gDrive){
				metadata.gDrive = true
				metadata.location = doc.location    //'../../docs/insecure.txt'
				metadata.filename = doc.file.name
			}
			form.append('metadata', JSON.stringify(metadata))
			uploadDocs(form,callback)
		})
	}

	function uploadDocs(form,callback=false){
		setLoading(true)
		DwApi.uploadFormData(cabinet,form)
			.then(()=>{
				dwSearch()
				setScreen('list')
				setDocsToAdd([])
				if(callback) callback()
			})
			.catch(displayErrors)
	}

	async function combineDocsToDocuware(metadata,callback){
		setLoading(true)
		if(cabinet === 'nml'){
			if(file.ncs_lno) {
				metadata.lien_no = file.ncs_lno
			}
			metadata.direct_no = metadata.serv_id
		}
		else{
			delete metadata.note
			metadata.ucc_no = file.ucc_no
		}

		const lastCallBack = ()=>{
			dwSearch()
			setScreen('list')
			setDocsToAdd([])
			callback()
		}

		let gDriveUploads = []

		if(docsToAdd?.length > 20){
			const loop = Math.floor(docsToAdd.length / 20)
			let docId
			let start = 0
			let end = 20
			for(let index = 0;index <= loop;index++) {
				const form = new FormData();
				const docs = docsToAdd.slice(start,end)
				if(docs?.length > 0) {
					docsToAdd.map((doc, i) => {
						if(doc.gDrive){
							gDriveUploads.push({location:doc.location,filename:doc.file.name})
						}
						else{
							form.append(`file[${i}]`, doc.file)
						}
					})
					if(gDriveUploads.length > 0){
						metadata.gDriveUploads = gDriveUploads
					}
					if (index > 0) {
						metadata.docId = docId
					}
					form.append('metadata', JSON.stringify(metadata));
					DwApi.combineMultipleDocs(cabinet, form)
						.then((response) => {
							start = start + 20
							end = end + 20
							docId = response.id
							if (index === loop) {
								lastCallBack()
							}
						})
						.catch(displayErrors)
				}
				else{
					lastCallBack()
				}
			}
		}
		else {
			const form = new FormData();
			docsToAdd.map((doc, i) => {
				if(doc.gDrive){
					gDriveUploads.push({location:doc.location,filename:doc.file.name})
				}
				else{
					form.append(`file[${i}]`, doc.file)
				}
			})
			if(gDriveUploads.length > 0){
				metadata.gDriveUploads = gDriveUploads
			}
			form.append('metadata', JSON.stringify(metadata));
			DwApi.combineMultipleDocs(cabinet, form)
				.then(({data}) => {
					lastCallBack()
				})
				.catch(displayErrors)
		}
	}

	function displayErrors(event){
		setErrors([])
		setErrors(event.data.errors)
	}

	async function updateCheckedDocs(){
		for(const doc of checkedDocs){
			let metadata =
				{
					view_online:doc.view_online,
					document_type:doc.document_type,
					serv_id:doc.serv_id
				}
			if(cabinet === 'nml' || cabinet === 'cli') metadata = {...metadata,note:doc.note}
			if(cabinet === 'ucc') metadata = {...metadata,nl_id:doc.nl_id}
			await DwApi.updateMetadata(cabinet,doc.id,metadata)
				.then(()=>{
					// console.log('update')
				})
				.catch(displayErrors)
		}

		dwSearch()
		setScreen('list')
	}

	function addScreenshot(img,docType = undefined, serv_id = undefined){
		// console.log('adding screenshot',docType,serv_id)
		const byteString = atob(img.split(',')[1]);
		const mimeString = img.split(',')[0].split(':')[1].split(';')[0];

		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		const screenshot = new Blob([ab], {type: mimeString});
		screenshot.name = 'research_screenshot_' +  dayjs(new Date()).format('MMDDYYHHMMss') + '.' + mimeString.split('/')[1]
		screenshot.docType = docType ?? 'INTERNET RESEARCH'
		addDocsToUpload([screenshot],serv_id)
	}

	function getServices(val){
		if(!servicesLoading) {
			setServicesLoading(true)
			DwApi.getServices(cabinet, val)
				.then(data => {
					setServicesLoading(false)
					changeServices(data)
					if (props?.servId) {
						const allServices = [...data.open, ...data.closed]
						// console.log('getting service', allServices.filter(serv => serv.SERV_ID === props.servId)[0], allServices)
						setService(makeKeysLowerCase(allServices.filter(serv => serv.SERV_ID === props.servId)[0]))
					}
				})
		}
	}

	function printUrl(){
		return DwApi.printUrl(cabinetDocListSelected ? cabinet : 'cli',checkedDocs)
	}

	function downloadDocuments(){
		checkedDocs.map(doc=> {
			DwApi.downloadDocumentV2(cabinetDocListSelected ? cabinet : 'cli',doc.id)
				.then(data=>{
					const linkSource = `data:application/pdf;base64, ${data.file}`;
					const downloadLink = document.createElement('a');
					document.body.appendChild(downloadLink);

					downloadLink.href = linkSource;
					downloadLink.target = '_self';
					downloadLink.download = (cabinetDocListSelected ? cabinet === 'nml' ? doc.lien_no : cabinet === 'ucc' ? doc.ucc_no : doc.cli_id : doc.cli_id) + " - " + doc.document_type;
					downloadLink.click();
				})
		})
	}

	function removeDocFromAdd(id){
		const docs = docsToAdd.filter(doc=>doc.id !== id)
		setDocsToAdd(docs)
	}

	function getGDriveDocs(){
		setGDriveLoading(true)
		DwApi.getGDriveFiles()
			.then(data=>{
				setGDriveLoading(false)
				setGDriveDocs(data)
			})
	}

	function getUploadedGDriveDocs(){
		setGDriveLoading(true)
		DwApi.getUploadedGDriveDocs()
			.then(data=>{
				setGDriveLoading(false)
				setGDriveUploadedDocs(data)
			})
	}

	function downloadGDrive(uploaded,filename){
		DwApi.downloadGDrive(uploaded,filename)
			.then(data=>{
				const linkSource = `data:${data.type};base64, ${data.file}`;
				const downloadLink = document.createElement('a');
				document.body.appendChild(downloadLink);

				downloadLink.href = linkSource;
				downloadLink.target = '_self';
				downloadLink.download = data.name;
				downloadLink.click();
			})
	}

	function moveToGDrive(filename){
		setGDriveLoading(true)
		DwApi.moveToGDrive(filename)
			.then(data=>{
				setGDriveLoading(false)
				setGDriveUploadedDocs(data.uploaded)
				setGDriveDocs(data.g_drive)
			})
	}

	return(
		<DocuwareContext.Provider
			value={{
				...props,
				docProps:props,
				isLoading,
				setLoading,
				screen,
				setScreen,
				cabinet,
				documents,
				query,
				setQuery,
				selectAllDocuments,
				selectDocument,
				docTypes,
				setDocTypes,
				getDocumentTypes,
				search,
				setSearch,
				checkedDocs,
				modal:props.modal?props.modal:false,
				deleteDocs,
				docsToAdd,
				addDocsToUpload,
				changeDocsToAdd,
				uploadDocsToDocuware,
				file,
				uncheckAllDocuments,
				changeCheckedDocs,
				updateCheckedDocs,
				addScreenshot,
				showModal,
				setshowModal,
				errors,
				services,
				setServices,
				getServices,
				printUrl,
				downloadDocuments,
				setDocsToAdd,
				combineDocsToDocuware,
				removeDocFromAdd,
				service,
				fromList: props.fromList ? props.fromList : [],
				modalSize,
				setModalSize,
				gDriveDocs,
				getGDriveDocs,
				gDriveLoading,
				setGDriveDocs,
				getUploadedGDriveDocs,
				gDriveUploadedDocs,
				downloadGDrive,
				moveToGDrive,
				notificationLetters,
				getNotificationLettersByUccNo,
				docsListTabSelected,
				setDocsListTabSelected,
				cliDocs,
				cabinetDocListSelected,
				removeDocErrorMessage,
				docsToAddError
			}}
		>
			{props.children}
		</DocuwareContext.Provider>
	)
}

export default GlobalState