import React, { useState, useEffect, useContext } from 'react'
import {PortalContext} from "../../../../GlobalState";
import {useRouter} from "next/router";

import Api from './AssignmentsApi'

export const AssignmentContext = React.createContext({})

function AssignmentGlobalState(props){
	const {employee} = useContext(PortalContext)
	const {pathname} = useRouter()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ assignments, setAssignments ] = useState([])
	const [ states, setStates ] = useState([])
	const [ assignTypes, setAssignTypes ] = useState([])
	const [ queues, setQueues ] = useState([])
	const [ queueFail, setQueueFail ] = useState([])
	const [ serviceTypes, setServiceTypes ] = useState([])
	const [ employeeView, setEmployeeView ] = useState([])
	const [ isAdmin, setIsAdmin ] = useState(false)

	useEffect(()=>{
		if(pathname.startsWith('/nml/maintenance/assignments')) {
			loadAssignmentData()
		}
	},[pathname])

	useEffect(()=>{
		setIsAdmin(employee?.admin > 0 && employee?.admin <= 10 && (employee?.Department === 'Liens' || employee?.Department === 'IT'))
	},[employee])

	function loadAssignmentData(){
		setIsLoading(true)
		Api.loadData().then(data=>{
			setAssignments(data.assignments)
			setAssignTypes(data.assign_types)
			setStates(data.states)
			setQueues(data.queues)
			setQueueFail(data.queue_fail)
			setServiceTypes(data.service_types)
			setIsLoading(false)
		})
		Api.employeeView().then(data=>{
			setEmployeeView(data)
		})
	}


	function addRule(rule){
		setIsLoading(true)
		Api.addRule(rule)
			.then(data=>{
				setIsLoading(false)
				setAssignments(data)
			})
	}

	function updateRule(ruleId,rule){
		setIsLoading(true)
		Api.updateRule(ruleId,rule)
			.then(data=>{
				setIsLoading(false)
				setAssignments(data)
			})
	}

	function deleteRule(ruleId){
		setIsLoading(true)
		Api.deleteRule(ruleId)
			.then(data=>{
				setIsLoading(false)
				setAssignments(data)
			})
	}

	function deleteSelector(id){
		setIsLoading(true)
		Api.deleteSelector(id)
			.then(data=>{
				setIsLoading(false)
				setAssignments(data)
			})
	}

	function addType(type){
		setIsLoading(true)
		Api.addType(type)
			.then(data=>{
				setIsLoading(false)
				setAssignTypes(data)
			})
	}

	function updateType(typeId,type){
		setIsLoading(true)
		Api.updateType(typeId,type)
			.then(data=>{
				setIsLoading(false)
				setAssignTypes(data)
			})
	}

	function deleteType(typeId){
		setIsLoading(true)
		Api.deleteType(typeId)
			.then(data=>{
				setIsLoading(false)
				setAssignTypes(data)
			})
	}

	return(
		<AssignmentContext.Provider
			value={{
				isLoading,
				assignments,
				states,
				assignTypes,
				queues,
				queueFail,
				serviceTypes,
				addRule,
				updateRule,
				deleteRule,
				addType,
				updateType,
				deleteType,
				deleteSelector,
				setAssignments,
				employeeView,
				isAdmin,
				loadAssignmentData
			}}
		>
			{props.children}
		</AssignmentContext.Provider>
	)
}

export default AssignmentGlobalState