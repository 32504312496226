import DashboardNav from "./dashboard/DashboardNav";
import FileNav from "./file/Nav";
import {useRouter} from "next/router";
import ManagementNav from "../maintenance/ManagementNav";
import ReportNav from "../reports/ReportNav";
import RequestNav from "./OnlineRequest/RequestNav";

export default function NmlNav(props) {
    const {pathname} = useRouter()

    function startsWith(search){
        return pathname.startsWith(search)
    }

    return startsWith('/nml/maintenance')?
        <ManagementNav/>
        :
        startsWith('/nml/report')?
            <ReportNav/>
            :
			startsWith('/nml/tracker/request')?
				<RequestNav/>
				:
				startsWith('/nml/tracker/dashboard')?
					<DashboardNav/>
					:
					<FileNav/>
}