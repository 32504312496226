import React, {useState, useEffect, useContext} from "react";
import TrackerLinkContext from "./TrackerLinkContext";
import MaintenanceContext from "../../maintenance/MaintenanceContext";
import axios from "../../../axios";
import {PortalContext} from "../../../GlobalState";
import formatDate from "../../../utils/formatDate";

const CancelToken = axios.CancelToken
const ROOT_URL = '/nml/tracker/1'
const TRACKER_2_URL = '/nml/tracker/2'
import { orderBy } from "lodash";
import DwApi from "../../../docuware/api/DwApi";
import {useRouter} from 'next/router'
import Cookies from 'js-cookie'
import isEmpty from "../../../../document_root/react/isEmpty";

const employee = Cookies.getJSON('employee')
const emp_id = parseInt(employee?.EmpID)

export const intialFormPageData = {
	contacts: [],
	additionalContacts: [],
	contracted_with: "",
	source: "Job Sheet",
	date_calc: "",
	ff_est: "0",
	ff_today: "0",
	ff_date: "",
	first_furnishing: "",
	furnishings: [],
	last_furnishing: "",
	lf_est: "0",
	lf_today: "0",
	lf_date: "",
	lien_direct: 0,
	project_address1: "",
	project_address2: "",
	project_city: "",
	project_name: "",
	project_state: "",
	project_type: "",
	project_zip: "",
	reference_no: "",
	rental_type: "",
	service_code: "",
	service_level: "1",
	service_type: "",
	service_text: "",
	description_of_materials: "",
	invoice_terms: "",
	completion_date: "",
	contract_date: "",
	total_claim_amt: "",
	total_contract_amt: "",
	material_type: "",
	construction_type: "",
	div_id: "",
	cnt_id: "",
	comment: "",
	bond_number: ""
};
export const Context = React.createContext({
	files: [],
});

export const EmployeeContext = React.createContext({
	employees: [],
});

let cancel;

// const { localStorage } = window;
const COLLAPSED_NAV = "collapsedNav";
const SORT_OPTION = "sortOption";
const RECENT_FILE_HISTORY = "recent_file_history"
const SHOW_HISTORY_SEARCH = 'show_history_search'
const SELECTED_FILE = 'selected_file'

function setCollapsedNav(value = 0) {
	Cookies.set(COLLAPSED_NAV, value);
}

function changeSortBy(value = 'ncs_lno') {
	Cookies.set(SORT_OPTION, value);
}

export const initialFilters = {
	open: [],
	stage_id: [],
	tracking_status: [],
	state: [],
	cli_id: [],
	type_id: []
};

function GlobalState(props) {
	const {socket} = useContext(PortalContext);
	const router = useRouter()
	let localStorage = null
	const [isLoading, setLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const [collapsed, setCollapsed] = useState(Cookies.getJSON(COLLAPSED_NAV));
	// const [filter, setFilter] = useState("all");
	const [showHistory, setShowHistory] = useState(Cookies.getJSON(SHOW_HISTORY_SEARCH) ?? false);
	const [historyEmpId, setHistoryEmpId] = useState(emp_id)
	const [filters, setFilters] = useState({...initialFilters});
	const [search, setSearch] = useState('');
	const [queueEmpId, setQueueEmpId] = useState(process.env.ENVIRONMENT === "PRODUCTION" ? emp_id : 21)
	const [searchAggs, setSearchAggs] = useState({})
	const [selectedFile, setSelectedFile] = useState({});
	const [fileInfo, setFileInfo] = useState({});
	const [fileInfoLoading, setFileInfoLoading] = useState(false);
	const [dropdowns, setDropdowns] = useState([]);
	const [sort, setSort] = useState('ncs_lno');
	const [sortDirection, setSortDirection] = useState("desc");
	const [show, setShow] = useState(99);
	const [researchLoading, setResearchLoading] = useState(false);
	const [researchCards, setResearchCards] = useState([]);
	const [clientHistory, setClientHistory] = useState([]);
	const [companySelected, setCompanySelected] = useState([]);
	const [employeeHistory, setEmployeeHistory] = useState([]);
	const [expandAll, setExpandAll] = useState(false);
	const [recentFileHistory, setRecentFileHistory] = useState([])
	const [docuwareContacts, setDocuwareContacts] = useState([{}])
	const [formPage, setFormPage] = useState({...intialFormPageData});
	const [fileBarCollapsed, setFileBarCollapse] = useState(true);
	const [draftSaveState, setDraftSaveState] = useState("saved");
	const [fileHistory, setFileHistory] = useState([])


	useEffect(() => {
		if (typeof window !== "undefined") {
			const {localStorage} = window
			setSort(localStorage?.getItem(SORT_OPTION))
			setExpandAll(JSON.parse(localStorage?.getItem(RECENT_FILE_HISTORY)))
			setRecentFileHistory(JSON.parse(localStorage?.getItem(RECENT_FILE_HISTORY)))
			const selectedFile = getLocalStorage(SELECTED_FILE)
			// console.log('selectedFile',router?.query?.['direct_no'],selectedFile,router)
			if (selectedFile?.direct_no === parseInt(router?.query?.['direct_no']) || selectedFile?.direct_no && !router.query?.['direct_no']) {
				// console.log('setting file from local storage')
				setFileInfo({...selectedFile});
				setSelectedFile({...selectedFile})
			}
		}
		// socket?.on('connect',()=>{
		// 	socket.emit('room','tracker')
		// })

		socket?.on('update',(update)=>{console.log('tracker update',update)})
	}, [])

	useEffect(() => {
		if (router.pathname.startsWith('/nml/tracker/request/form')) {
			if (Object.keys(router.query)?.length > 0) {
				setFormPage({...formPage, ...router.query})
			}
		}
	}, [router])

	useEffect(() => {
		// console.log("useffect");
		getDropdowns()
	}, []);

	function getDropdowns(){
		const expire = new Date(getLocalStorage('dropdownsExpire'))
		let today = new Date()
		// console.log('expire',expire,'date',new Date(expire))
		if(!expire || (today > expire)){
			today.setDate(today.getDate() + 1)
			// console.log('setting local',today)
			setLocalStorage('dropdownsExpire',today)
			axios.get(`${ROOT_URL}/dropdowns/json`).then(({data}) => {
				setLocalStorage('dropdowns',data)
				setDropdowns(data);
			});
		}
		else{
			setDropdowns(getLocalStorage('dropdowns'))
		}
	}

	function getClientHistory(searchTerm, callback) {
		setLoading(true);
		axios
			.get(`/cli/maintenance/1/client/all/search/json?q=${searchTerm}`)
			.then((response) => {
				const {data} = response;
				// console.log("response", data);
				setClientHistory(data);
				setLoading(false);
				callback?.();
			})
			.catch(() => {
				setLoading(false);
				callback?.();
			});
	}

	function getCLientDetails() {
		setLoading(true);
		axios.get(`${api_env}/ncs/employee/1/maintenance/emp_search/:search/json`).then((response) => {
			const {data} = response;
			// console.log("response", data);
			setEmployeeHistory(data);
			setLoading(false);
		});
	}

  // useEffect(() => {
  //   files?.length > 0 && parseInt(selectedFile?.direct_no) !== parseInt(files[0]?.direct_no) && selectFile(files[0]);
  // }, [files]);

  useEffect(()=>{
	  const localStorageSelectedFile = getLocalStorage(SELECTED_FILE)
	  const queryDirectNo = parseInt(router.query?.direct_no) || 0
      // console.log('router use effect',queryDirectNo,router?.query,localStorageSelectedFile)
	  // console.log('query',parseInt(router.query?.direct_no),localStorageSelectedFile?.direct_no,localStorageSelectedFile?.direct_no !== queryDirectNo && queryDirectNo > 0)
	  // console.log('checking router',router.pathname.startsWith('/nml/tracker/file') && isEmpty(selectedFile) && !fileInfoLoading,localStorageSelectedFile?.direct_no !== queryDirectNo && queryDirectNo > 0,router,localStorageSelectedFile)
      if(router.pathname.startsWith('/nml/tracker/file') && !isEmpty(router?.query) && !router?.query?.link_id){
          // console.log('inside file',queryDirectNo,router.query,localStorageSelectedFile)
          if(!isEmpty(localStorageSelectedFile) && localStorageSelectedFile?.direct_no == router?.query?.direct_no){
              // console.log('setting file from local storage')
              setFileInfo({...localStorageSelectedFile});
              setSelectedFile({...localStorageSelectedFile})
              if(docuwareContacts?.length === 0 && localStorageSelectedFile?.client?.div_id){
                  localStorageSelectedFile?.client?.div_id && getDivisionContacts(localStorageSelectedFile?.client?.div_id)
              }
          }
          else if(isEmpty(selectedFile) && !fileInfoLoading || (localStorageSelectedFile?.direct_no !== queryDirectNo && queryDirectNo > 0)){
              // console.log('trying to get a file',queryDirectNo)
              router?.query?.['direct_no'] && selectFile({direct_no:queryDirectNo})
          }
      }
	  else if(router.pathname == '/nml/tracker/dashboard' && files?.length === 0){
		  // console.log('getting files',)
		  getFiles()
	  }
  },[router])

	useEffect(() => {
		// console.log('changing',search,queueEmpId)
		// if(files.length === 0) {
		if (!router.pathname?.split("/nml/tracker")[1]?.includes("/file")) {
			// console.log('use effect getting files')
			getFiles()
		}
		// }
	}, [queueEmpId, historyEmpId, showHistory]);

	useEffect(() => {
		// console.log('files changing',files)
		files.length > 0 && setFiles(orderFiles(files));
		// orderFiles.length > 0 && selectFile(orderFiles[0])
	}, [sort, sortDirection]);

  function getDivisionContacts(div_id){
      DwApi.getContacts(div_id).then(contacts=>setDocuwareContacts(contacts))
  }

  function orderFiles(files) {
    return orderBy(files, [sort], [sortDirection]);
  }

    useEffect(() => {
        setLocalStorage(SELECTED_FILE, fileInfo)
    }, [fileInfo])

	function toggleCollapse() {
		setCollapsedNav(!collapsed);
		setCollapsed(!collapsed);
	}

	function toggleFileBarCollapse() {
		setFileBarCollapse(!fileBarCollapsed);
	}

	function changeSortOption(value) {
		changeSortBy(value);
		setSort(value)
	}

	function selectFile(file) {
		// console.log('selecting file',file)
		if (cancel) {
			cancel()
		}
		setSelectedFile(file);
		setFileInfo({});
		setFileInfoLoading(true);
		setLoading(true)
		axios
			.get(`${ROOT_URL}/project/${file?.direct_no}/json`, {
				cancelToken: new CancelToken(function executor(c) {
					cancel = c;
				}),
			})
			.then(({data}) => {
				setFileInfo(data);
				setFileInfoLoading(false);
				setLoading(false)
				setSelectedFile(data);
				setLocalStorage(SELECTED_FILE, data)
				data?.client?.div_id && DwApi.getContacts(data.client.div_id).then(contacts => setDocuwareContacts(contacts))
				// updateFileHistory(data)
				if (files.length === 0) {
					const file_list = [{...data}];
					setFiles(file_list);
					// console.log('no files',data,file_list)
					getFiles(false, [], {...data})
				} else if (!files?.find(f => f.direct_no === data.direct_no)) {
					// console.log('selecting project else if',data,[{...data},...files])
					setFiles([{...data}, ...files])
				}
			})
			.catch(function (thrown) {
				if (axios.isCancel(thrown)) {
					// console.log('Request canceled', thrown);
				}
			});
	}

	function setLocalStorage(value, item) {
		if (typeof window !== 'undefined') {
			const {localStorage} = window
			localStorage?.setItem(value, JSON.stringify(item))
		}
	}

	function getLocalStorage(value) {
		if (typeof window !== 'undefined') {
			const {localStorage} = window
			const info = localStorage?.getItem(value)
			// console.log('info',typeof info,info)
			if (info !== 'undefined') {
				return JSON.parse(info)
			} else {
				return undefined
			}
			// return info ? JSON.parse(info) : undefined
		}
	}

	function baseSearch(queueEmp = queueEmpId, historyEmp = historyEmpId) {
		setSearch('')
		setFilters({...initialFilters})
		setQueueEmpId(queueEmp)
		setHistoryEmpId(historyEmp)
	}

	function applyFilters(newFilters) {
		setFilters(newFilters)
		getFiles(false, newFilters)
	}

	function getFiles(search = false, newFilters = [], addFileToList = false) {
		if (cancel) {
			cancel();
		}
		setLoading(true);
		setFileInfoLoading(true);
		if (!addFileToList) {
			setFiles([]);
			setFileInfo({});
			setSelectedFile({});
		}
		let data
		// setSearch({...initialSearch})
		if (search?.length > 0) {
			data = {search}
		} else {
			data = showHistory ? {view_history: historyEmpId} : {emp_id: queueEmpId}
			data = {...data, ...filters, ...newFilters}
		}
		axios
			.post(`${ROOT_URL}/search/json`, data, {
				cancelToken: new CancelToken(function executor(c) {
					cancel = c;
				}),
			})
			.then(({data}) => {
				if (data?.list?.length > 0) {
					// const lienDirect = data.filter(file=>file.lien_direct === 1)
					// console.log('lien direct files',lienDirect)
					let fileList = orderFiles(data.list)
					if (addFileToList) {
						fileList = [{...addFileToList}, ...fileList.filter(r => r.direct_no != addFileToList.direct_no)]
					}
					setFiles(fileList);
					setSearchAggs(data.aggs)
				}
				setLoading(false);
				setFileInfoLoading(false)
			})
			.catch(function (thrown) {
				if (axios.isCancel(thrown)) {
					// console.log('Request canceled', thrown);
				} else {
					console.log('search error', thrown)
				}
			});
	}

	function stageChange(process, direct_no, service_id, stage_id, emp_id, note = false) {
		axios.post(`${ROOT_URL}/stage/${process}/json`, {
			direct_no,
			service_id,
			stage_id,
			emp_id,
			note,
			_return: "project"
		})
			.then(({data}) => {
				setFileInfo({...data})
			});
	}

	function changeFilters(name, value) {
		setFilters({...search, [name]: value});
	}

	function resetFilters() {
		setFilters({...initialFilters});
	}

	function getResearchCards(clearCards = true) {
		setResearchLoading(true);
		clearCards && setResearchCards([]);
		axios
			.get(`${ROOT_URL}/research/cards/${selectedFile.direct_no}/json`)
			.then(({data}) => {
				setResearchLoading(false);
				setResearchCards(data);
				// console.log('research cards for direct no',selectedFile.direct_no,data)
			})
			.catch((e) => {
				setResearchLoading(false);
			});
	}

	function createShippingLabel(contact, shipping_option, callback, herc = false) {
		const {direct_no, ncs_lno, reference_no} = selectedFile;
		axios.post(`${ROOT_URL}/create/shipment/json`, {
			contact,
			shipping_option,
			direct_no,
			ncs_lno,
			herc,
			reference_no
		})
			.then((response) => {
				if (response.data.fedex) {
					setFileInfo({...fileInfo, fedex: [...fileInfo.fedex, {...response.data.fedex}]})
				}
				callback(response);
			});
	}

	function expandCollapse(change) {
		localStorage?.setItem('expandAll', JSON.stringify(!expandAll))
		setExpandAll(!expandAll);
	}

	function convertLTOProject(post, callback) {
		axios.post(`${ROOT_URL}/project/convert/json`, post)
			.then(({data}) => {
				setFileInfo(data);
				setSelectedFile(data)
				callback()
			})
	}

	function addNote(direct_no, text, tags, assigned, need_confirmed, cb) {
		axios.post(`${ROOT_URL}/note/create/${direct_no}/json`, {text, tags, assigned, need_confirmed, _return: true})
			.then(({data}) => {
				setFileInfo({...fileInfo, ...data})
				cb(data)
			})
	}

	function patchNote(noteId, text, tags, cb) {
		axios.patch(`${ROOT_URL}/note/${noteId}/json`, {text, tags, _return: true})
			.then(({data}) => {
				setFileInfo({...fileInfo, ...data})
				cb(data)
			})
	}

	function deleteNote(noteId, cb) {
		axios.delete(`${ROOT_URL}/note/${noteId}/json`, {data: {_return: true}})
			.then(({data}) => {
				setFileInfo({...fileInfo, ...data})
				cb(data)
			})
	}

	function deleteTagsFromNote(id, tags, cb) {
		axios.delete(`${ROOT_URL}/note/${id}/tag/json`, {data: {tags, _return: true}})
			.then(({data}) => {
				setFileInfo({...fileInfo, ...data})
				cb && cb(data)
			})
	}

	function confirmNote(id, cb) {
		axios.post(`${ROOT_URL}/note/${id}/confirm/json`, {_return: true})
			.then(({data}) => {
				setFileInfo({...fileInfo, ...data})
				cb && cb(data)
			})
			.catch(error => {
				console.log('confirm error', error);
				cb(error)
			})
	}

	function updateFileHistory(addToHistory) {
		const oneday = 60 * 60 * 24 * 1000
		const oneDayAgo = Date.now() - oneday
		// console.log('recent',oneDayAgo,recentFileHistory)

		const newHistory = [
			...recentFileHistory?.filter(r => (r.recentFileDate > oneDayAgo && r.id !== addToHistory.direct_no) || r.id !== addToHistory.direct_no),
			{
				name: addToHistory.ncs_lno,
				id: addToHistory.direct_no,
				recentFileDate: Date.now()
			}
		]
		localStorage?.setItem(RECENT_FILE_HISTORY, JSON.stringify(newHistory))
		setRecentFileHistory(newHistory)
	}

	function toggleShowHistory() {
		localStorage?.setItem(SHOW_HISTORY_SEARCH, JSON.stringify(!showHistory))
		setShowHistory(!showHistory)
	}

	function saveAssociate(assoc) {
		if (assoc._id === 0) {
			createAssociate(assoc)
		} else {
			setLoading(true)
			setDraftSaveState("loading");
			axios.patch(`${TRACKER_2_URL}/version/associate/${assoc._id}/json`, assoc)
				.then(({data}) => {
					// console.log('saving changes',data)
					setLoading(false)
					updateProject(data)
				})
				.catch(e => {
					setLoading(false)
					setDraftSaveState("error");
				})
		}
	}

	async function saveProject(data) {
		setDraftSaveState("loading");
		setLoading(true)

		axios.patch(`${TRACKER_2_URL}/version/project/${data._id}/json`, data)
			.then(({data}) => {
				// console.log('saving changes',data)
				setLoading(false)
				updateProject(data)
			})
			.catch(e => {
				setLoading(false);
				setDraftSaveState("error");
			})
	}

	function attemptLockRequest() {
		axios.post(`${TRACKER_2_URL}/version/refresh/${fileInfo.direct_no}/json?take_over=true`)
			.then(({data}) => {
				console.log("Current File Info", fileInfo);
				let updateFileInfo = JSON.parse(JSON.stringify(fileInfo));
				updateFileInfo = {...updateFileInfo, ...data.refresh};

				setFileInfo(updateFileInfo);
				console.log("Updated File Info", fileInfo);
			})
			.catch(e => {
			})
	}

	function releaseLock() {
		console.log("File Info global state", fileInfo);
		if (fileInfo.lock_status?.is_mine) {
			axios.post(`${TRACKER_2_URL}/version/release/${fileInfo.direct_no}/json`)
				.then(({data}) => {
				})
				.catch()
		}
	}

	function updateProject(data) {
		if (data?.success) {
			let updateFileInfo = JSON.parse(JSON.stringify(fileInfo));
			updateFileInfo = {...updateFileInfo, ...data.refresh};

			setFileInfo(updateFileInfo);
			setDraftSaveState("saved");
		} else {
			setDraftSaveState("error");
		}
	}

	function updateAllAssociates(assocs, update) {
		Object.keys(assocs)?.map(key => {
			if (update?.[key]) {
				update[key] = {...update[key], ...assocs[key]}
			}
		})
		return update
	}

	function createAssociate(data) {
		setLoading(true)
		axios.post(`${TRACKER_2_URL}/version/associate/create/${fileInfo.project._id}/json`, data)
			.then(({data}) => {
				// console.log('creating assoc',data)
				setLoading(false)
				if (data?.success) {
					updateProject(data)
				}
			})
			.catch(error => {
				setLoading(false)
			})
	}

	function deleteAssociate(_id) {
		saveAssociate({_id, is_deleted: true})
	}

	function getFileHistory() {
		setLoading(true)
		axios.get(`${TRACKER_2_URL}/version/list/${fileInfo?.direct_no}/json`)
			.then(({data}) => {
				console.log('file history', data)
				setLoading(false)
				setFileHistory(data)
			})
			.catch(e => setLoading(false))
	}

	function addFurnishing(direct_no){
		setLoading(true)
		axios.post(`${ROOT_URL}/version/furnishing/create/${direct_no}json`)
			.then(({data})=>{
				setLoading(false)
				updateProject(data)
			})
	}

	function addDeadline(direct_no){
		setLoading(true)
		axios.post(`${ROOT_URL}/version/deadline/create/${direct_no}json`)
			.then(({data})=>{
				setLoading(false)
				updateProject(data)
			})
	}

	function saveEditFurnishing(info){
		// console.log('saving edit',info)
		if(info?.date){
			info.date = formatDate(info.date,'YYYY-MM-DD')
		}
		setLoading(true)
		axios.patch(`${ROOT_URL}/version/furnishing/${info?._id}/json`, {...info})
			.then(({data})=>{
				setLoading(false)
				updateProject(data)
			})
	}

	function saveEditDeadline(info){
		// console.log('saving edit',info)
		if(info?.date){
			info.date = formatDate(info.date,'YYYY-MM-DD')
		}
		setLoading(true)
		axios.patch(`${ROOT_URL}/version/deadline/${info?._id}/json`, {...info})
			.then(({data})=>{
				setLoading(false)
				updateProject(data)
			})
	}

	function getAllFileRequests(){
	  setLoading(true)
	  axios.get(`${ROOT_URL}/project/${fileInfo?.direct_no}/request/history/json`)
		  .then(({data})=>{
			  console.log('all history',data)
			  setFileInfo({...fileInfo,history:data?.history ?? fileInfo?.history})
			  setLoading(false)
		  })
	}

	return (
		<Context.Provider
			value={{
				files,
				getFiles,
				isLoading,
				collapsed,
				toggleCollapse,
				setSearch,
				selectedFile,
				selectFile,
				fileInfo,
				dropdowns,
				fileInfoLoading,
				show,
				setShow,
				sort,
				changeSortOption,
				sortDirection,
				setSortDirection,
				setFileInfo,
				researchLoading,
				stageChange,
				changeFilters,
				resetFilters,
				search,
				getResearchCards,
				researchCards,
				createShippingLabel,
				formPage,
				setFormPage,
				getClientHistory,
				clientHistory,
				setClientHistory,
				companySelected,
				setCompanySelected,
				getCLientDetails,
				//     clientDetails,
				// getEmployeeHistory,
				expandAll,
				expandCollapse,
				convertLTOProject,
				addNote,
				deleteTagsFromNote,
				confirmNote,
				searchAggs,
				setSearchAggs,
				queueEmpId,
				setQueueEmpId,
				baseSearch,
				applyFilters,
				filters,
				deleteNote,
				patchNote,
				recentFileHistory,
				updateFileHistory,
				showHistory,
				historyEmpId,
				toggleShowHistory,
				docuwareContacts,
				setDocuwareContacts,
				fileBarCollapsed,
				toggleFileBarCollapse,
				saveAssociate,
				createAssociate,
				deleteAssociate,
				draftSaveState,
				setDraftSaveState,
				saveProject,
				getFileHistory,
				fileHistory,
				attemptLockRequest,
				releaseLock,
				canEdit: fileInfo?.lock_status?.is_mine || !fileInfo?.lock_status?.is_locked,
				saveEditFurnishing,
				saveEditDeadline,
				addFurnishing,
				addDeadline,
				getAllFileRequests
			}}
		>
			<TrackerLinkContext>
				<MaintenanceContext>
					{props.children}
				</MaintenanceContext>
			</TrackerLinkContext>
		</Context.Provider>
	);
}

export default GlobalState;