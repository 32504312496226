import React, {useState, useContext, Fragment} from 'react'
import SortableTable from '../../utils/SortableTable'
import {DocuwareContext} from "../Context/GlobalState";
import LoadingSpinner from "../../utils/LoadingSpinner";
import DocTypeSearch from "./DocTypeSearch";
import CaptureButton from "../../utils/CaptureButton";
import Footer from "./Footer";
import DocFilePdf from "./DocFilePdf";
import FileDropper from "../../utils/FileDropper";
import DocThumbnail from "./DocThumbnail";
import {PortalContext} from "../../GlobalState";
import DocPreviewIcon from "./DocPreviewIcon";

export default function DocList(props){
	const {employee} = useContext(PortalContext)
	const {
		cabinet,
		documents,
		isLoading,
		selectDocument,
		selectAllDocuments,
		search,
		setScreen,
		checkedDocs,
		modal,
		addDocsToUpload,
		addScreenshot,
		printUrl,
		downloadDocuments,
		docsListTabSelected,
		setDocsListTabSelected,
		cliDocs,
		cabinetDocListSelected,
		docProps
	} = useContext(DocuwareContext)

	const [ dragOver, setDragOver ] = useState(false)
	const docsToShow = cabinetDocListSelected ? documents : cliDocs

	const customCheck = ([id,checked])=>(
		<div className={'form-check'}>
			<input
				className={'form-check-input position-static pointer'}
				type={'checkbox'}
				checked={checked?checked:false}
				onChange={()=>{selectDocument(id)}}
			/>
		</div>
	)

	const customType = ([viewer,document_type,file_size])=>(
		<a href={viewer} target={'_blank'} rel="noreferrer">
			{document_type}
			{parseInt(file_size)>3000000 &&
			<Fragment>
				<br/>
				<span className={'text-warning'}>*This file may be too big to attach to an email*</span>
			</Fragment>
			}
		</a>
	)

	const customOnline = view=>(view ? <span style={{color: "#3c9db5"}} className="far fa-check text-center"/> : '')
	const customContentType = ([id,content_type])=>(content_type === 'application/pdf' && <DocFilePdf id={id} cabinet={docsListTabSelected}/>)

	function newTabLink(){
		let route = `/docuware/?cabinet=${cabinet}`
		if(cabinet == 'ucc'){
			const ucc_no = docProps?.ucc_no ?? docProps?.file?.ucc_no ?? docProps?.file?.UCC_NO
			if(ucc_no) {
				route += `&ucc_no=${ucc_no}`
			}
			const serv_id = docProps?.serv_id ?? docProps?.service?.SERV_ID
			if(serv_id) {
				route += `&servId=${serv_id}`
			}
		}

		if(cabinet == 'nml'){
			const direct_no = docProps?.file?.direct_no
			if(direct_no) {
				route += `&direct_no=${direct_no}`
			}
		}

		return process.env.PORTAL2 + route
	}

    let headers = [
		{ name: <div className={'form-check'}><input onChange={selectAllDocuments} checked={docsToShow && docsToShow.filter(doc=>(doc.checked))?.length === docsToShow?.length} type={'checkbox'} className={'form-check-input position-static pointer'}/></div>,
			value:['id','checked'],
			custom:customCheck
		},
		{ name:'', value:['id','content_type'], custom:([id,content_type])=>(<DocPreviewIcon docId={id} cabinet={cabinet} type={content_type}/>)},
		{
			name: 'Online',
			className:'text-center',
			rowClassName:'text-center',
			sort:false,
			value: 'view_online',
			custom:customOnline
		},
        {
        	name: 'Type',
			value: ['viewer','document_type','file_size'],
			custom:customType
		},
		(docsListTabSelected === 'nml' || docsListTabSelected === 'cli') && { name: 'Note', value:'note' },
        { name: 'User', value: 'create_user' },
        { name: 'Created', value: 'create_date', date: true },
    ]

	const navtabs = [
		{name:cabinet?.toUpperCase(),value:cabinet},
		{name:'Client Maintenance',value:'cli'}
	]

	const btnEnabled = checkedDocs?.length === 0

    return (
    	<Fragment>
			<FileDropper setDragState={(val)=>{setDragOver(val)}} fileRetrieved={addDocsToUpload}>
				<div className={modal ? 'modal-body docuware-content':''}>
					{isLoading ?
						<h4><LoadingSpinner/> Loading</h4>
						:
						<Fragment>
							{cabinet !== 'cli' && cliDocs?.length > 0 &&
								<ul className="nav nav-tabs mb-3">
									{navtabs.map((tab,i)=>(
										<li key={i} className="nav-item" onClick={()=>{setDocsListTabSelected(tab.value)}}>
											<a className={"nav-link "+(docsListTabSelected === tab.value ? 'active':'')} href="#">
												{tab.name}
											</a>
										</li>
									))}
								</ul>
							}
							<div className={'row pb-3'}>
								<div className={'col-8'}>
									{cabinetDocListSelected &&
									<Fragment>
										<button
											className={'btn btn-default'}
											onClick={() => {
												setScreen('add')
											}}
										>
											<span className={'far fa-plus'}/> File(s)
										</button>
										<CaptureButton className={'ml-1'} capture={addScreenshot}/>
									</Fragment>
									}
									{docProps?.modal && cabinet !== 'cli' && <a href={newTabLink()} target={'_blank'} className={'btn btn-default ml-1'} rel="noreferrer"><span className={'far fa-external-link'}/></a>}
									<div className={'btn-group ml-1'}>
										<button
											className={"btn btn-default"}
											disabled={btnEnabled}
											onClick={async ()=>{
												const url = await printUrl()
												window.open(url,'_blank')
											}}
										>
											<span className="far fa-print" />
										</button>
										{cabinetDocListSelected &&
										<button
											className={'btn btn-default'}
											disabled={btnEnabled}
											onClick={() => {
												setScreen('email')
											}}
										>
											<span className={'far fa-envelope'}/>
										</button>
										}
										{cabinetDocListSelected &&
										<button
											className={'btn btn-default'}
											onClick={() => {
												setScreen('edit')
											}}
											disabled={btnEnabled}
										>
											<span className={'far fa-edit'}/>
										</button>
										}
										<button
											className={'btn btn-default'}
											onClick={() => {
												downloadDocuments()
											}}
											disabled={btnEnabled}
										>
											<span className={'far fa-download'}/>
										</button>
										{cabinetDocListSelected &&
										<button
											className={'btn btn-default'}
											onClick={() => {
												setScreen('delete')
											}}
											disabled={btnEnabled}
										>
											<span className={'far fa-trash-alt'}/>
										</button>
										}
									</div>
								</div>
								{cabinetDocListSelected &&
								<div className={'col-4'}>
									<DocTypeSearch/>
								</div>
								}
							</div>
							<SortableTable
								all
								headers={headers}
								info={search?.length > 0 ? docsToShow.filter(doc => (doc.document_type.toLowerCase().includes(search.toLowerCase()))) : docsToShow}
								id={'id'}
							/>
						</Fragment>
					}
				</div>
			</FileDropper>
			<Footer/>
		</Fragment>
	)
}