import React, {useState, useEffect, useContext, Fragment, useRef} from 'react'
import filter from "lodash/filter";
import {Context} from "../../Context/GlobalState";
import EmployeeDropdown from "../../../../utils/EmployeeDropdown";
import LoadingSpinner from "../../../../utils/LoadingSpinner";
import TagsList from "./TagsList";
import NoteTagList from "./NoteTagList";

export default function AddNote(props) {
	const { dropdowns, addNote, fileInfo } = useContext(Context)
	const { note_tags } = dropdowns
	const [ noteText, setNoteText ] = useState('')
	const [ newNoteEmp, setNewNoteEmp ] = useState(0)
	const [ newNoteConfirmed, setNewNoteConfirmed ] = useState(false)
	const [ tagsSelected, setTagsSelected ] = useState([])
	const [ addCustomTag, setAddCustomTag ] = useState(false)
	const [ newCustomTagText, setNewCustomTagText ] = useState('')
	const [ saving, setSaving ] = useState(false)
	const newNoteRef = useRef(null)
	const newCustomTagTextRef = useRef(null)

	useEffect(()=>{
		if(props?.tags?.length > 0 && (JSON.stringify(props.tags) !== JSON.stringify(tagsSelected))){
			setTagsSelected(props.tags)
		}
	},[props.tags])

	useEffect(()=>{
			newNoteRef?.current?.focus()
	},[])

	useEffect(()=>{
		addCustomTag && newCustomTagTextRef?.current?.focus()
	},[addCustomTag])

	function changeTagSelected(tag){
		if(tagsSelected.includes(tag.tag)){
			setTagsSelected(filter(tagsSelected,(r)=>(r!==tag.tag)))
		}
		else{
			setTagsSelected([...tagsSelected,tag.tag])
		}
	}

	function saveNewNote(){
		setSaving(true)
		addNote(fileInfo.direct_no,noteText,tagsSelected,newNoteEmp,newNoteConfirmed,
			()=>{
				setSaving(false)
				setTagsSelected([])
				setNewNoteEmp(0)
				cancelNewNote()
			}
		)
	}

	function cancelNewNote(){
		setNoteText('')
		newNoteRef?.current?.focus()
		props?.cancel()
	}

	const selectedEmp = newNoteEmp > 0 ? dropdowns.employees?.find(emp=>emp.EmpID===newNoteEmp) : false

	return <Fragment>
		<div className="modal-body">
			<div className={'row'}>
				<div className={'col-12 mb-3'}>
					<span className={'far fa-exclamation-triangle'}/> This is a beta version of notes. These notes may be deleted in the future <span className={'far fa-exclamation-triangle'}/>
				</div>
			</div>
			<div className={'row'} style={{marginBottom:'1.25rem'}}>
				<div className={'col-12'}>
					<div className={'form-inline'}>
						{note_tags?.length > 0 &&
						<div className={'input-group'}>
							<div className={'dropdown ml-1'}>
								<button className={'btn btn-outline-secondary'} data-toggle={'dropdown'}>
									<span className={'far fa-plus'}/> Tags
								</button>
								<NoteTagList
									dynamic_tags={props?.dynamic_tags}
									note_tags={note_tags}
									selected={tagsSelected}
									select={(tag) => {
										changeTagSelected(tag)
									}}
								/>
							</div>
						</div>
						}
							{/*<div className={'input-group ml-1'}>*/}
							{/*	<input*/}
							{/*		ref={newCustomTagTextRef}*/}
							{/*		className={'form-control'}*/}
							{/*		placeholder={'Custom Tag'}*/}
							{/*		value={newCustomTagText}*/}
							{/*		onChange={({target})=>{*/}
							{/*			setNewCustomTagText(target.value)*/}
							{/*		}}*/}
							{/*	/>*/}
							{/*	<div className={'input-group-append'}>*/}
							{/*		<button*/}
							{/*			className={'btn btn-outline-secondary'}*/}
							{/*			onClick={()=>{*/}
							{/*				changeTagSelected({tag:newCustomTagText})*/}
							{/*				setAddCustomTag(false)*/}
							{/*				setNewCustomTagText('')*/}
							{/*			}}*/}
							{/*		>*/}
							{/*			<span className={'far fa-plus'}/>*/}
							{/*		</button>*/}
							{/*	</div>*/}
							{/*</div>*/}
							<EmployeeDropdown
								employees={dropdowns.employees}
								onClick={(emp_id)=>{setNewNoteEmp(emp_id)}}
								btnStyle={'outline-secondary btn-block ml-1'}
								title={selectedEmp?selectedEmp.FirstName+' '+selectedEmp.LastName:'Select Employee'}
							/>
							{newNoteEmp > 0 &&
							<div
								className="custom-control custom-checkbox ml-2 pointer"
								onClick={()=>{setNewNoteConfirmed(!newNoteConfirmed)}}
							>
								<input
									type="checkbox"
									className="custom-control-input pointer"
									checked={newNoteConfirmed}
									onChange={()=>{setNewNoteConfirmed(!newNoteConfirmed)}}
								/>
								<label className="custom-control-label pointer">
									Needs Confirmed?
								</label>
							</div>
							}
					</div>
				</div>
				{tagsSelected?.length > 0 && <TagsList tags={tagsSelected} setTagsSelected={setTagsSelected} dynamic_tags={props?.dynamic_tags}/>}
			</div>
			<div className={'row'}>
				<div className={'col-12'}>
					<textarea
						ref={newNoteRef}
						style={{
							border: "0px solid white",
							boxShadow: "none",
							resize: "none",
							borderRadius: "10px",
							backgroundColor: "#f7f7f7",
							height: "300px"
						}}
						placeholder="New Note"
						className="form-control"
						rows="5"
						value={noteText}
						onChange={({target})=>{
							setNoteText(target.value)
						}}
					/>
				</div>
			</div>
		</div>
		<div className="modal-footer">
			<button
				className={'btn btn-primary mr-1'}
				onClick={saveNewNote}
				disabled={!fileInfo?.direct_no || saving}
			>
				{saving?<LoadingSpinner/>:<span className={'far fa-save'}/>} Save
			</button>
			<button
				className={'btn btn-default'}
				onClick={cancelNewNote}
			>
				<span className={'far fa-times'}/> Cancel
			</button>
		</div>
	</Fragment>
}