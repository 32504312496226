import React from 'react'
import formatDate from "../../../utils/formatDate";

export default function LabelAndDisplay({label, display, date, editable, onChange, onClick}) {
	let displayValue = display?.length > 0 || Number.isInteger(display) ? date ? formatDate(display) : display : "None";

	if(date) {
		displayValue = formatDate(display);
		
		if(editable) {
			displayValue = <input onChange={onChange}
				type="date"
				className="form-control"
				value={display}
			/>
		}
	} else if(editable) {
		displayValue = <input onChange={onChange}
			className="form-control"
			value={display}
		/>
	}

	return (
		<div className="labeled-input" onClick={onClick}>
			<div className="info-label">
				{label}
			</div>
			{displayValue}
		</div>
	)
}