import {
	SEARCH,
	LOADING,
	CHANGE_SELECTED
} from './action';

export default function Results(state = {isLoading:true,info:[],selected:''}, action) {
	switch (action.type) {
		case SEARCH:
			return {...state,info:[...action.data]}
		case LOADING:
			return {...state,isLoading:action.loading}
		case CHANGE_SELECTED:
			return {...state,selected:action.id}
		default:
			return state;
	}
}