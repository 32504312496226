import React, {useContext} from 'react'
import {DocuwareContext} from "../Context/GlobalState";

export default function NotificationLetterDropdown(props) {
	const { notificationLetters, services } = useContext(DocuwareContext)

	return (
		<select
			className={'form-control'}
			value={props.value}
			onChange={e=>{props.onChange(e)}}
		>
			<option value={null}>None</option>
			{notificationLetters?.length > 0 && services?.open?.map((service,i) => {
				const letters = notificationLetters[service.serv_id]
				if(letters) {
					return <optgroup key={i} label={service.serv_desc}>
						{letters.map((letter,i)=>(
							<option value={letter.nl_id} key={i}>
								{letter.sec_comp}
							</option>
						))}
					</optgroup>
				}
				else{
					return false
				}
			})}
			{notificationLetters?.length > 0 && services?.closed?.map((service,i) => {
				const letters = notificationLetters[service.serv_id]
				if(letters) {
					return <optgroup key={i} label={service.serv_desc}>
						{letters.map((letter,i)=>(
							<option value={letter.nl_id} key={i}>
								{letter.sec_comp}
							</option>
						))}
					</optgroup>
				}
				else{
					return false
				}
			})}
		</select>
	)
}