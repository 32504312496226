import React, {useState, useEffect} from 'react'
import axios from '../../axios'
import AssignmentContext from './Assignments/Context/AssignmentGlobalState'
const ROOT_URL = '/nml/tracker/1'
import {useRouter} from "next/router";
import StateMaintenanceContext from "../../administrator/StateMaintenance/StateMaintenanceContext";

export const MaintenanceContext = React.createContext({})

export default function MaintenanceContextProvider(props) {
	const router = useRouter()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ researchMaintenance, setResearchMaintenance ] = useState({})

	useEffect(()=>{
		if(router.pathname.startsWith('/nml/maintenance/research')){
			!researchMaintenance?.services  && getResearchMaintenance()
		}
	},[router.pathname])

	function getResearchMaintenance(){
		setIsLoading(true)
		axios.get(`${ROOT_URL}/research/maintenance/json`)
			.then(({data}) => {
				setIsLoading(false)
				setResearchMaintenance({...data})
			})
	}

	function saveService(service,callback){
		setIsLoading(true)
		axios.put(`${ROOT_URL}/research/maintenance/service/json`, {service:service})
			.then(({data}) => {
				setResearchMaintenance({
					...researchMaintenance,
					services:researchMaintenance.services.map(service =>
						service.id === data.id ? {...data}:{...service}
					)
				})
				setIsLoading(false)
			})
	}

	function saveProcesses(processes){
		setIsLoading(true)
		axios.post(`${ROOT_URL}/research/maintenance/processes/json`, {processes})
			.then(({data}) => {
				console.log('saved process',data)
				setIsLoading(false)
				setResearchMaintenance({...researchMaintenance,processes:data})
			})
			.catch((e)=>{
				console.error('error saving processes',e)
				setIsLoading(false)
			})
	}

	function saveCards(cards){
		setIsLoading(true)
		axios.post(`${ROOT_URL}/research/maintenance/cards/json`, {cards})
			.then(({data}) => {
				console.log('saved card',data)
				setIsLoading(false)
				setResearchMaintenance({...researchMaintenance,cards:data})
			})
			.catch((e)=>{
				console.error('error saving cards',e)
				setIsLoading(false)
			})
	}

	return <MaintenanceContext.Provider
		value={{
			isLoading,
			setIsLoading,
			getResearchMaintenance,
			researchMaintenance,
			saveService,
			saveProcesses,
			saveCards
		}}
	>
		<AssignmentContext>
			<StateMaintenanceContext>
				{props.children}
			</StateMaintenanceContext>
		</AssignmentContext>
	</MaintenanceContext.Provider>
}