import {
	NEXT_STAGE_NEW_INFO,
	NEXT_STAGE_NEW_LOADING,
	CHANGE_NEXT_STAGE_SELECTED,
	CHANGE_NEXT_STAGE_SELECTED_INFO
} from './action';

export default function NextStageReducer(
    state = {
        loading:false,
        stages:[],
        info:[],
        selected: {
            stage_id:0,
            emp_id: 0,
            follow_up:''
        }
    },
    action
) {
	switch (action.type) {
		case NEXT_STAGE_NEW_LOADING:
			return {...state,loading:action.set}
		case NEXT_STAGE_NEW_INFO:
			return {...state,...action.data,selected:{...action.data.info[0]}}
		case CHANGE_NEXT_STAGE_SELECTED:
			return {...state,selected:{...state.info.filter(stage=>(stage.stage_id == action.stage_id))[0]}}
		case CHANGE_NEXT_STAGE_SELECTED_INFO:
			return {...state,selected:{...state.selected,[action.target]:action.value}}
		default:
			return state;
	}
}