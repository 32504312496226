import {useRouter} from "next/router";
import Head from "next/head";
import NMLGlobalState from "./Context/GlobalState"
import TrackerLayout from "./TrackerLayout";

export default function NmlLayout({children}) {
	const {pathname} = useRouter()
	return <NMLGlobalState>
		{pathname.startsWith('/nml/attorney') ?
			<>
				<Head><title>Attorney Follow Up</title></Head>
				{children}
			</>
			:
			<TrackerLayout>
				{children}
			</TrackerLayout>
		}
	</NMLGlobalState>
}