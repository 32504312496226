import request from '../../utils/request'

const DW_API = '/docuware/3'

export default class DwApi {

	// SEARCHES ----------------------------------------------------------------
	// Basic search
	static search(cabinet, data, showPrivate=false) {
		let searchType = showPrivate ? 'search_private' : 'search';
		return request({
			url: `${DW_API}/${cabinet}/${searchType}/json`,
			method: 'POST',
			data
		})
	}

	// Advanced search
	static advancedSearch(cabinet, data) {
		return request({
			url: `${DW_API}/${cabinet}/advanced_search/json`,
			method: 'POST',
			data
		})
	}

	// UPLOADS -----------------------------------------------------------------
	// Upload (formdata)
	static  uploadFormData(cabinet, data) {
		return request({
			url: `${DW_API}/${cabinet}/upload/formpost/json`,
			method: 'POST',
			data
		})
	}

	// UPLOADS -----------------------------------------------------------------
	// Upload multiple with ability to combine (formdata)
	static  combineMultipleDocs(cabinet, data) {
		return request({
			url: `${DW_API}/${cabinet}/upload/combine/formpost/json`,
			method: 'POST',
			data
		})
	}

	// Upload (base 64)
	static uploadBase64(cabinet, data) {
		return request({
			url: `${DW_API}/${cabinet}/upload/base64/json`,
			method: 'POST',
			data
		})
	}

	// DOC ---------------------------------------------------------------------
	// Get metadata
	static getMetadata(cabinet, id) {
		return request({
			url: `${DW_API}/${cabinet}/metadata/${id}/json`,
			method: 'GET'
		})
	}

	// Update metadata
	static updateMetadata(cabinet, id, data) {
		return request({
			url: `${DW_API}/${cabinet}/update/${id}/json`,
			method: 'PATCH',
			data
		})
	}

	// Download doc
	static downloadDocument(cabinet, id, type='pdf') {
		return request({
			url: `${DW_API}/${cabinet}/download/${id}/${type}`,
			method: 'GET'
		})
	}

	// Doc thumbnail
	static getThumbnail(cabinet, id) {
		return request({
			url: `${DW_API}/${cabinet}/thumbnail/${id}`,
			method: 'GET'
		})
	}

	// Doc thumbnails (base 64)
	static getThumbnails(cabinet, id) {
		return request({
			url: `${DW_API}/${cabinet}/metadata/${id}?base64_thumbs=1`,
			method: 'GET'
		})
	}

	//Delete docuware items
	static deleteDocuments(cabinet,data) {
		return request({
			url: `/docuware/3/${cabinet}/delete/json`,
			method:'DELETE',
			data
		})
	}

	//Get Document Types
	static getDocTypes(cabinet){
		return request({
			url:`${DW_API}/${cabinet}/doctypes/json`,
			method:'GET'
		})
	}

	static getContacts(div_id){
		return request({
			url:`${DW_API}/contacts/${div_id}/json`,
			method:'GET'
		})
	}

	static getServices(cabinet,data){
		return request({
			url:`${DW_API}/${cabinet}/services/json`,
			method:'POST',
			data
		})
	}

	static printUrl(cabinet, documents){
		return request({
			url:`${DW_API}/${cabinet}/print/json`,
			method:'POST',
			data: {documents}
		})
	}

	static getGDriveFiles(){
		return request({
			url:`${DW_API}/docs/json`,
			method:'GET'
		})
	}

	static getUploadedGDriveDocs(){
		return request({
			url:`${DW_API}/uploaded/docs/json`,
			method:'GET'
		})
	}

	static downloadGDrive(uploaded,filename){
		return request({
			url:`${DW_API}/g_drive/document/json?uploaded=${uploaded}&download=true&filename=${encodeURIComponent(filename)}`,
			method:'GET'
		})
	}

	static moveToGDrive(filename){
		return request({
			url:`${DW_API}/g_drive/move/json`,
			method:'POST',
			data: {filename}
		})
	}

	// Download doc
	static downloadDocumentV2(cabinet, id) {
		return request({
			url: `${DW_API}/${cabinet}/download_v2/${id}`,
			method: 'GET'
		})
	}

	static getFileInfo(cabinet,fileNo,servId = false){
		const addServId = servId ? '?serv_id='+servId : ''
		return request({
			url: `${DW_API}/${cabinet}/file_info/${fileNo}/json${addServId}`,
			method: 'GET'
		})
	}

	static getNotificationLetter(letterId){
		return request({
			url: `${DW_API}/notification_letter/${letterId}/json`,
			method: 'GET'
		})
	}

	static getNotificationLettersByUccNo(uccNo){
		return request({
			url: `docuware/3/notification_letters/${uccNo}/json`,
			method: 'GET'
		})
	}

	//Update a doc type
	static updateDocType(cabinet,docType){
		return request({
			url:`${DW_API}/${cabinet}/doctype/json`,
			method:'POST',
			data: {...docType}
		})
	}

	//Delete a doc type
	static deleteDocType(cabinet,id){
		return request({
			url:`${DW_API}/${cabinet}/doctype/${id}/json`,
			method:'DELETE'
		})
	}
}

