import {
	SNOOZE_MODAL,
	SNOOZE_PROJECT,
	SNOOZE_FOLLOW_UP_CHANGE,
	CHANGE_SNOOZED_CHECKED
} from './action';
import formatDate from "../../utils/formatDate";

export default function SnoozeModalReducer(state = {show:false,info:{},follow_up:formatDate(new Date())}, action) {
	switch (action.type) {
		case SNOOZE_MODAL:
			return {...state,show:action.open,info:{}};
		case SNOOZE_PROJECT:
			return {...state,info:action.open_service};
		case SNOOZE_FOLLOW_UP_CHANGE:
			return {...state,follow_up:action.follow_up};
		case CHANGE_SNOOZED_CHECKED:
			state.info.open_services[action.key].checked = action.checked;
			return {...state};
		default:
			return state;
	}
}