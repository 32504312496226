export default function NoteTagList({note_tags,dynamic_tags,selected,select}) {
    const tags = dynamic_tags ?
        [...note_tags,...dynamic_tags.map(dTag=>({id:Math.random(),tag:dTag.value,label:dTag.label,preset:0}))]
            :
        [...note_tags]

    return <div className={'dropdown-menu'}>
        <form>
            {tags?.map((tag, i) => {
                return <a
                    key={i}
                    className={'dropdown-item pointer'}
                    onClick={()=>{select(tag)}}
                >
                    {selected.includes(tag.tag) &&
                    <span className={'far fa-check mr-1'}/>}
                    {tag?.label??tag.tag}
                </a>
            })}
        </form>
    </div>
}