import React, {useState, useEffect, useContext, Fragment, useRef} from 'react'
import {MessageContext} from "../Context/GlobalState";
import {PortalContext} from "../../GlobalState";
import LoadingSpinner from "../../utils/LoadingSpinner";
import formatDate from "../../utils/formatDate";
import FileUploadBtn from "../../utils/FileUploadBtn";
import anchorme from 'anchorme'
import FileDownload from "./FileDownload";
import AddPerson from "./AddPerson";
import ScrollToBottom from "./ScrollToBottom";

export default function Messages(props) {
	const {
		selected,
		findSelected,
		sendNewMessage,
		isLoading,
		contacts,
		updateViewed,
		downloadFile,
		downloadAttachments
	 } = useContext(MessageContext)
	const {employee} = useContext(PortalContext)
	const [ newMessageText, setNewMessageText ] = useState('')
	const [ attachments, setAttachments ] = useState([])
	const [ toList, setToList ] = useState([])
	const [ errorText, setErrorText ] = useState(false)
	const selectedMessages = findSelected(selected)
	const textareaRef = useRef()

	useEffect(()=>{
		scrollToBottom()
		textareaRef.current.focus()
		setNewMessageText('')
	},[selectedMessages])

	function scrollToBottom(){
		const messageContainer = document.getElementById('messagesList')
		if(messageContainer){
			messageContainer.scrollTop = messageContainer?.scrollHeight ?? 0
		}
	}

	function sendMessage(e){
		e && e.preventDefault()
		if(newMessageText?.length === 0){
			setErrorText(true)
		}
		else {
			const current_message = selectedMessages?.id ?
				{
					...selectedMessages,
					message_group_id: selectedMessages.id
				}
				:
				{
					message_group_id: Math.random() * -1,
					members: [...toList]
				}
			sendNewMessage(newMessageText, attachments, current_message)
			setNewMessageText('')
			setToList([])
			setErrorText(false)
			setAttachments([])
		}
	}

	function createLinks(input){
		return anchorme({
			input,
			options: {
				attributes: {
					// target: "_blank",
					class: "chat-link"
				},
			},
		})
	}

	function keyDown(e){
		if(e.key === 'Enter'){
			if(!e.ctrlKey) {
				sendMessage()
			}
			else{
				setNewMessageText(newMessageText+'\n')
			}
		}
	}

	return (
		<div style={{
			position:'absolute',
			top:0,
			bottom:0,
			left:0,
			right:0
		}}>
			<div
				id={'messagesList'}
				style={{
					position:'absolute',
					top:0,
					bottom:65,
					left:0,
					right:0,
					overflow:'auto',
					borderBottom:'1px solid rgb(222, 226, 230)',
					padding:10
				}}
			>
				{/*<ScrollToBottom id={'messagesList'}/>*/}
				{selectedMessages?.messages?.length > 0 ?
					selectedMessages.messages.map((message,i) => {
						const prev = i > 0 ? selectedMessages.messages[i-1] : false
						const next = i < selectedMessages.messages.length ? selectedMessages.messages[i+1] : false
						const prevEqual = prev && prev.sender === message.sender
						const nextEqual = next && next.sender === message.sender
						const display = message.sender == employee?.cnt_id ? false : prevEqual ? false : message?.sender_name
						const dateFormat = formatDate(message.sent) === formatDate(new Date()) ? 'h:mm a' : 'M-D-Y h:mm a'
						return <div
							key={message.id}
							className={'chat-message mb-1 message-' + (message.sender == employee?.cnt_id ? 'sender' : 'receiver')}
						>
							{display && <strong className={'ml-1'}>{display}</strong>}
							<div className={'message-bubble'}>
								{message.attachments?.map(attachment=> {
									return <div
										key={attachment.id}
										className={'message-attachment'}
									>
										{!attachment.thumbnail ?
											<div
												className={'download-message-attachment'}
												onClick={()=>{
													downloadAttachments(message.id)
												}}
											>
												<span className={'fal fa-file-exclamation fa-5x'}/>
											</div>
											:
											<Fragment>
												<img
													className={'img-thumbnail d-block'}
													style={{maxWidth:400,maxHeight:400}}
													src={attachment.thumbnail}
													alt={attachment.name}
												/>
												<FileDownload
													key={attachment.id}
													id={attachment.id}
													name={attachment.name}
													download={downloadFile}
													className={'message-image-above'}
												/>
											</Fragment>
										}
									</div>
								})}
								<span dangerouslySetInnerHTML={{__html:createLinks(message.message)}}/>
							</div>
							{!nextEqual && <small className={'d-block'}>{formatDate(message.sent,dateFormat)}</small>}
						</div>
					})
					:
					<AddPerson
						contacts={contacts}
						update={(list)=>setToList(list)}
						list={toList}
					/>
				}
			</div>
			<div
				style={{
					position:'absolute',
					bottom:5,
					left:5,
					right:5
				}}
			>
				<div className={'row'}>
					<div className={'col'}>
					{attachments.map((attachment,i)=>(
						<span
							key={attachment.lastModified}
							className={'badge badge-pill badge-secondary p-2 mb-1 mr-2'}
						>
							{attachment.name}
							<span
								className={'far fa-times float-right pointer ml-2'}
								onClick={()=>{
									setAttachments(attachments.filter((a,index)=>index!==i))
								}}
							/>
						</span>
					))}
					</div>
				</div>
				<form onSubmit={sendMessage}>
					<div className={'input-group'}>
						<textarea
							className={'form-control'}
							placeholder={'Type message here...'}
							onChange={(e)=>{setNewMessageText(e.target.value)}}
							onKeyDown={keyDown}
							value={newMessageText}
							ref={textareaRef}
							disabled={isLoading}
							required
						/>
						<div className="input-group-append">
							<FileUploadBtn
								btnStyle={'outline-secondary'}
								classNames={' d-flex align-items-center'}
								onChange={(e)=>{setAttachments([...attachments,...e.target.files]);textareaRef.current.focus()}}
							>
								<span className={'far fa-plus'}/>
							</FileUploadBtn>
							<button
								className={'btn btn-outline-secondary'}
								onClick={()=>{
									setNewMessageText(newMessageText+(newMessageText?.length === 0 ? '':" ")+window?.location?.href)
								}}
								disabled={isLoading}
							>
								<span className={'far fa-link'}/> Link
							</button>
							<button
								className={'btn btn-outline-secondary'}
								onClick={sendMessage}
								disabled={isLoading}
							>
								{isLoading?<LoadingSpinner/> : <span className={'far fa-paper-plane'}/>} Send
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}