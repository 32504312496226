import { useContext } from 'react'
import LeftNav from '../../utils/LeftNav'
import NavItem from "../../utils/NavItem";
import {Context} from "../tracker/Context/GlobalState";
import {PortalContext} from "../../GlobalState";

export default function ReportNav(props){
    const {
        collapsed,
        toggleCollapse,
    } = useContext(Context)
    const {employee:{admin}} = useContext(PortalContext)

    const links = [
        {
            title:'Reports',
            href:'/nml/report',
            icon:'fa-chart-line',
            links:[
                {
                    title:'Assignments',href:'/nml/report/assignments'
                },
                {
                    title:'LTO Notices Per day',href:'/nml/report/lto_notices_per_day'
                },
                {
                    title:'Notices Per day',href:'/nml/report/notices_per_day'
                },
                {
                    title:'Past Reviews',href:'/nml/report/past_reviews'
                },
				{
					title:'Queue',href:'/nml/report/queue'
				},
            ]
        },
    ]

    return(
        <LeftNav
            collapsed={collapsed}
            collapse={toggleCollapse}
            noSearch
            icon={'csg'}
            title={'Reports'}
            back={'/nml/tracker/dashboard'}
            backBtnTitle={'Go to Tracker'}
        >
            {links.map(link=>(
                <NavItem
                    key={Math.random()}
                    info={link}
                    collapsed={collapsed}
                />
            ))}
        </LeftNav>
    )
}