import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Context} from "../../Context/GlobalState";
import LoadingSpinner from "../../../../utils/LoadingSpinner";

export default function ClearNotifications({note}) {
    const { confirmNote } = useContext(Context)
    const [ loading, setLoading ] = useState(false)

    function confirmTags(){
        setLoading(true)
        confirmNote(note.id,(data)=>{
            setLoading(false)
        })
    }

    return <span
        className={'badge badge-pill badge-danger pointer mr-1'}
        onClick={confirmTags}
    >
        {loading?<LoadingSpinner/>:<span className={'far fa-bell-slash'}/>} need confirmed
    </span>
}