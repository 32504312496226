// Bootstrap styled file picker button

import React, { Component } from 'react';

export default class FileUploadBtn extends Component {
    render() {
        const { 
            id,             // ID assigned to label element 
            classNames,     // Classnames to append to label element 
            btnStyle,       // Desired bootstrap button styling if you want to override 'default'
            style,          // Style to append to label element
            onChange,       // Callback to call when file is selected [REQUIRED]
            single          // Set true if only one file is allowed to be selected
        } = this.props;

        var bsBtn = "mb-0 btn btn-" + ( btnStyle ? btnStyle : "default " );

        return (
            <label 
                className = { bsBtn + ( classNames ? classNames : " " ) + ' pointer' }
                style =     { style ? style : null }
                id =        { id ? id : null }
            >

                {   this.props.children ? 
                    this.props.children :
                        <span className = "far fa-plus" style={{marginRight: "5px"}}/>
                }
                <input 
                    style =     { {display: "none"} }
                    type =      "file" 
                    className = "form-control-file" 
                    onChange =  { onChange ? onChange : null }
                    multiple =  { single ? false : true}
                />File(s)
            </label>
        )
    }
}