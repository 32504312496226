import {Fragment} from 'react'
import filter from "lodash/filter";

export default function TagsList({tags,setTagsSelected,dynamic_tags = undefined}) {
	return (
		<Fragment>
			<div className={'col-10 mt-3'}>
				{tags.map((tag, i) => {
					const dynamic = dynamic_tags ? dynamic_tags?.find(dTag=>dTag.value===tag)?.label ?? undefined : undefined
					return <span className={'h5'} key={i}>
						<span
							className={'badge badge-pill badge-secondary mr-2'}
						>
							{dynamic ?? tag}
							<span
								className={'far fa-times pointer ml-1'}
								onClick={() => {
									setTagsSelected(filter(tags, (r) => (r !== tag)))
								}}
							/>
						</span>
					</span>
				})}
			</div>
			<div className={'col-2 mt-2'}>
				{tags?.length > 1 &&
				<button
					className={'btn btn-outline-danger ml-1 float-right'}
					onClick={() => {
						setTagsSelected([])
					}}
				>
					<span className={'far fa-trash-alt'}/> Tags
				</button>
				}
			</div>
		</Fragment>
	)
}