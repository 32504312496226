import React, {useState, useEffect, useContext, Fragment} from 'react'
import {DocuwareContext} from "../Context/GlobalState";
import LoadingSpinner from "../../utils/LoadingSpinner";
import Footer from "./Footer";
import DocFilePdf from "./DocFilePdf";

export default function DocDelete(props) {
	const {
		modal,
		checkedDocs,
		setScreen,
		cabinet,
		deleteDocs,
		isLoading,
		setModalSize
	} = useContext(DocuwareContext)

	useEffect(()=>{
		setModalSize('')

		return ()=>{setModalSize('lg')}
	},[])

	return (
		<Fragment>
			<div className={modal ? 'modal-body':'mt-3'}>
				{isLoading ?
					<h3><LoadingSpinner/> Loading</h3>
					:
					<Fragment>
						<h5 className={'pb-3'}>Are you sure you want to delete these documents:</h5>
						{checkedDocs.map(doc=>(
							<div className={'row'} key={doc.id}>
								<div className={'col'}>
									<h5>
										<DocFilePdf id={doc.id} cabinet={cabinet}/>
										<span className={'ml-3'}>{doc.document_type}</span>
									</h5>
								</div>
							</div>
						))}
					</Fragment>
				}
			</div>
			<Footer>
				<button
					className={'btn btn-danger'}
					onClick={deleteDocs}
					disabled={isLoading}
				>
					<span className={'far fa-trash-alt'}/> Delete
				</button>
			</Footer>
		</Fragment>
	)
}