import Head from "next/head";
import GlobalState from './ClientMaintenanceContext'

export default function ClientMaintenanceLayout({children}) {
	return (
		<GlobalState>
			<div className="container">
				<Head><title>Client Maintenance</title></Head>
				<div className="p-3">
					{children}
				</div>
			</div>
		</GlobalState>
	)
}