import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk';
import reducers from './bankruptcy/reducers';
import {Provider} from "react-redux";
const middleware = applyMiddleware(thunk);
const store = createStore(
	reducers,
	middleware
);
import {useRouter} from "next/router";
import Head from "next/head";
import PhoneListGlobalState from './phone_list/Context/GlobalState'

export default function ToolsLayout({children}) {
	const {pathname} = useRouter()
	return pathname.startsWith('/tools/bankruptcy') ?
		<Provider store={store}>
			<div className={'container-fluid'}>
				<Head><title>Bankruptcy Monitoring</title></Head>
				{children}
			</div>
		</Provider>
		:
		pathname.startsWith('/tools/phone_list') ?
			<PhoneListGlobalState>
				<Head><title>Phone List</title></Head>
				<div className={'p-3'}>
					{children}
				</div>
			</PhoneListGlobalState>
			:
			pathname.startsWith('/tools/imports') ?
				<div className="container-fluid">
					<Head><title>Imports</title></Head>
					<div className="page">
						{children}
					</div>
				</div>
				:
				children
}