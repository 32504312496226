import React, {useState, useEffect, useContext} from 'react'
import axios from '../../axios';
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';
const ROOT_URL = process.env.API_HOST
import {PortalContext} from "../../GlobalState";

export default function DocPreviewer(props) {
	const {employee} = useContext(PortalContext)
	const [ url, setUrl ] = useState(0)
	const [ images, setImages ] = useState([])
	const [ loading, setLoading ] = useState(false)
	const [ gotData, setData ] = useState(false)
	const { show, onClose } = props;

	useEffect(()=>{
		setImages( [{
			src: `${ROOT_URL}/docuware/3/${props.cabinet}/image/${props.docId}?page=0&api_key=${employee?.api_key}`,
			downloadUrl: `${ROOT_URL}/docuware/3/${props.cabinet}/download/${props.docId}/pdf&api_key=${employee?.api_key}`
		}])
	},[])

	useEffect(()=>{
		// console.log('show',show && !gotData)
		show && !gotData && getDocumentMetadata()
	},[show])

	function getDocumentMetadata() {
		console.log('getting metadata')
		setLoading(true)
		axios.get(`/docuware/3/${props.cabinet}/metadata/${props.docId}/json?base64_thumbs=1`)
			.then(({data}) => {
				if(data.id) {
					let images = [];
					for(let i = 0; i < (data.page_count > 10 ? 10 : data.page_count); i++) {
						console.log('image',`${ROOT_URL}/docuware/3/${props.cabinet}/image/${props.docId}?page=${i}&api_key=${employee?.api_key}`)
						images.push({
							src: `${ROOT_URL}/docuware/3/${props.cabinet}/image/${props.docId}?page=${i}&api_key=${employee?.api_key}`,
							thumb: `data:image/png;base64, ${data.thumbnails[i]}`,
							downloadUrl: `${data.download}&api_key=${employee?.api_key}`
						})
					}

					setLoading(false)
					setImages(images)
					setUrl(data.viewer)
					setData(true)
				}
			})
	}

	return (
		<Viewer
			onClose = {onClose}
			visible = {show}
			images = {images}
			activeIndex = {0}
			zIndex={2000}
			customToolbar = {(toolbar) => {
				return [...toolbar, {
					key: 'dw-link',
					render: <span
						className={'far fa-external-link-alt'}
					/>,
					onClick: ()=>{window.open(url, "_blank");}
				}]
			}}
			scalable={false}
			zoomSpeed={.5}
			onMaskClick={onClose}
			noImgDetails={true}
			downloadable={false}
		/>
	)
}