import React, {useState, useEffect, useContext} from 'react'
import GlobalState from './Context/GlobalState'
import Docuware from './components/Docuware'

export default function Index(props) {
	return (
		<GlobalState {...props}>
			<Docuware {...props}/>
		</GlobalState>
	)
}