import React, {useState, useEffect, useContext, Fragment} from 'react'
import {DocuwareContext} from "../Context/GlobalState";

export default function Footer(props) {
	const { setshowModal, modal, setScreen, screen } = useContext(DocuwareContext)

	return (
		<div className={modal?'modal-footer':'docuware-footer float-right'}>
			{props.children}
			{screen !== 'list' && !props.noBackBtn &&
			<button
				className={'btn btn-default'}
				onClick={() => {
					setScreen('list');
					props.goBack && props.goBack()
				}}
			>
				<span className={'fas fa-caret-left'}/> Back
			</button>
			}
			{modal &&
				<button
					className={'btn btn-default'}
					onClick={()=>{setshowModal(false);setScreen('list')}}
				>
					<span className={'far fa-times'}/> Close
				</button>
			}
		</div>
	)
}