import {useContext, useState, Fragment} from 'react'
import LeftNav from '../../../utils/LeftNav'
import NavItem from '../../../utils/NavItem'
import {Context,initialFilters} from "../Context/GlobalState";
import TrackerLink from "../Utils/TrackerLink";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import Collapse from "../../../utils/Collapse";
import Overlay from "../../../utils/Overlay";
import formatDate from "../../../utils/formatDate";
import Link from "next/link";
import NavFilters from "./NavFilters";
import NavQueue from "./NavQueue";

export default function DashboardNav(props){
	const {
		collapsed,
		toggleCollapse,
		search,
		setSearch,
		isLoading,
		getFiles,
		setSearchAggs
	} = useContext(Context)

	const [view,setView] = useState(1)

	const submit = (search) => {
		setSearch(search)
		getFiles(search)
		setSearchAggs({})
	}

	// console.log('dash nav',collapsed)

	return(
		<LeftNav
			collapsed={collapsed}
			collapse={toggleCollapse}
			search={search}
			submit={submit}
			icon={'csg'}
			title={'LienTracker'}
		>
			<NavItem
				info={{
					title:' Request',
					href:'/nml/tracker/request/form',
					icon:'fa-folder-plus'

				}}
				collapsed={collapsed}
			/>
			<TrackerLink/>
			<div className={'side-nav-filters'}>
				<div className={'hide-side-nav-collapse'}>
					{isLoading && <Fragment><LoadingSpinner/> Loading...</Fragment>}
					<ul className="nav nav-pills nav-fill">
						<li className="nav-item" onClick={()=>{setView(0)}}>
							<a className={"nav-link "+(view===0?'active':'')} href="#"><span className={'fas fa-user'}/></a>
						</li>
						<li className="nav-item" onClick={()=>{setView(1)}}>
							<a className={"nav-link "+(view===1?'active':'')} href="#"><span className={'far fa-search-plus'}/></a>
						</li>
					</ul>
					<div className={'mt-3'}>
						{view === 0 && <NavQueue/>}
						{view === 1 && <NavFilters/>}
					</div>
				</div>
			</div>
		</LeftNav>
	)
}