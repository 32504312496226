import React, {useEffect, useState} from 'react'
import { useRouter } from "next/router";

export default function LeftNav(props){
	const [ search, setSearch ] = useState('')
	const [ top, setTop ] = useState(false)
	const [ hover, setHover ] = useState(false)
	const inputRef = React.useRef()
	const router = useRouter()
	const { collapsed, title, icon, noSearch, backBtnTitle } = props

	useEffect(()=>{
		setTop(document.getElementById('sideNavHeader')?.clientHeight ?? 113)
	},[hover])

	useEffect(()=>{
		setSearch(props.search)
	},[props.search])

	const onSubmit = (e) => {
		e.preventDefault()

		props.submit(search)
	}

	return(
		<div className={'side-nav left-nav ' + (collapsed ? 'side-nav-collapsed':'')} onMouseEnter={()=>{setHover(true)}} onMouseLeave={()=>{setHover(false)}}>
			<div className={'side-nav-header'} id={'sideNavHeader'}>
				{props.back &&
					<button
						className={'btn btn-outline-secondary mb-2 ' + (collapsed && !hover ? 'btn-sm':'')}
						data-toggle="tooltip"
						title={backBtnTitle ?? 'Back to Dashboard'}
						onClick={()=>{router.push(props.back)}}
					>
						<span className={'far fa-arrow-left'}/><span className={'hide-side-nav-collapse'}><span className={'ml-2'}>{backBtnTitle ?? 'Dashboard'}</span></span>
					</button>
				}
				<h4><span className={'far fa-'+icon}/><span className={'hide-side-nav-collapse'}><span className={'ml-2'}>{title}</span></span></h4>
				{!noSearch &&
					<div className={'hide-side-nav-collapse'}>
						<form onSubmit={onSubmit}>
							<div className={'input-group'}>
								<input
									ref={inputRef}
									className={'form-control'}
									name={'search'}
									placeholder={'Search'}
									value={search}
									onChange={e => setSearch(e.target.value)}
								/>
								<div className="input-group-append">
									{search?.length > 0 &&
										<button
											className={'btn btn-outline-secondary'}
											onClick={()=>{setSearch('')}}
											type={'button'}
										>
											<span className={'far fa-times'}/>
										</button>
									}
									<button
										className="btn btn-outline-secondary"
										type={'submit'}
										onClick={()=>{props.submit(search)}}
									>
										<span className={'far fa-search'}/>
									</button>
								</div>
							</div>
						</form>
					</div>
				}
			</div>
			<div className={'side-nav-body'} style={{top}}>
				{props.children}
			</div>
			<div className={'side-nav-footer pointer'} onClick={()=>{props.collapse()}}>
				{collapsed ?
					<div><span className={'fas fa-thumbtack'}/> <span className={'hide-side-nav-collapse'}>Pin Sidebar</span></div>
					:
					<span><span className={'fas fa-arrow-alt-to-left'}/> Collapse Sidebar</span>
				}
			</div>
		</div>
	)
}