import React, {useState, useEffect, useContext, Fragment} from 'react'

export default function Collapse({children,startOpen,title,className}) {
	const [ id ] = useState(Math.round(Math.random()*1000))
	const [ opened, setOpened ] = useState(startOpen)
	const [ startCollapsed ] = useState('collapse '+(startOpen?'show':''))

	useEffect(()=>{
		if(startOpen !== opened){
			setOpened(startOpen)
			$('#collapse'+id).collapse('toggle')
		}
	},[startOpen])

	return(
		<Fragment>
			<div
				className={'pointer '+(className ?? '')}
				onClick={()=>{setOpened(!opened)}}
				data-toggle={'collapse'}
				data-target={'#collapse'+id}
			>
				{title}
				<span className={`far fa-chevron-${(opened?'up':'down')} float-right`}/>
			</div>
			<div className={startCollapsed} id={'collapse'+id}>
				{children}
			</div>
		</Fragment>
	)
}