import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import Link from 'next/link'
import { useContext } from 'react'
import { PortalContext } from './GlobalState'
import Messages from './messages'

export default function Header(props) {
    const { employee, auth, admin } = useContext(PortalContext)
    const manager = admin && admin > 0 && admin <= 10
    let backgroundColor = 'lienfinder'

    switch (process.env.APPLICATION_ENV) {
        case 'DEVELOPMENT':
            backgroundColor = 'danger'
            break
        case 'STAGING':
        case 'TESTING':
            backgroundColor = 'coll-secondary'
            break
        case 'PRODUCTION':
            backgroundColor = 'ucc-secondary'
            break
    }

    const links = [
        {
            title: 'Administrator',
            href: '/administrator',
            icon: 'far fa-key',
            dropdown: [
                { title: 'Attorney Maintenance', href: '/administrator/attorney_maintenance' },
                { title: 'Employee Maintenance', href: '/administrator/emp_maintenance' },
                { title: 'Legislation Updates', href: '/administrator/legislation_updates' },
                {
                    title: 'Liens',
                    href: '/administrator/liens/fees',
                    submenu: [
                        { title: 'Fees', href: '/administrator/liens/fees' },
                        { title: 'Generic Questions', href: '/administrator/liens/questions' },
                        { title: 'Services', href: '/administrator/liens/services' },
                    ],
                },
                {
                    title: 'LTO Postage Administration',
                    href: '/administrator/lto_postage_administration',
                },
                {
                    title: 'Online Services',
                    href: '/administrator/ols_admin/faqs',
                    submenu: [
                        { title: 'FAQs', href: '/administrator/ols_admin/faqs' },
                        { title: 'Glossary', href: '/administrator/ols_admin/glossary' },
                        { title: 'Links', href: '/administrator/ols_admin/links' },
                        { title: 'OLS Alerts', href: '/administrator/ols_admin/ols_alerts' },
                        { title: 'On Demand', href: '/administrator/ols_admin/on_demand' },
                    ],
                },
                { title: 'State Maintenance', href: '/administrator/state_maintenance' },
            ],
        },
        {
            title: 'NML',
            href: '/nml',
            icon: 'far fa-csg',
            dropdown: [
                { title: 'Attorney Follow Up', href: '/nml/attorney' },
                {
                    title: 'Maintenance',
                    href: '/nml/maintenance/assignments',
                    submenu: [
                        { title: 'Assignments', href: '/nml/maintenance/assignments' },
                        { title: 'Email Templates', href: '/nml/maintenance/email_templates' },
                        manager && { title: 'Research', href: '/nml/maintenance/research' },
                        manager && {
                            title: 'Docuware Doc Types',
                            href: '/nml/maintenance/docuware_doc_types',
                        },
                        {
                            title: 'Reviewers By State',
                            href: '/nml/maintenance/reviewers_by_state',
                        },
                        { title: 'State Maintenance', href: '/nml/maintenance/state_maintenance' },
                        { title: 'Template Editor', href: '/nml/maintenance/template_editor' },
                    ],
                },
                {
                    title: 'Reports',
                    href: '/nml/report/assignments',
                    submenu: [
                        { title: 'Assignments', href: '/nml/report/assignments' },
                        { title: 'LTO Notices Per Day', href: '/nml/report/lto_notices_per_day' },
                        { title: 'Notices Per Day', href: '/nml/report/notices_per_day' },
                        { title: 'Past Reviews', href: '/nml/report/past_reviews' },
                        { title: 'Queue', href: '/nml/report/queue' },
                    ],
                },
                { title: 'Request', href: '/nml/tracker/request/form' },
                { title: 'Tracker', href: '/nml/tracker/dashboard' },
            ],
        },
        {
            title: 'UCC',
            href: '/ucc',
            icon: 'fas fa-ucc',
            dropdown: [
                { title: 'Email Templates', href: '/ucc/email_templates' },
                {
                    title: 'Reports',
                    href: '/ucc/report/errors',
                    submenu: [
                        { title: 'Errors', href: '/ucc/report/errors' },
                        { title: 'Incorrect File Dates', href: '/ucc/report/fileDates' },
                        { title: 'Log', href: '/ucc/report/log' },
                        { title: 'Monitoring', href: '/ucc/report/monitoring' },
                        { title: 'Notification Letters', href: '/ucc/report/notes_report' },
                    ],
                },
                { title: 'Settings', href: '/ucc/settings' },
                { title: 'UFile', href: '/ucc/' },
                { title: 'Wk Search', href: '/ucc/search' },
                { title: 'Workflow', href: '/ucc/workflow' },
            ],
        },
        {
            title: 'Client Maintenance',
            href: '/client_maintenance',
            icon: 'far fa-address-card',
        },
        {
            title: 'Tools',
            href: '/tools',
            icon: 'far fa-wrench',
            dropdown: [
                { title: 'Bankruptcy Monitoring', href: '/tools/bankruptcy' },
                { title: 'Import Reports', href: '/tools/imports' },
                { title: 'Paylocity', href: 'https://access.paylocity.com/' },
                { title: 'Phone List', href: '/tools/phone_list' },
                { title: 'URL Shortener', href: '/tools/url_shortener' },
                {
                    title: 'CM Change Request',
                    href: 'http://ncs.credit/EA378-C93EB',
                },
                {
                    title: 'Service Agreement',
                    href: 'https://www.ncscredit.com/ncs-credit-service-agreement-form-1/'
                }
            ],
        },
    ]

    return (
        <nav className={`navbar fixed-top navbar-expand-md navbar-dark bg-${backgroundColor}`}>
            <Link href={'/'} passHref>
                <a className='navbar-brand m-0 p-0 d-flex align-items-center'>
                    <img
                        src={'/images/header/ncs-new-logo.png'}
                        alt={'NCS Credit Logo'}
                        height={'35px'}
                    />
                    <span className='h4 m-0 ml-2'> Portal</span>
                </a>
            </Link>
            <button
                className='navbar-toggler'
                type='button'
                data-toggle='collapse'
                data-target='#portalNavbar'
                aria-controls='portalNavbar'
                aria-expanded='false'
                aria-label='Toggle navigation'
            >
                <span className='navbar-toggler-icon' />
            </button>
            <div className='collapse navbar-collapse ml-3' id='navbarSupportedContent'>
                <ul className='navbar-nav mr-auto'>
                    {employee?.api_key && (
                        <AuthenticatedTemplate>
                            {links?.map((link, i) => {
                                if (!link) {
                                    return null
                                }
                                return link?.dropdown ? (
                                    <li className={'nav-item dropdown'} key={i}>
                                        <a
                                            className='nav-link dropdown-toggle'
                                            href={'#'}
                                            data-toggle='dropdown'
                                        >
                                            <span className={link.icon + ' mr-1'} />
                                            {link.title}
                                        </a>
                                        <div className={'dropdown-menu'}>
                                            {link.dropdown.map((item, i) => {
                                                if (!item) {
                                                    return null
                                                }
                                                return item?.submenu?.length > 0 ? (
                                                    <div className={'dropdown-submenu'} key={i}>
                                                        <NextLink
                                                            href={item.href}
                                                            passHref
                                                            title={item.title}
                                                        >
                                                            <a
                                                                className={'dropdown-item'}
                                                                tabIndex={'-1'}
                                                            >
                                                                {item.title}
                                                            </a>
                                                        </NextLink>
                                                        <div className={'dropdown-menu'}>
                                                            {item.submenu.map((sub, i) => {
                                                                if (!sub) {
                                                                    return null
                                                                }
                                                                return (
                                                                    <NextLink
                                                                        href={sub.href}
                                                                        passHref
                                                                        key={i}
                                                                        title={sub.title}
                                                                    >
                                                                        <a
                                                                            className={
                                                                                'dropdown-item'
                                                                            }
                                                                            href={sub.href}
                                                                        >
                                                                            {sub.title}
                                                                        </a>
                                                                    </NextLink>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <NextLink
                                                        href={item.href}
                                                        key={i}
                                                        title={item.title}
                                                    >
                                                        <a className={'dropdown-item'} href={'#'}>
                                                            {item.title}
                                                        </a>
                                                    </NextLink>
                                                )
                                            })}
                                        </div>
                                    </li>
                                ) : (
                                    <Link href={link.href} key={i} passHref>
                                        <li className={'nav-item'}>
                                            <a className='nav-link' href={'#'}>
                                                <span className={link.icon + ' mr-1'} />
                                                {link.title}
                                            </a>
                                        </li>
                                    </Link>
                                )
                            })}
                            <Messages />
                        </AuthenticatedTemplate>
                    )}
                </ul>
                <ul className={'navbar-nav ml-auto'}>
                    <AuthenticatedTemplate>
                        <li className={'nav-item dropdown'} id={'navbar_right'}>
                            <a
                                className={'nav-link dropdown-toggle'}
                                href='#'
                                data-toggle='dropdown'
                            >
                                {employee?.FirstName
                                    ? employee?.FirstName + ' ' + employee?.LastName
                                    : auth?.user?.displayName ?? "Can't find name"}
                            </a>
                            <div className={'dropdown-menu dropdown-menu-right'}>
                                {employee?.CNTEMAIL !== employee?.real_email &&
                                    employee?.real_email?.length > 0 && (
                                        <a
                                            className={'dropdown-item'}
                                            href={'#'}
                                            onClick={() => {
                                                auth.getEmployee(employee.real_email)
                                            }}
                                        >
                                            Change Back
                                        </a>
                                    )}
                                <a className={'dropdown-item'} href={'#'} onClick={auth.signOut}>
                                    Sign Out
                                </a>
                            </div>
                        </li>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <li className={'nav-item'} id={'navbar_right'}>
                            <a className={'nav-link'} onClick={auth.signIn} href={'#'}>
                                <span className={'far fa-sign-in'} /> Sign In
                            </a>
                        </li>
                    </UnauthenticatedTemplate>
                </ul>
            </div>
        </nav>
    )
}

function NextLink(props) {
    if (props?.href?.startsWith('http')) {
        return (
            <a href={props.href} target={'_blank'} className={'dropdown-item'} rel={'noreferrer'}>
                {props.title}
            </a>
        )
    } else {
        return <Link {...props}>{props.children}</Link>
    }
}
