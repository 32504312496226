import React, {useContext, Fragment, useEffect} from 'react'
import {Context} from "../Context/GlobalState";
import {TrackerLinkContext} from "../Context/TrackerLinkContext"
import LoadingSpinner from "../../../utils/LoadingSpinner";
import NavItem from "../../../utils/NavItem";

export default function TrackerLink(props) {
	const { collapsed } = useContext(Context)
	const {
		trackerLinksLoading,
		trackerLinks,
		trackerLinked,
		changeListener
	} = useContext(TrackerLinkContext)

	// console.log('tracker linked',trackerLinked)
	const firstLink = trackerLinks[0]

	return (
		<Fragment>
			{!trackerLinksLoading && trackerLinks?.length > 0 &&
			<NavItem
				info={/*trackerLinks?.length === 1 ?*/
					// {
					// 	icon: 'fa-csg',
					// 	title:<Fragment>{firstLink.display}: {firstLink.ncs_lno ?? firstLink.direct_no ?? 'Open File in Tracker'}{firstLink?.project_name &&
					// 	<span> - {firstLink.project_name}</span>}</Fragment>,
					// 	href:firstLink
					// }
					// :
					{
						title: trackerLinked?.id ?
							<span>Linked to Tracker: <strong>{trackerLinked.display}</strong></span>
							:
							trackerLinks?.length > 0 ?
								<span>Connect to {trackerLinks[0]?.display}?</span>
								:
								'Open File From Tracker',
						href: trackerLinks?.[0] ?? false,
						icon: 'fa-csg',
						links: trackerLinks?.length > 0 ?
							[
								trackerLinked?.id && {title: 'Unlink', href: false},
								...trackerLinks.map(link => (
									{
										title:
											<Fragment>{link.display}: {link.ncs_lno ?? link.direct_no ?? 'Open File in Tracker'}{link?.project_name &&
											<span> - {link.project_name}</span>}</Fragment>, href: link
									}
								))
							]
							:
							[
								{
									title: trackerLinksLoading ? <span><LoadingSpinner/> Loading</span> : "No links",
									href: ''
								}
							],
					}}
				onClick={(link) => {
					changeListener(link)
				}}
				collapsed={collapsed}
				noOpen
			/>
			}
		</Fragment>
	)
}