import React, {useState, useEffect, useRef, useContext} from 'react'
import { useRouter } from "next/router";

export default function NavItem(props){
	const { info, collapsed, onClick, noOpen, addParams } = props
	const id = Math.random()
	const [ hoverRef, isHovered ] = useHover();
	const [ style, setStyle ] = useState({position:'fixed',top:150,left:185,zIndex:9999})
	const router = useRouter()

	const linkOpen = !noOpen && info?.links?.filter(link=>router.asPath.split(link.href)?.length > 1)
	const open = (info?.links?.length > 0 && linkOpen.length > 0) || router.pathname === (info?.href)

	function openLink(e,href){
		// console.log('open link',e,href)
		e.preventDefault()
		const query = addParams ? router.query : {}
		onClick ? onClick(href) : info?.newWindow ? window.open(href, '_blank') : router.push({pathname:href,query})
	}

	useEffect(()=>{
		const navItem = document.getElementById(`${id}`)?.getBoundingClientRect()
		navItem?.top && setStyle({...style,top:navItem.top-1,left:navItem.width-15})
	},[isHovered])

	// console.log('nav item',(href+'/') === router.asPath, href, router)
	if(!props.info){
		return false
	}

	return(
		<div className={'list-nav-item '+(open ? 'open':'')} ref={hoverRef} id={id}>
			<a
				className={'list-nav-header '+((info?.href+'/') === router.asPath ? 'nav-link-selected':'')}
				onClick={(e)=>{openLink(e,info?.href)}}
			>
				<span className={'fas '+info?.icon}/><span className=" hide-side-nav-collapse"><span className={'ml-2'}>{info?.title}</span></span>
			</a>
			{info?.links?.length > 0 &&
				<div
					className={open ? collapsed ? 'dropdown-menu portal-nav-menu':'':'dropdown-menu portal-nav-menu'}
					style={open?collapsed?style:{}:style}
				>
					{/*{collapsed &&*/}
					{/*<a*/}
					{/*	className={'dropdown-item'}*/}
					{/*	onClick={(e) => {*/}
					{/*		openLink(e,info?.href)*/}
					{/*	}}*/}
					{/*>*/}
					{/*	<span className={'hide-side-nav-collapse'}>*/}
					{/*		{info?.title}*/}
					{/*	</span>*/}
					{/*</a>*/}
					{/*}*/}
					{/*{collapsed && info?.links?.length > 0 && <div className={'dropdown-divider'}/>}*/}
					{info?.links?.length > 0 && info?.links?.map((link,i) => (
						link && <a
							key={i}
							className={
								collapsed ?
									'dropdown-item'
									:
									open ?
										'sub-list-nav-item ' + ((linkOpen.length > 0 && linkOpen[0].href === link.href) ? 'nav-link-selected':'')
										:
										'dropdown-item'
							}
							onClick={(e) => {
								openLink(e,link.href)
							}}
						>
							{link.title}
						</a>
					))}
				</div>
			}
		</div>
	)
}

function useHover() {
	const [value, setValue] = useState(false);

	const ref = useRef(null);

	const handleMouseOver = () => setValue(true);
	const handleMouseOut = () => setValue(false);

	useEffect(
		() => {
			const node = ref.current;
			if (node) {
				node.addEventListener('mouseover', handleMouseOver);
				node.addEventListener('mouseout', handleMouseOut);

				return () => {
					node.removeEventListener('mouseover', handleMouseOver);
					node.removeEventListener('mouseout', handleMouseOut);
				};
			}
		},
		[ref.current] // Recall only if ref changes
	);

	return [ref, value];
}