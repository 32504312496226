import React, {useContext, useState, useEffect} from 'react'
import {createPortal} from 'react-dom';
import MessageList from "./MessageList";
import Messages from "./Messages";
import {MessageContext} from "../Context/GlobalState";

export default function Modal(props) {
	const { selected, findSelected } = useContext(MessageContext)
	const [ mounted, setMounted ] = useState(false)
	const selectedMessages = findSelected(selected)

	useEffect(() => {
		setMounted(true)
		return () => {
			setMounted(false)
		};
	}, []);



	return mounted ?
		createPortal(
			<div className="modal fade" id="messageAppModal">
				<div className="modal-dialog modal-xl" style={{height:'95%'}}>
					<div className="modal-content" style={{height:'100%'}}>
						<div className="modal-header">
							<h5 className="modal-title">Messages {selectedMessages?.display && <>from {selectedMessages.display}</>}</h5>
							<button type="button" className="close" data-dismiss="modal">
								<span>&times;</span>
							</button>
						</div>
						<div className="modal-body p-0">
							<div className={'row no-gutters'} style={{height:'100%'}}>
								<div className={'col-md-3 col-sm-1'}>
									<MessageList/>
								</div>
								<div className={'col-md-9 col-sm-11 p-2'}>
									<Messages/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>,
			document.body
		)
		:
		null
}