import axios from 'axios'
import Cookies from 'js-cookie'
axios.defaults.baseURL = process.env.API_HOST
axios.defaults.headers['Content-Type'] = 'application/json'

axios.interceptors.request.use(function (config){
	const employee = Cookies.getJSON('employee')
	// console.log('config',config,employee)
	if(config.url.startsWith(`/ncs/employee/1/maintenance/email/`)){
		// console.log('starts with emp email',config.url)
		config.headers['X-Api-Key'] = process.env.PORTAL_API_KEY
	}
	else if(employee?.api_key){
		// console.log('has api key',employee.api_key)
		config.headers['X-Api-Key'] = employee.api_key
	}
	else{
		console.log('no api key',employee)
		// location.reload()
	}
	return config
})

// Add a response interceptor
axios.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function(error) {
	if(axios.isCancel(error)){
		// console.log('cancelled')
		return {cancelled:true}
	}
	else if (error.response) {
		// Request was made but server responded with something
		// other than 2xx
		console.error('Status:', error.response.status);
		console.error('Data:', error.response.data);
		console.error('Headers:', error.response.headers);
	} else {
		// Something else happened while setting up the request
		// triggered the error
		console.error('Error Message:', error.message);
	}
	console.error('Request Failed:', error.config);

	return Promise.reject(error);
});

export default axios