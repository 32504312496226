import React, {useContext} from 'react'
import LeftNav from "../../../utils/LeftNav";
import {Context} from "../Context/GlobalState";

export default function RequestNav(props) {
    const {
        collapsed,
        toggleCollapse,
		getFiles,
		setSearch,
		search
    } = useContext(Context)

    const links = [
        {
            title:'Request',
            href:'/nml/tracker/maintenance/',
            icon:'fa-cogs',
            
        },
       
    ]


    return  <LeftNav
        collapsed={collapsed}
        collapse={toggleCollapse}
		// search={search}
		// submit={submit}
        noSearch

        icon={'csg'}
        title={'Request'}
        back={'/nml/tracker/dashboard'}
        backBtnTitle={'Go to Tracker'}
    >
	</LeftNav>
}