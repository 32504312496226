import React, {useState, useEffect, useContext} from 'react'
import DataList from "../../utils/DataList";
import {DocuwareContext} from "../Context/GlobalState";

export default function DocTypeSearch(props) {
	const { docTypes,getDocumentTypes,search,setSearch } = useContext(DocuwareContext)

	useEffect(()=>{
		docTypes?.length === 0 && getDocumentTypes()
	},[docTypes])

	return (
		<div className="input-group" role="group">
			<DataList
				options = {docTypes}
				list = "document-types"
				placeHolder = "Search Types"
				value = { search }
				onChange = {({target}) => {setSearch(target.value)}}
			/>
			<span className="input-group-append">
				<button
					onClick={()=>{setSearch('')}}
					type="button"
					className="btn btn-outline-default">
						<span className="far fa-times" />
				</button>
			</span>
		</div>
	)
}