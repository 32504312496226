import _ from 'lodash';
import {
	LINK_FILES_MODAL,
	LINK_FILES,
	CHANGE_GROUP_NAME,
	LINK_FILES_SEARCH,
	CLEAR_LINK_FILES,
	LINK_FILE_SEARCH_LOADING,
	PUSH_UCC_NO,
	ADD_LINK_GROUP,
	UPDATE_SEARCH_TEXT,
	REMOVE_LINK
} from './action';

export default function LinkFilesReducer(
    state = {show:false,isLoading:true,searchLoading:false,info:{},search:[],search_text:''},
    action
) {
	switch (action.type) {
		case LINK_FILES_MODAL:
			return {...state,show:action.open};
		case LINK_FILES:
			return {...state,info:action.info};
		case CHANGE_GROUP_NAME:
			state.info.group_name = action.value;
			return {...state};
		case LINK_FILES_SEARCH:
			return {...state,search:action.search};
		case CLEAR_LINK_FILES:
			return {...state,search:{},info:{}};
		case LINK_FILE_SEARCH_LOADING:
			return {...state,searchLoading:action.set};
		case PUSH_UCC_NO:
			state.info.ucc_nos.push(action.ucc_no);
			return {...state,search:{},search_text:''};
		case ADD_LINK_GROUP:
			const new_ucc_nos = action.group.ucc_nos.concat(state.info.ucc_nos);
			let info = action.group;
			info.ucc_nos = new_ucc_nos;
			return {...state,info,search:{},search_text:''};
		case UPDATE_SEARCH_TEXT:
			return {...state,search_text:action.ucc_no};
		case REMOVE_LINK:
			_.remove(state.info.ucc_nos,function(ucc_no){
				if(ucc_no == action.ucc_no){
					return ucc_no;
				}
			});
			return {...state};
		default:
			return state;
	}
}