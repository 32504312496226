/*
    Email Form Template
    Author: Michael Spangler

    Props: (* = required)
        - from  = 'address@gmail.com'
        - to    = 'address@gmail.com'  OR  ['one@gmail.com', 'two@gmail.com']
        - cc    = ^^
        - contacts = [ {
            emailAddress:  'address@gmail.com',     *
            title:          'name, title, etc'
        }, {...} ]
        - attachments = [ {
            name:       'fileName.txt",             *
            type:       'txt",
            location:   'local', 'docuware', 'server' *
            fileContent: JS File object,            * if location is local
            tmpPath:    'tmp/path/"
        }, {...} ]
*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import axios from '../../axios';
import DocPreviewer from "../../docuware/components/DocPreviewer";
import DocThumbnail from "../../docuware/components/DocThumbnail";
import { PortalContext } from "../../GlobalState";
import { addAttachmentToEmail, buildDraftEmail, sendEmail } from "../../GraphService";
import Alert from "../Alert";
import FileUploadBtn from '../FileUploadBtn';
import formatDate from "../formatDate";
import makeKeysLowerCase from "../makeKeysLowerCase";
import TypeTags from '../TypeTags';
import EmailMergeFieldDropdown from "./EmailMergeFieldDropdown";
import EmailSignature from "./EmailSignature";
const CancelToken = axios.CancelToken;
let cancel;

/**
 * @param {string} from 
 * @param {string} attachments 
 *  [{
 *      name: "fileName.txt", 
 *      type: "txt", 
 *      fileContent: JS File object
 * }] 
 * @param {string} cc
 */
export default class EmailForm extends Component {
    static propTypes = {
        from: PropTypes.string
    }
	static contextType = PortalContext

    constructor(props) {
        super(props);

        const {
        	from,
			fromList,
			to,
			cc,
			onBehalfOf,
			attachments,
			subject,
			messageBody,
			cabinet
        } = this.props;

        this.subject = React.createRef()
		this.messageBody = React.createRef()
		this.docuwareBtn = React.createRef()
		this.clearError = this.clearError.bind(this)

        this.state = {
            // Send from different account than the one that is signed in (on behalf of OR send as)
            from:           from ? from : false,
			fromAddress:    from ? from : '',
            // If a "From" address is included, sends using "Send As" unless specified as "On Behalf Of"
            onBehalfOf:     from ? !!onBehalfOf : null,
            // What is currently being typed in the send/cc text boxes (not yet submitted to the send lists)
            sendTo:         "",
            ccTo:           "",
            // Lists of email addresses to send/cc to
            sendList:       to ? (Array.isArray(to) ? to : [to]) : [],
            ccList:         cc ? (Array.isArray(cc) ? cc : [cc]) : [],
            // List of attachments which could be from Docuware, server TMP folder, or a local file
            attachments: attachments ? attachments : [],
            // Subject and message body for the email
            subject:        subject ? subject : "",
            messageBody:    messageBody ? messageBody : "",
            // Statuses and error flags
            buildingDraft:  false,
            draftBuilt:     false,
            draftError:     false,
            emailSent:      false,
			emailError:     false,
			emailErrorMessage: '',
			emailErrorAttachmentName:'',
            sendListError:  false,
            ccListError:    false,
			isLoading: 		false,
			templates:		[],
			messageBodyCursor: 0,
			subjectCursor: 0,
			mergeFieldDropdown:[],
			mergeFieldLoading:true,
            docuwareList:[],
            docuwareThumbs:[],
			docuwareListLoading:true,
			showImage:false,
			docId:null,
			cabinet:cabinet?cabinet:'ucc',
			shareLinkLoading:false,
			sendAnyway:false,
			includeSignature:true,
			signatureName:'',
			signatureTitle:'',
			signatureExt: '',
			associateMergeFields:[]
        }
    }

    componentDidMount(){
		this.setState({isLoading: true,docuwareListLoading: true})
		axios.get(`/email/template/1/${this.props.cabinet}/json`)
			.then(({data}) => {
				this.setState({
					isLoading: false,
					templates: data
				})
			})
		this.getDropdown()
		let docUrl;
		if(this.props?.cabinet?.toLowerCase() === 'ucc'){
			docUrl = `ucc_no/${this.props.file.ucc_no}`
		}
		else{
			const { DIRECT_NO, direct_no } = this.props.file
			docUrl = `direct_no/${DIRECT_NO ?? direct_no}`
		}

		axios.get(`/docuware/3/${this.props.cabinet}/filter_private/${docUrl}/json?base64_thumbs=1`)
			.then(({data}) => (
				this.setState({
					docuwareList: data.documents,
					docuwareThumbs: data.documents,
					docuwareListLoading: false
				})
			))

		if(this.context?.employee){
			const {employee} = this.context
			this.setState({
				signatureName:employee?.FirstName+' '+employee?.LastName,
				signatureTitle:employee?.title,
				signatureExt:employee?.ext
			})
		}
	}

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.attachments) !== JSON.stringify(this.props.attachments)) {
            this.setState({
                attachments: this.props.attachments,
            })
        }

        if(JSON.stringify(prevProps.to) !== JSON.stringify(this.props.to)){
        	this.setState({
				to:this.props.to
			})
		}
		if(JSON.stringify(prevProps.cc) !== JSON.stringify(this.props.cc)){
			this.setState({
				ccList:this.props.cc
			})
		}
		if(JSON.stringify(prevProps.contacts) !== JSON.stringify(this.props.contacts)){
			this.setState({
				contacts:this.props.contacts
			})
		}
    }

    // Returns true if string passed is a valid email address
    validateEmail = (email) => {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
    }

    // Generic onChange handler (element's name field must match the this.state field)
    onChange = e => this.setState({ [e.target.name]: e.target.value });


    handleFileAdd = (files) => {
        var attachments = [];
        for(var i = 0; i < files.length; i++) {
            attachments.push({
                fileContent: files[i],
                name: files[i].name,
                type: files[i].type,
                location: "local"
            })
        }

        this.setState({
            attachments: [...this.state.attachments, ...attachments]
        })
    }

    async createEmail(send = false) {
    	const { sendAnyway } = this.state
        this.setState({
            buildingDraft: 1
        })

		const signature = this.state.includeSignature ? "<br/><br/>"+document.getElementById('docuwareEmailSignature').innerHTML : ''

		// Creates email draft
		const builtDraft = await buildDraftEmail(
			{
			    from: this.state.fromAddress,
			    onBehalfOf: this.state.onBehalfOf,
			    toRecipients: this.state.sendList,
			    ccRecipients: this.state.ccList,
			    subject:this.state.subject,
			    body:this.state.messageBody+signature
			},
			this.context.auth.authProvider
		)
		this.setState({
			draftId: builtDraft.id,
			draftUrl: builtDraft.webLink
		})


		// Add each attachment
		var attachPromises = [];
		await this.state.attachments.map((attachment, index) => {
			// console.log('attachments',attachment)
			if (attachment.location == 'docuware' || attachment.location == 'dw' || attachment.fileCabinetId) {
				attachPromises.push(addAttachmentToEmail({
					id: this.state.draftId,
					name: attachment.name,
					location: attachment.location,
					cabinet: attachment.fileCabinetId,
					dwId: attachment.Id
				},this.context.auth.authProvider).then((data) => {
					var attachments = this.state.attachments;
					attachments[index].draftStatus = true;

					this.setState({
						attachments: attachments
					})
				})
					.catch((error)=>{
						console.log('docuware file',error)
						this.setState({
							emailError:true,
							emailErrorMessage:error.response.data.response,
							emailErrorAttachmentName:attachment.name,
							draftBuilt: true,
							buildingDraft: false
						})
						send = sendAnyway?sendAnyway:false
					}))
			} else if (attachment.location == 'docuware-3') {
				attachPromises.push(addAttachmentToEmail(
					{
						id: this.state.draftId,
						name: attachment.name,
						location: attachment.location,
						cabinet: attachment.cabinet,
						dwId: attachment.dwId
					},this.context.auth.authProvider).then((data) => {
					var attachments = this.state.attachments;
					attachments[index].draftStatus = true;

					this.setState({
						attachments: attachments
					})
				}).catch((error) => {
					console.log('docuware 3', error)
					this.setState({
						emailError: true,
						emailErrorMessage: error.response.data.response,
						emailErrorAttachmentName: attachment.name,
						draftBuilt: true,
						buildingDraft: false
					})
					send = sendAnyway ? sendAnyway : false
				}))
			}
			else if (!attachment.location) {
				attachPromises.push(addAttachmentToEmail(
					{
						id: this.state.draftId,
						name: attachment.document_type,
						location: 'docuware-3',
						cabinet: this.props.cabinet,
						dwId: attachment.id
					},
					this.context.auth.authProvider
				).then((data) => {
					var attachments = this.state.attachments;
					attachments[index].draftStatus = true;

					this.setState({
						attachments: attachments
					})
				}).catch((error) => {
					console.log('no location', error)
					this.setState({
						emailError: true,
						emailErrorMessage: error.code + error?.message,
						emailErrorAttachmentName: attachment.name,
						draftBuilt: true,
						buildingDraft: false
					})
					send = sendAnyway ? sendAnyway : false
				}))
			}
			else if(attachment.location == 'server') {
				// Attach File from Server
				attachPromises.push(addAttachmentToEmail(
				{
					id: this.state.draftId,
					name: attachment.name,
					tmpPath: attachment.tmpPath,
					location: attachment.location
				},
					this.context.auth.authProvider
				).catch((error)=>{
					console.log('file on the server',error)
					this.setState({
						emailError:true,
						emailErrorMessage:error.response.data.response,
						emailErrorAttachmentName:attachment.name,
						draftBuilt: true,
						buildingDraft: false
					})
					send = sendAnyway?sendAnyway:false
				}))
			}
			else if(attachment.location == 'local' || attachment.fileContent) {
				// Attach local file

				attachPromises.push(addAttachmentToEmail(
					{
						id: this.state.draftId,
						name: attachment.name,
						file: attachment.fileContent,
						location: attachment.location
					},
					this.context.auth.authProvider
				).catch((error) => {
					console.log('file on the server', error)
					this.setState({
						emailError: true,
						emailErrorMessage: error.response.data.response,
						emailErrorAttachmentName: attachment.name,
						draftBuilt: true,
						buildingDraft: false
					})
					send = sendAnyway ? sendAnyway : false
				}))

			}
		})
		//
		// Waiting for all attachment promises to resolve
		Promise.all(attachPromises).then(() => {
			// If specified to send, send email draft
			if(send) {
				sendEmail(this.state.draftId,this.context.auth.authProvider)
					.then((response) => {
						if(response?.data?.error){
							this.setState({
								emailError:true,
								emailErrorMessage:data.response,
								draftBuilt: true,
								buildingDraft: false
							})
						}
						else {
							this.setState({
								emailSent: true,
								draftBuilt: true,
								buildingDraft: false,
								emailError:true,
								emailErrorMessage:''
							})
							this.props.sendBtn();
						}
					})
					.catch((error)=>{
						// console.log('email error',error)
						this.setState({
							emailError:true,
							emailErrorMessage:error.code+' '+error.message,
							draftBuilt: true,
							buildingDraft: false
						})
					});
			}
			else{
				this.setState({
					draftBuilt: true,
					buildingDraft: false
				})
			}
		});
    }

    removeAttachment = (i) => {
        let tempArray = this.state.attachments;
        tempArray.splice(i, 1);

        this.setState({
            attachments: tempArray
        })
    }

    // Saves draft
    openInOffice() {
        this.createEmail();
    }

    // Renders the contact list if one is passed as props
    rendercontacts(textBoxId) {
        let listArray = [];
        if(typeof this.props.contacts != undefined) {
            this.props.contacts.forEach((value, i)=>{
                if(value.emailAddress != "" && !this.state.sendList.includes(value.emailAddress) &&  !this.state.ccList.includes(value.emailAddress) ) {
                    listArray.push({
                        value: value.emailAddress, alt: value.title
                    });
                }
            })
        }

        return(listArray);
    }

    // Renders the attachment list

	//TODO get service info when selecting docuware / add single person templates
    renderAttachments() {
        let renderArray = [];
        this.state.attachments.map((attachment, index) => {
            renderArray.push(
				<span style={{padding: "8px", margin: "4px", transition: '.5s'}} key={index} className="badge badge-default" >
					<span
						style={{marginRight: "10px", color: "red"}}
						className="far fa-file"
					/>
					{ attachment.url ?
						<a href={attachment.url} target="_blank" rel="noreferrer" className={attachment.file_size>3200000?'text-danger':''}>
							{attachment.name ? attachment.name : attachment.document_type}
						</a> :
						<span className={attachment.file_size>3200000?'text-danger':''}>
							{attachment.name ? attachment.name : attachment.document_type}
						</span>
					}
					<span
						style={{marginLeft: "7px"}}
						className="far fa-times pointer"
						onClick={() => {this.removeAttachment(index)}}
					/>
				</span>
            );
        });

        return renderArray;
    }

    mergeFields = (name,string) => {
		let index,startIndex = 0
		let endTag = 0
		if(string.indexOf('<<',startIndex) > -1) {
			while ((index = string.indexOf('<<', startIndex)) > -1) {
				startIndex = index + 2
				endTag = string.indexOf('>>', startIndex)
				const tag = string.slice(index + 2, endTag)
				const sourceIndex = tag.indexOf('_')
				const source = tag.slice(0, sourceIndex).toLowerCase()
				const field = tag.slice(sourceIndex + 1).toLowerCase().split('.')
				// console.log('source',source,'field',field)
				let propsSource = null
				if(source === 'associate'){
					const mergeType = field[1]
					// console.log('checking format',mergeType)
					if(mergeType === 'format') {
						let assocType = field[0]
						switch (assocType){
							case '1':
								assocType = [1,2,15,22]
								break;
							case '3':
								assocType = [3,20,14]
								break;
							case '4':
								assocType = [4,21]
								break;
							case '8':
								assocType = [8,9,10]
								break;
							default:
								assocType = [assocType]
						}
						const formatType = field[2]
						const associateMergeField = this.state?.associateMergeFields?.find(a => a.format == formatType) ?? {}
						// console.log('merge field',associateMergeField)
						let associateMergeString = ''
						if (associateMergeField?.value?.length > 0) {
							assocType.map((type, assocTypeI) => {
								const combine = associateMergeField?.combine
								const associates = this.props?.file?.associates?.filter(r => r?.type_id == type)
								// console.log('associates',associates)
								associates.map((thisAssoc, i) => {
									let assocString = associateMergeField.value
									let assocIndex, assocStartIndex = 0
									let assocEndTag = 0
									// console.log('this assoc', thisAssoc)
									// console.log('type map', type, assocString)
									while ((assocIndex = assocString.indexOf('<<', assocIndex)) > -1) {
										assocStartIndex = assocIndex + 2
										// console.log('first two characters', assocString[assocStartIndex], assocString[assocStartIndex+1])
										assocEndTag = assocString.indexOf('>>', assocStartIndex)
										// console.log('index',assocIndex,'start',assocStartIndex,'end',assocEndTag)
										const assocTag = assocString.slice(assocIndex + 2, assocEndTag)
										const assocField = assocString.slice(assocIndex + 2, assocEndTag).toLowerCase()
										// console.log('tag','<<' + assocTag + '>>','field', assocField, 'value', thisAssoc[assocField])
										assocString = assocString.replace('<<' + assocTag + '>>', thisAssoc[assocField] ?? '')
										if (thisAssoc[assocField] && thisAssoc[assocField]?.length > 0) {
											assocString = assocString.replace('  ', ' ')
										}
									}
									if (associateMergeString?.length === 0) {
										// console.log('if',combine,i)
										associateMergeString += assocString + (combine === 1 && associates?.length > 1 ? ' and/or ' : '\r\n\r\n')
									} else {
										// console.log('else',combine,i,assocTypeI,associates?.length,assocString)
										// console.log('before',combine === 1 && assocTypeI > 0 && assocTypeI === associates?.length-1,'after',combine === 1 && i > 0 && i < associates?.length,(combine === 1 && i > 0 ? '':'\r\n') + assocString + (combine === 1 && i > 0 && i < associates?.length ? ' and/or ':'\r\n'))
										associateMergeString += assocString + (combine === 1 && i > 0 && i < associates?.length ? ' and/or ' : '\r\n\r\n')
									}
								})
							})
						}

						if (associateMergeString?.length > 0) {
							string = string.replace('<<' + tag + '>>', associateMergeString)
							this.setState({[name]: string})
						}
					}
					else{
						propsSource = this.props?.file?.associates?.find(r=>r?.type_id == field[0] && r.type_main)
					}
				}
				else if (source === 'contact') {
					if (this.state.sendList.length > 0) {
						propsSource = this.props.contacts.filter(contact => (contact.CNTEMAIL === this.state.sendList[0]))[0]
					}
					else {
						propsSource = this.props.contacts[0]
					}
				}
				else {
					propsSource = this.props[source]
				}
				// console.log('props source',propsSource)

				if (propsSource) {
					propsSource = makeKeysLowerCase(propsSource)
					let sourceField = ''
					if(propsSource){
						let i = source === 'associate' ? 1 : 0
						for (i; i < field?.length; i++) {
							propsSource = propsSource[field[i]];
						}
						sourceField = propsSource
					}
					string = string.replace('<<' + tag + '>>', sourceField)
					this.setState({[name]: string})
				}
			}
		}
		else{
			this.setState({[name]:string})
		}

		// this[name].current.focus()
	}

	inputChange = (name,string) => {
    	// console.log('input change',name,string)
    	this.cursorPosition(name)
		this.setState({[name]:string},()=>{this.mergeFields(name,string)})
	}

	addMergeField = (name,mergeField) => {
		const cursorPosition = this.state[name+'Cursor']
		const firstSlice = this.state[name].slice(0,cursorPosition)
		const lastSlice = this.state[name].slice(cursorPosition)
		const merged = firstSlice+mergeField+lastSlice
		this.setState({
			[name]:merged
		},()=>{this.mergeFields(name,merged)})
		this[name].current.focus()
	}

	cursorPosition = (name) => {
		this.setState({
			[name+'Cursor']:this[name].current.selectionStart
		})
	}

	getDropdown(){
    	this.setState({mergeFieldLoading:true})
		axios.get(`/email/template/2/${this.props.cabinet}/dropdowns/json`)
			.then(({data}) => {
				this.setState({
					mergeFieldDropdown: data?.dropdowns ?? [],
					mergeFieldLoading: false,
					associateMergeFields: data?.associate_merge_fields ?? []
				})
			})
	}

	viewImage(docId){
    	this.setState({
			showImage:true,
			docId
		})
	}

	closeImage = () => {
    	this.setState({
			showImage:false
		})

		this.docuwareBtn.current.click()
    }

    attachDoc = (doc) => {
        let attachment = {
            location: 'docuware-3',
            cabinet: doc.cabinet,
            thumbnail: doc.thumbnail + '?api_key=',
            url: doc.viewer,
            name: doc.document_type,
            size: doc.file_size,
            dwId: doc.id
        }

        let newAttachments = this.state.attachments.concat(attachment);
        this.setState({attachments: newAttachments});
    }

    getShareLink(name){
    	this.setState({shareLinkLoading:true})
    	axios.post(`/docuware/3/share/json`,this.state.attachments)
			.then(({data})=>{
				this.setState({shareLinkLoading:false,attachments:[]})
				this.addMergeField(name,data)
			})
	}

	clearError(){
    	this.setState({
			emailError:false,
			emailErrorMessage:'',
			emailErrorAttachmentName:''
		})
	}

	templateDropdown(templates,title) {
		return templates?.length > 0 &&
		<React.Fragment>
			<div className={'dropdown-header'}>{title}</div>
			{templates.map(template => (
				<a
					key={template.id}
					className={'dropdown-item pointer'}
					onClick={() => {
						this.setState({cursorPosition: template.body.length});
						template.subject?.length > 0 && this.mergeFields('subject',template.subject);
						this.mergeFields('messageBody', template.body)
						this.messageBody.current.focus()
					}}
				>
					{template.title}
				</a>
			))}
		</React.Fragment>
	}

	changeFromAddress(fromAddress){
		this.setState({fromAddress})
		if(this.state.includeSignature && this.validateEmail(fromAddress) && fromAddress.indexOf('.com') > -1 ){
			if(cancel){
				cancel()
			}
			axios.get(`/ncs/employee/1/maintenance/email/${fromAddress}/json`,{cancelToken: new CancelToken(function executor(c) {
					// An executor function receives a cancel function as a parameter
					cancel = c;
				})})
				.then(({data}) => {
					if(data) {
						// console.log('data: ', data);
						const user = data?.[0]
						const first = user?.FirstName ?? null
						const last = user?.LastName ?? null
						this.setState({
							signatureName: first && last ? first +' '+last : null,
							signatureTitle: user.title,
							signatureExt: user.ext
						})
					}
				})
		}
		else {
			if(this.context?.employee){
				const {employee} = this.context
				this.setState({
					signatureName:employee?.FirstName+' '+employee?.LastName,
					signatureTitle:employee?.title,
					signatureExt:employee?.ext
				})
			}
		}
	}

    render() {
		const { contacts, cancelBtn, modal, closeBtn } = this.props;
        const { from, sendList, ccList, subject, messageBody, draftBuilt, buildingDraft, draftUrl, docId, showImage } = this.state;

        // console.log('templates',this.props.file,this.props.service)
		// console.log('email form',this.state.sendList)

        return(
			<React.Fragment>
				{this.state.emailError &&
					<Alert onClick={this.clearError}>
						<div className={'row'}>
							<div className={'col-12'}>
								{this.state.emailErrorAttachmentName?.length > 0 ? this.state.emailErrorAttachmentName+' - ' : ''} {this.state.emailErrorMessage}
							</div>
							<div className={'col-12'}>

								{this.state.emailErrorAttachmentName?.length > 0 && <button
									className={'btn btn-outline-secondary'}
									onClick={()=>{
										this.getShareLink('messageBody')
										this.clearError()
									}}
								>
									<span className={'far fa-plus'}/> Add Share Link
								</button>}
								{/*<button*/}
								{/*	className={'btn btn-outline-secondary ml-2'}*/}
								{/*	onClick={()=>{*/}
								{/*		this.setState({sendAnyway:true});*/}
								{/*		this.createEmail(1)*/}
								{/*		this.clearError()*/}
								{/*	}}*/}
								{/*>*/}
								{/*	<span className={'fab fa-telegram-plane'}/> Send Anyway*/}
								{/*</button>*/}
							</div>
						</div>
					</Alert>
				}
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12">
                            <table>
                                <tbody>
                                {from?.length > 0 &&
                                    <HeaderItem label="From">
										<div onClick={() => {document.getElementById('fromAddress').focus();}} style={{width: "100%", cursor: "text"}}>
											<input
												className="type-tags-input form-control"
												type="text"
												style={{border:"0px", boxShadow: "inset 0px 0px 0px 0px white", width: "250px", display: 'inline-block', paddingLeft: "0px"}}
												id={'fromAddress'}
												placeholder="From Address"
												value={this.state.fromAddress}
												onChange={({target}) => this.changeFromAddress(target.value)}
												list={"fromAddress-list"}
											/>
											<datalist id={"fromAddress-list"}>
												{this.props.fromList?.map((item,i)=>
													<option key={i} value={item.Email}>
														{item.FirstName} {item.LastName}
													</option>
												)}
												{this.state.cabinet === 'nml' && <option value={"attorneyrequest@ncscredit.com"}>
													NCS Credit Attorney Request
												</option>}
											</datalist>
										</div>
                                   </HeaderItem>
                                }
                                <HeaderItem label="To">
                                    <TypeTags
										id="sendTo"
										validate={this.validateEmail}
										tagList={sendList}
										optionList={contacts}
										handleList={(list)=>{
											this.setState({sendList: list})}
										}
									/>
                                </HeaderItem>

                                <HeaderItem label="CC">
                                    <TypeTags id="cc" validate={this.validateEmail} tagList={ccList} optionList={contacts} handleList={(list)=>{this.setState({ccList: list})}}/>
                                </HeaderItem>
								<tr className="email-header">
									<td>
										<span>Subject</span>
									</td>
									<td>
										<div className="input-group" style={{width:"100%"}}>
											<input
												className="form-control unstyled-input"
												ref={this.subject}
												type="text"
												value={subject}
												onKeyDown={()=>{this.cursorPosition('subject')}}
												onClick={()=>{this.cursorPosition('subject')}}
												name="subject"
												onChange={({target})=>{this.inputChange(target.name,target.value)}}
											/>
											{this.state.cabinet !== 'cli' && <div className={'input-group-append'}>
												{this.state.mergeFieldDropdown.length > 0 &&
												<EmailMergeFieldDropdown
													select={(mergeField) => {
														this.addMergeField('subject', mergeField)
													}}
													dropdown={this.state.mergeFieldDropdown}
													loading={this.state.mergeFieldLoading}
												/>
												}
											</div>}
										</div>
									</td>
								</tr>
                                <HeaderItem label={<span className="far fa-paperclip"/>}>
									<div className={'row'}>
										<div className={'col'}>
											{ this.renderAttachments() }
										</div>
									</div>
                                    <div className={'btn-toolbar mb-2 mr-1'}>
										<div className={'dropdown'}>
											<button ref={this.docuwareBtn} disabled={this.state.docuwareListLoading} className={'btn btn-outline-secondary mr-1'} data-toggle="dropdown">
												{this.state.docuwareListLoading ?
													<span className={'far fa-spinner fa-spin'}/>
													:
													<span className={'far fa-plus'}/>
												}
												Docuware
											</button>
											{this.state.docuwareList &&
												<ul className={'dropdown-menu'} style={{maxHeight: 600, overflow: 'scroll'}}>
													{this.state.docuwareList.map((document, key) => (
														<li key={key} className={'pointer'}>
															<a>
																<table>
																	<tbody>
																	<tr>
																		<td onClick={() => {
																			this.viewImage(document.id)
																		}}>
																			{this.state.docuwareThumbs[key] &&
																			<DocThumbnail
																				url={`data:image/png;base64, ${this.state.docuwareThumbs[key].thumbnail}`}/>
																			}
																		</td>
																		<td onClick={() => {
																			this.attachDoc(document)
																		}} style={{verticalAlign: 'middle', paddingLeft: 16}}>
																			<div
																				style={{fontWeight: "bold"}}>{document.document_type}</div>
																			<div
																				style={{color: "#8a8a8a", fontStyle: "italic"}}>
																				{formatDate(document.create_date)}
																			</div>
																		</td>
																	</tr>
																	</tbody>
																</table>
															</a>
														</li>
													))}
												</ul>
											}
										</div>
										{/*<div className={'col'}>*/}
											<FileUploadBtn
												onChange={(e)=>{this.handleFileAdd(e.target.files)}}
												btnStyle={' btn-outline-secondary mr-1'}
											>
												<span className="far fa-plus"/>
											</FileUploadBtn>
										{/*</div>*/}
										{this.state.attachments?.length > 0 &&
											<button className={'btn btn-outline-secondary'} onClick={()=>{this.setState({attachments:[]})}}>
												<span className={'far fa-trash-alt'}/> Clear Attachments
											</button>
										}
									</div>
                                </HeaderItem>
                                </tbody>
                            </table><br/>
                        </div>
                    </div>
					<div className={'row d-flex align-items-center mb-3'}>
						<div className={'col-4'}>
							<div className="form-check">
								<input
									className="form-check-input"
									type="checkbox"
									checked={this.state.includeSignature}
									onChange={()=>{this.setState({includeSignature:!this.state.includeSignature})}}
									id="includeSignature"
								/>
								<label className="form-check-label" htmlFor="includeSignature">
									Include Signature?
								</label>
							</div>
						</div>
						<div className={'col-8'}>
							{this.state.cabinet !== 'cli' && <div className={'dropdown float-right ml-1'}>
								<button
									className={'btn btn-outline-secondary'}
									data-toggle={'dropdown'}
									disabled={this.state.isLoading}
								>
									{this.state.isLoading && <span className={'far fa-spin fa-spinner'}/>} Template <span className={'fas fa-caret-down'}/>
								</button>
								<ul className={'dropdown-menu dropdown-menu-right'}>
									{this.templateDropdown(this.state.templates?.general,'General')}
									{this.templateDropdown(this.state.templates?.personal,'Personal')}
								</ul>
							</div>}
							{this.state.cabinet !== 'cli' && <div className={'float-right ml-1'}>
								{this.state.mergeFieldDropdown.length > 0 &&
								<EmailMergeFieldDropdown
									select={(mergeField) => {
										this.addMergeField('messageBody', mergeField)
									}}
									dropdown={this.state.mergeFieldDropdown}
									loading={this.state.mergeFieldLoading}
								/>
								}
							</div>}
							{this.state.attachments?.length > 0 &&
							<div className={'float-right'}>
								<button
									className={'btn btn-outline-secondary'}
									onClick={() => {
										this.getShareLink('messageBody')
									}}
									disabled={this.state.shareLinkLoading}
								>
									<span
										className={'far fa-' + (this.state.shareLinkLoading ? 'spinner fa-spin' : 'plus')}/> Add
									Share Link
								</button>
							</div>
							}
						</div>
					</div>
					<textarea
						ref={this.messageBody}
						style={{
							border: "0px solid white",
							boxShadow: "none",
							resize: "none",
							borderRadius: "10px",
							backgroundColor: "#f7f7f7",
							height: "300px"
						}}
						placeholder="Body"
						className="form-control"
						rows="5"
						id="comment"
						value={messageBody}
						onKeyDown={()=>{this.cursorPosition('messageBody')}}
						onClick={()=>{this.cursorPosition('messageBody')}}
						name={'messageBody'}
						onChange={({target})=>{this.inputChange(target.name,target.value)}}
						onPaste={(e)=>{
							this.handleFileAdd(e.clipboardData.files)
						}}
					/>
					{this.state.includeSignature &&
						<EmailSignature
							name={this.state.signatureName}
							title={this.state.signatureTitle}
							ext={this.state.signatureExt}
						/>
					}
                    {/*<TemplateTextArea handleChange={(content)=>{this.setState({messageBody: content})}} />*/}
                </Modal.Body>

				<div className={modal?'modal-footer':'docuware-footer float-right'} style={{padding:'1rem'}}>
					<button
						className="btn btn-info"
						onClick={()=>{this.createEmail(1)}}
					>
						<span className="fab fa-telegram-plane"/> Send
					</button>
					{ !draftBuilt && !buildingDraft &&
					<button className="btn btn-default" onClick={() => {this.createEmail(false)}}>
						{/* <span className="fab fa-windows"/> Open in 365 */}
						<span className="fal fa-save"/> Save Draft
					</button>
					}
					{ buildingDraft &&
					<button className="btn btn-default" disabled>
						<span className="far fa-spinner fa-pulse fa-fw"/> Building Draft
					</button>
					}
					{ draftUrl && draftBuilt &&
					<a href={draftUrl} target="_blank" rel="noreferrer" style={{float: "left"}} className="btn btn-default">
						<span className="fal fa-external-link"/> Open Draft
					</a>
					}
					{this.props.cancelBtn && <button
						className={'btn btn-default'}
						onClick={cancelBtn}
					>
						<span className={'fas fa-caret-left'}/> Back
					</button>}
					{modal &&
					<button
						className={'btn btn-default'}
						onClick={closeBtn}
					>
						<span className={'far fa-times'}/> Close
					</button>
					}
				</div>
                {/*<Modal.Footer>*/}
                    {/*<button className="btn btn-default" onClick={() => {close()}}>*/}
                        {/*<span className="far fa-times"/> Cancel*/}
                    {/*</button>*/}
				{/*</Modal.Footer>*/}
				<DocPreviewer cabinet={this.state.cabinet} docId={docId} show={showImage} onClose={this.closeImage} />
			</React.Fragment>
		)
    }
}

const HeaderItem = ({label, children}) => (
    <tr className="email-header">
        <td>
            <span>{label}</span>
        </td>
        <td>
            {children}
        </td>
    </tr>
)