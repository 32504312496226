import '../styles/portal.scss'
import PortalHead from "../components/PortalHead";
import Header from "../components/Header"
import GlobalState from '../components/GlobalState'
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react'
import {config} from "../components/GlobalState";
import AppLayout from "../components/AppLayout";
import {ErrorBoundary} from "../components/Bugsnag";
import {
	useQuery,
	useMutation,
	useQueryClient,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
function MyApp(props) {
	// const redirectUri = 'https://portal.loc.ncscredit.com'
	const msalInstance = new PublicClientApplication({
		auth:{
			clientId:config.appId,
			redirectUri:process.env.DOMAIN
		},
		cache:{
			cacheLocation:'localStorage',
			storeAuthStateInCookie: false
		}
	})

	// Check if there are already accounts in the browser session
	// If so, set the first account as the active account
	const accounts = msalInstance.getAllAccounts();
	// console.log('getting accounts',accounts)
	if (accounts?.length > 0) {
		msalInstance.setActiveAccount(accounts[0]);
	}

	msalInstance.addEventCallback((event) => {
		if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
			// Set the active account - this simplifies token acquisition
			const authResult = event.payload;
			msalInstance.setActiveAccount(authResult.account);
		}
	});
	const queryClient = new QueryClient()


	return <ErrorBoundary>
		<MsalProvider instance={msalInstance}>
			<QueryClientProvider client={queryClient}>
				<GlobalState>
					<PortalHead/>
					<Header/>
					<AppLayout {...props}/>
				</GlobalState>
			</QueryClientProvider>
		</MsalProvider>
	</ErrorBoundary>
}

export default MyApp
