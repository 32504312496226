import React, { useEffect, useState } from 'react'
import Cookies from "js-cookie";
import axios from 'axios'
import {io} from 'socket.io-client'
export const AUTH_USER = 'authUser'
export const EMPLOYEE = 'employee'
axios.defaults.baseURL = process.env.API_HOST
axios.defaults.headers['Content-Type'] = 'application/json'
export const pickerOptions = {
	format: "MM/DD/YYYY",
	showClear: true,
	showTodayButton: true,
	icons: {
		previous: 'far fa-chevron-left',
		next: 'far fa-chevron-right',
		today: "far fa-calendar",
		clear: 'far fa-trash',
		up: "far fa-arrow-up",
		down: "far fa-arrow-down"
	}
}
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType,InteractionStatus } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { getUser } from './GraphService';

export const config = {
	appId: process.env.AUTH_CLIENT_ID,
	scopes: [
		"openid",
		"offline_access",
		"profile",
		"User.ReadBasic.All",
		"mail.read",
		"mail.readwrite",
		"mail.send",
		"Calendars.ReadWrite"
	]
};

export const PortalContext = React.createContext({});

let checking = false

export default function GlobalState(props) {
	const auth = useProvideAppContext()
    const employee = Cookies.getJSON(EMPLOYEE) ?? {admin:0}
	const [ socket, setSocket ] = useState(undefined)
	const [ socketLoading, setSocketLoading ] = useState(false)

	useEffect(()=>{
		// console.log('employee use effect',employee)
		let tab_id = Math.random()
		if(typeof window !== 'undefined'){
			tab_id = Cookies.getJSON('tab_id') ?? tab_id
		}
		async function socketConnection(){
			setSocketLoading(true)
			const connection = await io(process.env.SOCKET_URL, {
				// path: window.environment == 'LOCAL' ? '/LOCAL/socket/socket.io/' : undefined,
				reconnection:true,
				reconnectionAttempts:5,
				reconnectionDelay: 3000,
				query: {
					api_key:employee?.api_key,
					cnt_id:employee?.cnt_id,
					tab_id
				},
				forceNew:false
			});
			// console.log('new connection',employee)
			setSocket(connection)
			setSocketLoading(false)
		}
		// console.log('global state',employee?.api_key && !socket,employee?.api_key,socket.connected)
		// employee?.api_key && !socket && !socketLoading && console.log('socket connection',employee?.api_key,socket,socketLoading)
		employee?.api_key && !socket && !socketLoading && socketConnection()
	}, [employee]);

    const admin = employee?.admin ? parseInt(employee.admin) : 0

    return <PortalContext.Provider
        value={{
            employee,
            admin,
			isAdmin:admin && admin <= 10,
			socket,
            pickerOptions,
			auth
        }}
    >
        {props.children}
    </PortalContext.Provider>
}

function useProvideAppContext() {
	const msal = useMsal();
	const {instance,inProgress} = msal
	const isAuthenticated = useIsAuthenticated()
	const [user, setUser] = useState(undefined);
	const [error, setError] = useState(undefined);
	const [loading,setLoading] = useState(false)
	const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
		msal.instance,
		{
			account: msal.instance.getActiveAccount(),
			scopes: config.scopes,
			interactionType: InteractionType.Popup
		}
	);

	useEffect(() => {
		// console.log('use effect authProvider')
		const checkUser = async() => {
			if (!user && !checking) {
				// console.log('no user',user)
				checking = true
				try {
					// Check if user is already signed in
					const account = msal.instance.getActiveAccount();
					// console.log('account',account)
					if (account) {
						// Get the user from Microsoft Graph
						const user = await getUser(authProvider);
						// console.log('got account',account)
						// console.log('user',user)

						setUser({
							displayName: user.displayName || '',
							email: user.mail || user.userPrincipalName || '',
							timeFormat: user.mailboxSettings?.timeFormat || '',
							timeZone: user.mailboxSettings?.timeZone || 'UTC'
						});
					}
					checking = false
				} catch (err) {
					displayError(err.message);
					checking = false
				}
			}
		};
		checkUser();
	},[authProvider]);

	const displayError = (message, debug) => {
		setError({message, debug});
	}

	const clearError = () => {
		setError(undefined);
	}

	async function getEmployee(email,real_email= ''){
		// console.log('getting emp',email,real_email)
		let employee = undefined
		setLoading(true)
		await axios.get(`/ncs/employee/1/maintenance/email/${email}/json`,{headers:{'X-Api-Key':process.env.PORTAL_API_KEY}})
			.then(({data})=>{
				// console.log('getting employee',data[0])
				let emp = {...data[0]}
				emp.emp_id = emp.EmpID
				if(real_email?.length > 0) {
					emp.real_email = real_email
				}
				// console.log('emp after',emp)
				Cookies.set('employee',emp, { expires: 1 })
				employee = emp
				setLoading(false)
			})

		return employee
	}

	const signIn = async () => {
		// console.log('sign in',isAuthenticated,inProgress,InteractionStatus)
		if(!isAuthenticated && inProgress === InteractionStatus.None) {
			await msal.instance.loginRedirect({
				scopes: config.scopes,
				prompt: 'select_account'
			});
			//
			// const user = await getUser(authProvider);
			// console.log('user sign in',user)
			// const emp = await getEmployee(user.userPrincipalName)
			// // console.log('emp',emp)
			//
			// setUser({
			// 	displayName: user.displayName || '',
			// 	email: user.mail || user.userPrincipalName || '',
			// 	timeFormat: user.mailboxSettings?.timeFormat || '',
			// 	timeZone: user.mailboxSettings?.timeZone || 'UTC'
			// });
			// location.reload()
		}
	};

	const signOut = async () => {
		await msal.instance.logoutRedirect();
		setUser(undefined);
		Cookies.set(EMPLOYEE,undefined)
	};

	return {
		user,
		error,
		signIn,
		signOut,
		displayError,
		clearError,
		authProvider,
		getEmployee,
		loading
	};
}