import _ from 'lodash';
import {
	SKIP_AHEAD_MODAL,
	SKIP_AHEAD_INFO,
	EDIT_SKIP_AHEAD,
	RETURN_SKIP_AHEAD,
	CHANGE_SKIP_FOLLOW_UP,
	CHANGE_SKIP_AHEAD_EMP,
	SKIP_AHEAD_LOADING,
	CLOSE_MODAL,
	CLEAR_SKIP_AHEAD
} from './action';

export default function SkipAheadReducer(state = {show:false,info:{},edit:0,isLoading:true}, action) {
	switch (action.type) {
		case SKIP_AHEAD_MODAL:
			return {...state,show:action.open,edit:0};
		case SKIP_AHEAD_INFO:
			return {...state,info:action.info};
		case EDIT_SKIP_AHEAD:
			return {...state,edit:1};
		case RETURN_SKIP_AHEAD:
			return {...state,edit:0};
		case CHANGE_SKIP_FOLLOW_UP:
			let new_skip_follow_up = state.info;
			new_skip_follow_up.follow_up = action.follow_up;
			return {...state,info:new_skip_follow_up};
		case CHANGE_SKIP_AHEAD_EMP:
			let new_skip_emp = state.info;
			new_skip_emp.emp_id = _.toInteger(action.e.target.value);
			new_skip_emp.emp_name = action.e.target[action.e.target.selectedIndex].text;
			return {...state,info:new_skip_emp};
		case SKIP_AHEAD_LOADING:
			return {...state,isLoading:action.set};
		// case CLOSE_MODAL:
		// 	return {...state,info:{}};
		case CLEAR_SKIP_AHEAD:
			return {...state,info:{}};
		default:
			return state;
	}
}