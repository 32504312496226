import React, {Fragment, useState, useEffect, useContext, useRef} from 'react'
import formatDate from "../../../../utils/formatDate";
import {Context} from "../../Context/GlobalState";
import ClearNotifications from "./ClearNotifications";
import dayjs from "dayjs";
import filter from "lodash/filter";
import NoteTagList from "./NoteTagList";
import {PortalContext} from "../../../../GlobalState";

export default function NoteDisplay({note, dynamic_tags}) {
    const { dropdowns, deleteNote, patchNote } = useContext(Context)
	const {employee} = useContext(PortalContext)
	const { note_tags } = dropdowns
	const [ editNote, setEditNote ] = useState(note?.text)
	const [ edit, setEdit ] = useState(false)
	const [ editTags, setEditTags ] = useState(false)
	const [ deleting, setDelete ] = useState(false)
	const [ deleteLoading, setDeleteLoading ] = useState(false)
	const [ editLoading, setEditLoading ] = useState(false)
	const [ tagsSelected, setTagsSelected ] = useState(Object.keys(note?.tags) ?? [])
	const [ hovering, setHovering ] = useState(false)
	const editNoteRef = useRef(null)

	useEffect(()=>{
		setEditNote(note?.text)
		setTagsSelected(Object.keys(note?.tags) ?? [])
	},[note])

	function changeTagSelected(tag){
		if(tagsSelected.includes(tag)){
			setTagsSelected(filter(tagsSelected,(r)=>(r!==tag)))
		}
		else{
			setTagsSelected([...tagsSelected,tag])
		}
	}

	function saveNote(){
    	setEditLoading(true)
		patchNote(note.id,editNote,tagsSelected,()=>{
			cancelTags()
			setEdit(false)
			setEditTags(false)
			setEditLoading(false)
		})
	}

	function cancelTags(){
    	setTagsSelected(Object.keys(note.tags))
	}

    const assignedEmp = note?.assigned_emp_id > 0 ? dropdowns.employees?.find(emp => emp.EmpID === note.assigned_emp_id) : false
	const canEdit = dayjs().diff(dayjs(note?.created),'hours') <= 24
	const tagsDifferent = JSON.stringify(Object.keys(note.tags)) !== JSON.stringify(tagsSelected)

    return <li
		className={'list-group-item'}
		onMouseEnter={()=>{setHovering(true)}}
		onMouseLeave={()=>{setHovering(false)}}
	>
        <div className={'row'}>
            <div className={'col-9'}>
				{assignedEmp && <h5><span className={note.assigned_emp_id === parseInt(employee.EmpID) ?'text-info':''}>{assignedEmp.FirstName} {assignedEmp.LastName}</span></h5>}
				<div className={'row'}>
					<div className={'col-12 mb-1'}>
						{hovering && !editTags && !canEdit &&
							<span className={'badge badge-pill badge-secondary mr-1 pointer'} onClick={()=>{setEditTags(true)}}>
								<span className={'far fa-tags'}/> Edit
							</span>
						}
						{note_tags?.length > 0 && (edit || editTags) &&
							<span className={'dropdown mr-1'}>
								<span className={'h5'} data-toggle={'dropdown'}>
									<span className={'badge badge-pill badge-secondary pointer'}>
										<span className={'far fa-plus'}/> Tags
									</span>
								</span>
								<NoteTagList
									dynamic_tags={dynamic_tags}
									note_tags={note_tags}
									selected={tagsSelected}
									select={(tag) => {
										changeTagSelected(tag.tag)
									}}
								/>
							</span>
						}
						{tagsSelected?.map((tag, i) => {
							if(tag === 'need_confirmed'){
								return <ClearNotifications key={i} note={note}/>
							}
							const dynamic = dynamic_tags ? dynamic_tags?.find(dTag=>dTag.value===tag)?.label ?? undefined : undefined
							return <span className={edit || editTags ? 'h5' : ''} key={i}>
								<span
									className={'badge badge-pill mr-1 badge-secondary'}
								>
									{dynamic ?? tag.replace('_', " ")}
									{(edit || editTags) &&
									<span
										className={'far fa-times ml-1 pointer'}
										onClick={() => {
											changeTagSelected(tag)
										}}
									/>
									}
								</span>
							</span>
						})}
					</div>
				</div>
            </div>
            <div className={'col-3'}>
                <span className={'float-right'}>{formatDate(note.created,'MM/DD/YYYY h:mm A')}</span>
            </div>
        </div>
        <div className={'row'}>
            <div className={'col-9'}>
				{deleting ?
					<h5><strong>Are you sure you would like to delete this note?</strong></h5>
					:
					edit?
						<textarea
							ref={editNoteRef}
							style={{
								border: "0px solid white",
								boxShadow: "none",
								resize: "none",
								borderRadius: "10px",
								backgroundColor: "#f7f7f7",
							}}
							placeholder="New Note"
							className="form-control"
							rows="3"
							value={editNote}
							onChange={({target})=>{
								setEditNote(target.value)
							}}
							onFocus={()=>{setEditNote(editNote)}}
						/>
						:
						<p>{note.text}</p>
				}
            </div>
			<div className={'col-3'}>
				<div className={'row'}>
				{canEdit ?
					<div className={'col-12'}>
						<div className={'float-right'}>
							{deleting ?
								<Fragment>
									<button
										className={'btn btn-outline-danger btn-sm mr-1'}
										onClick={() => {
											setDeleteLoading(true)
											deleteNote(note.id,()=>{
												cancelTags()
												setDelete(false)
												setDeleteLoading(false)
											})
										}}
									>
										<span className={'far fa-'+(deleteLoading?'spinner fa-spin':'check')}/> Delete
									</button>
									<button
										className={'btn btn-outline-secondary btn-sm'}
										onClick={() => {
											setDelete(false)
										}}
									>
										<span className={'far fa-times'}/> Cancel
									</button>
								</Fragment>
								:
								edit ?
									<Fragment>
										{(tagsDifferent || note.text !== editNote) &&
											<button
												className={'btn btn-outline-primary btn-sm mr-1'}
												onClick={saveNote}
											>
												<span className={'far fa-'+(editLoading?'spinner fa-spin':'check')}/> Save
											</button>
										}
										<button
											className={'btn btn-outline-secondary btn-sm'}
											onClick={() => {
												setEdit(false)
												cancelTags()
												setEditNote(note?.text)
											}}
										>
											<span className={'far fa-times'}/> Cancel
										</button>
									</Fragment>
									:
									<Fragment>
										<button
											className={'btn btn-outline-secondary btn-sm mr-1'}
											onClick={() => {
												setEdit(true)
												setTimeout(()=>{editNoteRef?.current?.focus()},500)
											}}
										>
											<span className={'far fa-edit'}/> Edit
										</button>
										<button
											className={'btn btn-outline-danger btn-sm'}
											onClick={() => {
												setDelete(true)
											}}
										>
											<span className={'far fa-trash-alt'}/> Delete
										</button>
									</Fragment>
							}
						</div>
					</div>
					:
					editTags && <div className={'col-12'}>
						<div className={'float-right'}>
							{tagsDifferent &&
								<button
									className={'btn btn-outline-primary btn-sm mr-1'}
									onClick={saveNote}
								>
									<span className={'far fa-'+(editLoading?'spinner fa-spin':'check')}/> Save
								</button>
							}
							<button
								className={'btn btn-outline-secondary btn-sm'}
								onClick={()=>{
									cancelTags()
									setEditTags(false)
								}}
							>
								<span className={'far fa-times'}/> Cancel
							</button>
						</div>
					</div>
				}
					<div className={'col-12 mt-2'}><span className={'float-right'}>{note.name}</span></div>
				</div>
			</div>
        </div>
    </li>
}