import React,{ useState, useContext, Fragment } from 'react'
import formatDate from "../../../utils/formatDate";
import LabelAndDisplay from "../Utils/LabelAndDisplay";
import {Context} from "../Context/GlobalState";
import currencyFormat from "../../../utils/currencyFormatter";
import Docuware from "../../../docuware";
import EmailModal from "../../../utils/email_form/EmailModal";
import {PortalContext} from "../../../GlobalState";
import NoteModal from "../Utils/NoteModal";

export default function RightNav(props){
	const { fileInfo, dropdowns, docuwareContacts } = useContext(Context)
	const {client,debtor} = fileInfo || {}
	const [ showDocModal, setShowDocModal ] = useState(false)
	const {admin} = useContext(PortalContext)

	// console.log('right nav',fileInfo)

	const projectFields = [
		{label:'Name',display:'name'},
		(admin > 0 && admin < 5) && {label:'Direct No',display:'direct_no'},
		{label:'Type',display:'type'},
		{label:'State',display:'state'},
		{label:'County',display:'county'},
		{label:'APN',display:'apn'},
		{label:'Ref No',display:'reference_no'},
		{label:'First Furn',display:'first_furnishing',date:true},
		{label:'Last Furn',display:'last_furnishing',date:true},
		{label:'Material Description',display:'material_description'},
		{label:'Contract',display:'contract',currency:true},
		{label:'Balance',display:'balance',currency:true},
		{label:'Address',display:'address1'},
		{label:'Address 2',display:'address2'},
		{label:'City',display:'City'},
		{label:'Zip',display:'zip'},
	]

	const address = [
		{label:'Address',display:'address1'},
		{label:'Address 2',display:'address2'},
		{label:'City',display:'City'},
		{label:'State',display:'state'},
		{label:'Zip',display:'zip'},
	]

	const contactFields = [
		{label:'Name',display:'name'},
		{label:'Contact',display:'contact'},
		{label:'Affiant',display:'affiant'},
	]

	const debtorFields = [
		{label:'Type',display:'type'},
		{label:'Name',display:'name'},
		{label:'Address',display:'address1'},
		{label:'Address 2',display:'address2'},
		{label:'City',display:'City'},
		{label:'State',display:'state'},
		{label:'Zip',display:'zip'},
	]
	const addToList = dropdowns?.employees?.map(emp=>({emailAddress:emp.Email,title:emp.FirstName+' '+emp.LastName}))

	return(
		<div className={'side-nav right-nav'}>
			<div className="right-nav-body">
				<div className={'row'}>
					<div className={'col-12'}>
						<EmailModal
							cabinet="nml"
							file={fileInfo}
							direct_no={fileInfo?.direct_no}
							btnStyle={'btn btn-outline-secondary w-100 mb-2'}
							fromList={dropdowns?.employees}
							from
							addToList={addToList}
							contacts={docuwareContacts}
						/>
					</div>
					<div className={'col-12'}>
						<Docuware
							modal
							cabinet="nml"
							file={fileInfo}
							direct_no={fileInfo?.direct_no}
							btnStyle={'btn btn-outline-secondary w-100 mb-2'}
							fromList={dropdowns?.employees}
							from
							addToList={addToList}
							contacts={docuwareContacts}
						/>
					</div>
					<div className={'col-12'}>
						<NoteModal
							dynamic_tags={fileInfo?.dynamic_tags}
							notes={fileInfo?.notes}
							ncs_lno={fileInfo?.ncs_lno}
							btnStyle={'btn btn-outline-secondary w-100 mb-2'}
						/>
					</div>
				</div>
				{client && <Fragment>
					<h5>Client</h5>
					<Display fields={contactFields} info={client}/>
				</Fragment>}

				{fileInfo?.name && <Fragment>
					<h5>Project</h5>
					<Display fields={projectFields} info={fileInfo}/>
					{/*<AddressDisplay fields={address} info={fileInfo}/>*/}
				</Fragment>}

				{debtor && <Fragment>
					<h5>Debtor</h5>
					<Display fields={debtorFields} info={debtor}/>
					{/*<AddressDisplay fields={address} info={debtor}/>*/}
				</Fragment>}
			</div>
		</div>
	)
}

function AddressDisplay({fields,info}){
	const [ show, setShow ] = useState(false)

	return(
		<Fragment>
			{show && <Display fields={fields} info={info}/>}
			<button
				className={'btn btn-outline-secondary btn-block btn-sm mt-2'}
				onClick={()=>{setShow(!show)}}
			>
				<span className={'far fa-chevron-'+(show?'up':'down')}/> {show?'Hide':'Show'} Address
			</button>
		</Fragment>
	)
}

function Display({fields = [],info}) {
	return (
		fields.map(({label,display,date,currency},i)=>(
			info[display] &&
			<LabelAndDisplay
				key={i}
				label={label}
				display={date?formatDate(info[display]):currency?currencyFormat(info[display]):info[display]}
			/>
		))
	)
}