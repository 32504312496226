import { useState, useEffect, createContext } from "react";
import axios from '../../../axios'
export const Context = createContext({});
const ROOT_URL = "/doc/transaction/1";

function GlobalState(props) {
  const [isLoading, setLoading] = useState(false);
  const [clientSummary, setClientSummary] = useState([]);
  const [clientHistory, setClientHistory] = useState([]);

  useEffect(() => {
    getClientSummary();
  }, []);

  function getClientSummary() {
    setLoading(true);
    axios.get(`${ROOT_URL}/client-summary/json`).then((response) => {
      const { data } = response;
      data?.length > 0 && setClientSummary(data);
      setLoading(false);
    });
  }

  function getClientHistory(cli_id) {
    setLoading(true);
    axios.get(`${ROOT_URL}/history/${cli_id}/json`).then((response) => {
      const { data } = response;
      if(data?.length > 0){
        setClientHistory(data?.map((r,i)=>{
          // console.log('setting client history',i,r);
          if(i===0){
            return {...r,meta:{...r.meta,DIV_ID:0}}
          }else {
            return {...r}
          }
        }))
      }
      setLoading(false);
    });
  }
  return (
    <Context.Provider
      value={{
        isLoading,
        clientSummary,
        getClientHistory,
        clientHistory,
      }}
    >
      {clientSummary?.length > 0 && props.children}
    </Context.Provider>
  );
}

export default GlobalState;
