import React, {useContext} from 'react'
import {MessageContext} from "../Context/GlobalState";
import MessageListItem from "./MessageListItem";

export default function MessageList(props) {
	const { messages, selected, setSelected, selectMessageGroup, hideMessageGroup } = useContext(MessageContext)

	return (
		<ul
			className={'list-group list-group-flush'}
			style={{borderRight:'solid 1px #dee2e6',height:'100%'}}
		>
			<li
				className={'list-group-item p-3 pointer message-list-item ' + (selected === false ? 'message-selected':'')}
				onClick={()=>{setSelected(false)}}
			>
				<span className={'far fa-plus'}/> New Message
			</li>
			{messages.map(message=> (
				<MessageListItem
					key={message.id}
					selected={selected === message.id}
					onClick={()=>{selectMessageGroup(message)}}
					hide={()=>{hideMessageGroup(message.id)}}
					message={message}
				/>
			))}
		</ul>
	)
}