import React, {useContext} from 'react'
import { MessageContext } from "../Context/GlobalState";
import LoadingSpinner from "../../utils/LoadingSpinner";

export default function NavItem(props) {
	const { unseen, isLoading } = useContext(MessageContext)

	return (
		<li className='nav-item dropdown' data-toggle="modal" data-target="#messageAppModal">
			<span className='nav-link pointer'>
				{isLoading ?
					<LoadingSpinner/>
					:
					<span className={'stack-icons'}>
									{unseen > 0 && <span className={'badge badge-pill badge-danger'}>{unseen}</span>}
						<span className={'far fa-envelope'}/>
								</span>
				}
				<span className={'ml-2'}>Messages</span>
			</span>
		</li>
	)
}