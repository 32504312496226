import React, {useState, useEffect, useContext} from 'react'
import axios, {CancelToken, ROOT_URL} from "./AxiosSetup";
import { useRouter } from "next/router";
import TrackerLinkModal from "../../../../components/nml/tracker/Utils/TrackerLinkModal";
import {PortalContext} from "../../../GlobalState";
import {Context} from "./GlobalState";
export const TrackerLinkContext = React.createContext({})
let cancel

function TrackerLinkState({children}){
	const {socket,employee} = useContext(PortalContext)
	const { selectedFile, selectFile } = useContext(Context)
	const [ trackerLinked, setTrackerLinked ] = useState(false)
	const [ trackerLinks, setLinks ] = useState([])
	const [ trackerLinksLoading, setTrackerLinksLoading ] = useState(false)
	const router = useRouter()
	const [ link_id,setLinkId ] = useState(router?.query?.link_id ?? false)
	const [ askedIfWantedToLink, setAskedIfWantedToLink ] = useState(false)

	useEffect(()=>{
		if(employee?.emp_id && !trackerLinksLoading && trackerLinks?.length === 0){
			setTrackerLinksLoading(true)
			axios.get(`${ROOT_URL}/tracker/links/${employee?.emp_id}/json`)
				.then(({data})=>{
					setTrackerLinksLoading(false)
					setLinks(data)
				})
		}
	},[employee])

	useEffect(()=>{
		// console.log('tracker link router change',link_id,router?.query)
		if(router?.query?.link_id){
			setLinkId(router.query.link_id)
		}
	},[router])

	// useEffect(()=>{
	// 	if(trackerLinked?.id){
	// 		document.title = trackerLinked.display+": Lien Tracker"
	// 	}
	// },[trackerLinked])

	useEffect(()=>{
		// console.log('links updating',trackerLinks)
		if(!trackerLinked?.id && trackerLinks?.length > 0){
			if(link_id){
				const findLink = trackerLinks.find(link=>link.id==link_id)
				if(findLink){
					changeListener(findLink)
				}
				else{
					const routerDirectNo = router?.query?.direct_no
					// console.log('router direct no',routerDirectNo,selectedFile?.direct_no)
					if(routerDirectNo != selectedFile?.direct_no){
						// console.log('going to file',routerDirectNo)
						goToFile({direct_no:routerDirectNo})
					}
				}
			}
			else if(!askedIfWantedToLink){
				$('#trackerLinkModal').modal('toggle')
				setAskedIfWantedToLink(true)
			}
		}
	},[trackerLinks])

	useEffect(()=>{
		socket?.on?.('updateTrackerLinks',(links)=>{
			// console.log('updating links',links)
			setLinks(links)
		})
	},[])

	const changeListener = (listener) => {
		// console.log('changeListener',trackerLinked,listener)
		const { id, direct_no, notice_review } = listener
		socket?.removeListener('trackerLink'+trackerLinked.id)
		setTrackerLinked(listener)
		direct_no && goToFile({direct_no, notice_review})
		id && socket?.on?.('trackerLink'+id,goToFile)
	}

	function goToFile({direct_no, notice_review = 0}){
		// console.log('going to file',direct_no,notice_review)
		// console.log('go to',goTo,page)
		const {pathname} = location
		const path = pathname.split('/nml/')[1]?.split('/')
		// console.log('path name',pathname,path)
		let page = pathname
		if(notice_review == 1){
			router.push(`/nml/tracker/file/associates/?direct_no=${direct_no}`)
		}
		else if(path?.[0] === 'tracker'){
			if(path?.[1] === 'dashboard'){
				page = '/nml/tracker/file/services'
			}
			router.push(`${page}?direct_no=${direct_no}`)
		}
	}

	// console.log('links',trackerLinks,link_id,trackerLinked)

	return(
		<TrackerLinkContext.Provider
			value={{
				trackerLinked,
				trackerLinks,
				trackerLinksLoading,
				changeListener
			}}
		>
			{children}
			{/*<TrackerLinkModal/>*/}
		</TrackerLinkContext.Provider>
	)
}

export default TrackerLinkState