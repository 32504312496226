import React,{ useState, useContext, useEffect } from 'react'
import LeftNav from '../../../utils/LeftNav'
import NavItem from "../../../utils/NavItem";
import {Context} from "../Context/GlobalState";
import TrackerLink from "../Utils/TrackerLink";
import {nldUrl} from "../Utils/OpenNLD";
import {useRouter} from "next/router";

export default function Nav(props){
	const { collapsed, toggleCollapse, setSearch, fileInfo, search, getFiles } = useContext(Context)
	const [ backToDashboard, setBackToDashboard ] = useState(`/nml/tracker/dashboard`)
	const router = useRouter()

	const submit = (search) => {
		setSearch(search)
		getFiles(search)
		router.push('/nml/tracker/dashboard')
	}

	const direct_no = router.query?.['direct-no']
	const url = '/nml/tracker/file'
	const links = [
		{
			title:'Project',
			href:`${url}/services`,
			icon:'fa-folder-open',
			links:[
				{
					title:'Services',
					href:`${url}/services`
				},
			
				{
					title:'Associates',
					href:`${url}/associates`
				},
				{
					title:'Dates',
					href:`${url}/dates`
				},
				{
					title:'Counties',
					href:`${url}/counties`
				},
				(fileInfo?.extra?.length > 0 && {
					title:'Extra',
					href:`${url}/extra`
				})
			]
		},
		{
			title:'History',
			href:`${url}/history/requests`,
			icon:'fa-history',
			links:[
				{
					title:'Mail',
					href: `${url}/history/mail`
				},
				{
					title:'Requests',
					href: `${url}/history/requests`
				},
			]
		},
		{
			title:'Research',
			href:`${url}/research/dashboard`,
			icon:'fa-binoculars',
			links:[
				// {
				// 	title:'Dashboard',
				// 	href: `${url}/research/dashboard`
				// },
				{
					title:'Dashboard',
					href: `${url}/research/dashboard`
				},
				{
					title:'Property Detail Reports',
					href: `${url}/research/pdr`
				}
			]
		},
		{
			title:'Documents',
			href:`${url}/documents/notices`,
			icon:'fa-file-signature',
			links:[
				{
					title:'Notices',
					href: `${url}/documents/notices`
				},
				{
					title:'Waivers',
					href: `${url}/documents/waivers`
				}
			]
		},
		fileInfo?.type_id && fileInfo?.state && {
			title:'Open in NLD',
			href:nldUrl(fileInfo?.type_id,fileInfo?.state),
			icon:'fa-ncs-hex',
			links:[],
			newWindow:true,
		},
		{
			title:'Open in OLS',
			href:`${process.env.OLS_HOST}/onlineservices/construction/account_management/?direct_no=${direct_no}`,
			icon:'fa-ncs-hex',
			links:[],
			newWindow:true,
		},
		// {
		// 	title:'Notes',
		// 	href:`/nml/tracker/file/${direct_no}/state_maintenance`,
		// 	icon:'fa-cogs'
		// },
	]

	// console.log('file nav',collapsed)

	return(
		<LeftNav
			collapsed={collapsed}
			collapse={toggleCollapse}
			search={search}
			submit={submit}
			icon={'csg'}
			title={fileInfo?.ncs_lno ?? 'D#: '+direct_no}
			back={backToDashboard}
		>
			<TrackerLink/>
			{links.map((link,i)=>(
				<NavItem
					key={i}
					info={link}
					collapsed={collapsed}
					addParams
				/>
			))}
		</LeftNav>
	)
}