import { Client, GraphRequestOptions, PageCollection, PageIterator } from '@microsoft/microsoft-graph-client';
import axios from './axios'

let graphClient = undefined;

function ensureClient(authProvider) {
	if (!graphClient) {
		graphClient = Client.initWithMiddleware({
			authProvider: authProvider
		});
	}

	return graphClient;
}

export async function getUser(authProvider){
	ensureClient(authProvider);

	// Return the /me API endpoint result as a User object
	const user = await graphClient.api('/me')
		// Only retrieve the specific fields needed
		.select('displayName,userPrincipalName')
		.get();

	return user;
}

export async function buildDraftEmail(message,authProvider){
	ensureClient(authProvider);
// const {employee} = useContext(PortalContext)
	let fromAddress =  message.from
	let onBehalfOf =  message.onBehalfOf
	let sendList =  message.toRecipients
	let ccList =  message.ccRecipients
	let subject =  message.subject
	let body =  `<html>${message?.body?.replace(/(?:\r\n|\r|\n)/g, "<br>") ?? message.body}</html>`
	// $attachments =  $_POST['attachments'];

	// Format from address
	let from = undefined
	let sender = undefined
	if(fromAddress) {
		from = {
			'EmailAddress': {
				'Address': fromAddress
			}
		}

		if(onBehalfOf && authProvider?.options?.account?.username) {
			sender =  {
				'EmailAddress': {
					'Address': authProvider?.options?.account?.username
				}
			}
		}
	}

	// Format to-recipients
 	let toRecipients = [];
	sendList.map(address=>(toRecipients.push({EmailAddress:{address}})))

	// Format CC-recipients
	let ccRecipients = [];
	ccList.map(address=>(ccRecipients.push({EmailAddress:{address}})))

	// Format email object
	const emailBody = {
		"From": from,
		"Sender": sender,
		"ToRecipients": toRecipients,
		"CcRecipients":ccRecipients,
		"Subject": subject,
		"Body": {
			"ContentType" : "HTML",
			"Content" : body
		}
	};
	ensureClient(authProvider);

	// console.log('trying to build draft',emailBody)
	return await graphClient.api('/me/messages').post(emailBody)
}

export async function addAttachmentToEmail(info,authProvider) {
	// console.log('adding attachment',info)
	ensureClient(authProvider);

	let contentBytes = undefined
	let name = info.name+'.pdf'
	if(info?.location == 'docuware-3') {
		contentBytes = await axios.get(`/docuware/3/${info.cabinet}/download_v2/${info.dwId}`).then(({data})=>(data.file))
	}
	else if(info?.location == 'docuware' || info?.cabinet?.length > 0) {
		contentBytes = await axios.get(`/docuware/2/download/${info.cabinet}/${info.dwId}`).then(({data})=>(btoa(data)))
	}
	else if(info?.location == 'server') {
		contentBytes = await axios.post(`/portal/ucc/1/filldoc/filestring/json`,{tmpPath:info.tmpPath}).then(({data})=>(btoa(data)))
	}
	else if(info?.location == 'local') {
		name = info.name
		contentBytes = await fileToBase64(info.file)
		// console.log('local',contentBytes)
	}

	return await graphClient.api(`me/messages/${info.id}/attachments`).post({
		'@odata.type': '#microsoft.graph.fileAttachment',
		name,
		contentBytes
	})
}

function fileToBase64(file){
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.onload = function(event) {
			resolve(event.target.result.split(';base64,')[1]);
		};
		reader.readAsDataURL(file);
	})
};

export async function sendEmail(id,authProvider){
	ensureClient(authProvider);

	return await graphClient.api(`me/messages/${id}/send`).post()
}