import {
	ALERTS
} from './action';

export default function AlertsReducer(state = {alerts:[]}, action) {
	switch (action.type) {
		case ALERTS:
			return {...state,alerts:action.alerts}
		default:
			return state;
	}
}