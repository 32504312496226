import React, {useState, useEffect, useContext, Fragment, useRef} from 'react'
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import {Context} from "../../Context/GlobalState";
import NoteDisplay from "./NoteDisplay";
import AddNote from "./AddNote";
import TagsList from "./TagsList";
import NoteTagList from "./NoteTagList";
import {PortalContext} from "../../../../GlobalState";
import {Modal} from "react-bootstrap";

export default function Index(props) {
    const { dropdowns } = useContext(Context)
	const {employee} = useContext(PortalContext)
    const { note_tags } = dropdowns
    const [ notes, setNotes ] = useState([])
    const [ searchNotes, setSearchNotes ] = useState('')
    const [ newNote, setNewNote ] = useState(false)
    const [ tagsSelected, setTagsSelected ] = useState([])
    const searchRef = useRef(null)
    const newNoteRef = useRef(null)
    const id = 'noteModal'+Math.round(Math.random()*1000)
	const [ showModal, setShowModal ] = useState(false)

    useEffect(()=>{
        if(JSON.stringify(notes) !== JSON.stringify(props.notes)){
            setNotes(props.notes ?? [])
        }
		searchRef?.current?.focus()
    },[props.notes])
    useEffect(()=>{
        if(props?.tags?.length > 0 && (JSON.stringify(props.tags) !== JSON.stringify(tagsSelected))){
            setTagsSelected(props.tags)
        }
    },[props.tags])

    useEffect(()=>{
        if(newNote){
            newNoteRef?.current?.focus()
        }
    },[newNote])

    useEffect(()=>{
        if(tagsSelected?.length > 0 && props?.notes?.length > 0){
            setNotes(props.notes.filter((note)=>
                tagsSelected.find(tag=>
                    note?.tags[tag] > 0
                )
            ))
        }
        else{
            setNotes(props.notes)
        }
    },[tagsSelected])

    function changeTagSelected(tag){
        if(tagsSelected.includes(tag.tag)){
            setTagsSelected(filter(tagsSelected,(r)=>(r!==tag.tag)))
        }
        else{
            setTagsSelected([...tagsSelected,tag.tag])
        }
    }

    const needConfirmed = props?.notes?.filter(note=>(note.tags['need_confirmed']))

    return <div>
        <button
            className={props.btnStyle??'btn btn-default'}
            data-toggle="modal"
            data-target={"#"+id}
            onClick={()=>{
                setShowModal(true)
            }}
        >
            {props.children ?? <Fragment>
                {needConfirmed?.length > 0 ?
                    <span className={'stack-icons'}>
                        <span className={'badge badge-pill badge-danger mt-1'}>{needConfirmed.length}</span>
                        <span className={'far fa-sticky-note fa-lg'}/>
                    </span>
                    :
                    <span className={'far fa-sticky-note'}/>
                }
				{!props?.iconOnly && <span className={'ml-1'}>Notes</span>}
            </Fragment>}
        </button>
		<Modal
			show={showModal}
			onHide={()=>{
				setShowModal(false)
				setTagsSelected(props.tags??[])
				setSearchNotes('')
				setNewNote(false)
			}}
			size={'lg'}
			backdrop={true}
		>
			<Modal.Header closeButton>
				<h5 className="modal-title">*Beta* {props.notesTitle??(props.ncs_lno?'Notes for '+props.ncs_lno : 'Notes')}</h5>
			</Modal.Header>
			{newNote ?
				<AddNote tags={tagsSelected} dynamic_tags={props?.dynamic_tags} cancel={()=>{setNewNote(false)}}/>
				:
				<Fragment>
					<div className="modal-body">
						<div className={'row'}>
							<div className={'col-12 mb-3'}>
								<span className={'far fa-exclamation-triangle'}/> This is a beta version of notes.
								These notes may be deleted in the future <span
								className={'far fa-exclamation-triangle'}/>
							</div>
						</div>
						<div className={'row'} style={{marginBottom: '1.25rem'}}>
							<div className={'col-' + (newNote ? '12' : '6')}>
								<div className={'form-inline'}>
									<div className="input-group">
										<input
											ref={searchRef}
											type={'text'}
											className={'form-control'}
											placeholder={'Search notes'}
											value={searchNotes}
											onChange={({target}) => {
												setSearchNotes(target.value)
											}}
										/>
										{searchNotes?.length > 0 &&
										<div className="input-group-append">
											<button
												className="btn btn-outline-secondary"
												onClick={() => {
													setSearchNotes('')
												}}
											>
												<span className={'far fa-times'}/>
											</button>
										</div>
										}
									</div>
									{note_tags?.length > 0 &&
									<div className={'input-group'}>
										<div className={'dropdown ml-1'}>
											<button className={'btn btn-outline-secondary'}
													data-toggle={'dropdown'}>
												<span className={(tagsSelected?.length > 0 ? 'fas':'far')+' fa-' + (newNote ? 'plus' : 'filter')}/> Tags
											</button>
											<NoteTagList
												dynamic_tags={props?.dynamic_tags}
												note_tags={note_tags}
												selected={tagsSelected}
												select={(tag) => {
													changeTagSelected(tag)
												}}
											/>
										</div>
									</div>
									}
								</div>
							</div>
							<div className={'col-6 text-right'}>
								<button
									className={'btn btn-outline-secondary'}
									onClick={() => {
										console.log('clicking new note')
										setNewNote(true)
									}}
								>
									<span className={'far fa-plus'}/> Note
								</button>
							</div>
							{tagsSelected?.length > 0 && <TagsList tags={tagsSelected} setTagsSelected={setTagsSelected} dynamic_tags={props?.dynamic_tags}/>}
						</div>
						{notes?.length > 0 ?
								<ul className={'list-group list-group-flush'} style={{margin: '-1rem'}}>
									{orderBy(
										(searchNotes?.length > 0 ?
												filter(notes, (note) =>
													note.text.toLowerCase().includes(searchNotes.toLowerCase()) ||
													note.name.toLowerCase().includes(searchNotes.toLowerCase()) ||
													filter(Object.keys(note.tags), (tag) => tag.indexOf(searchNotes.toLowerCase()) > -1)?.length > 0
												)
												:
												notes
										),
										(row) => (row.tags['need_confirmed'] || row.tags['pinned'] || row.assigned_emp_id === parseInt(employee?.EmpID)), ['desc'])
										.map((note, i) => <NoteDisplay key={i} note={note} dynamic_tags={props?.dynamic_tags}/>)
									}
								</ul>
								:
								<h5>No Notes</h5>
						}
					</div>
					<div className="modal-footer">
						<button
							className="btn btn-default"
							onClick={()=>{setShowModal(false)}}
						>
							<span className={'far fa-times'}/> Close
						</button>
					</div>
				</Fragment>
			}
		</Modal>
	</div>
}