// Users can freely enter text or select from dropdown list provided

import React, { Component,Fragment } from 'react';

export default class DataList extends Component {
    render() {
        const {list, id, classNames, options, placeHolder, value, initialFilter, style, onChange} = this.props;

        return (
            <Fragment >
                <input 
                    type=           "text"
                    list =          { list }  
                    className =     { "form-control " + ( classNames    ? classNames : "" ) }
                    id =            { id            ? id : null }
                    placeholder =   { placeHolder   ? placeHolder : "" }
                    value =         { value ? value : initialFilter ? initialFilter : "" }
                    style =         { style         ? style : {} }
                    onChange =      { onChange      ? onChange : null }
                />
                <datalist id={list}>
                    { options.map((option, i) => {
                        return (
                            <option 
                                key = { i } 
                                value = { option.value }
                            >
                                { option.alt }
                            </option>
                        );
                    })}
                </datalist>
            </Fragment>
        )
    }
}