import React, { Component } from 'react'
import axios from '../../axios'
const ROOT_URL = '/portal/ucc/1';

export default class EmailMergeFieldDropdown extends Component{
	constructor(props){
		super(props)

		this.state = {
			dropdown:props.dropdown ? props.dropdown : [],
			isLoading:false
		}
	}
	// componentDidMount(){
	// 	if(this.state.dropdown.length === 0 && (!this.props.dropdown || this.props.dropdown.length === 0) && this.props.show){
	// 		console.log('getting dropdown')
	// 		this.getDropdown()
	// 	}
	// }
	componentDidUpdate(prevProps){
		const { dropdown } = this.props
		// console.log('component did update',this.props,prevProps)
		if(JSON.stringify(prevProps.dropdown) !== JSON.stringify(dropdown)){
			this.setState({dropdown})
		}
	}
	getDropdown(){
		this.setState({isLoading:true})
		axios.get(`${ROOT_URL}/email_template_dropdown/json`)
			.then(({data}) => {
				this.setState({
					dropdown:data,
					isLoading:false
				})
			})
	}

	render(){
		return(
			<div className={'dropdown dropdown-menu-right'} style={this.props.style && this.props.style}>
				<button
					className={'btn btn-outline-secondary'}
					data-toggle="dropdown"
					disabled={this.state.isLoading}
				>
					{this.state.isLoading || this.props.loading ? <span className={'far fa-spin fa-spinner'}/> : <span className={'far fa-plus'}/>}{!this.props.noText || this.props.noText === false ? ' Merge Field':''}
				</button>
				<div className={'dropdown-menu max-500'}>
					{this.state?.dropdown?.map((field,key)=>(
						<React.Fragment key={field.title}>
							{key !== 0 && <div className={'dropdown-divider'}/>}
							<h6 className={'dropdown-header'}>{field.title.charAt(0).toUpperCase()+field.title.slice(1)}</h6>
							{field.dropdown.map(dropdown=>(
								<a
									key={dropdown.name}
									className={'pointer dropdown-item'}
									onClick={()=>{
										this.props.select(dropdown.value)
									}}
									href={'#'}
								>
									{dropdown.name}
								</a>
							))}
						</React.Fragment>
					))}
				</div>
			</div>
		)
	}
}