import React, {useState, useEffect, useContext, Fragment} from 'react'
import {DocuwareContext} from "../Context/GlobalState";
import Footer from "./Footer";
import ServiceDropdown from "./ServiceDropdown";
import DocFilePdf from "./DocFilePdf";
import NotificationLetterDropdown from "./NotificationLetterDropdown";

export default function DocEdit(props) {
	const {
		checkedDocs,
		docTypes,
		cabinet,
		modal,
		changeCheckedDocs,
		updateCheckedDocs
	} = useContext(DocuwareContext)

	return (
		<Fragment>
			<div className={modal ? 'modal-body':'mt-3'}>
				<table className={'table table-hover'}>
					<thead>
					<tr>
						<th/>
						<th>Type</th>
						{cabinet !== 'cli' && <th>Service</th>}
						{(cabinet === 'nml' || cabinet === 'cli') &&
						<th>Note</th>
						}
						{cabinet === 'ucc' && <th>Notification Letter</th>}
						<th>View Online</th>
					</tr>
					</thead>
					<tbody>
					{checkedDocs.map(doc=>(
						<tr key={doc.id}>
							<td>
								<DocFilePdf id={doc.id} cabinet={cabinet}/>
							</td>
							<td>
								<select
									className={'form-control'}
									onChange={({target})=>{changeCheckedDocs(doc,'document_type',target.value)}}
									value={doc.document_type}
								>
									{docTypes.map(type=>(
										<option key={type.value} value={type.value}>{type.value}</option>
									))}
								</select>
							</td>
							{cabinet !== 'cli' &&
								<td>
									<ServiceDropdown
										value={doc.serv_id ?? ''}
										onChange={({target}) => {
											changeCheckedDocs(doc, 'serv_id', target.value)
										}}
									/>
								</td>
							}
							{cabinet === 'ucc' && <td>
								<NotificationLetterDropdown
									value={doc.nl_id ?? ''}
									onChange={({target}) => {
										changeCheckedDocs(doc, 'nl_id', target.value)
									}}
								/>
							</td>}
							{(cabinet === 'nml' || cabinet === 'cli') &&
							<td>
								{doc?.note?.length > 40 &&
									<span className={'text-warning ml-1'}>
										<span className={'far fa-exclamation-triangle mr-1'}/>
										Text is too long
										<span className={'far fa-exclamation-triangle ml-1'}/>
									</span>
								}
								<textarea
									className={'form-control'}
									value={doc.note ? doc.note : ''}
									rows={3}
									name={'note'}
									onChange={({target})=>{changeCheckedDocs(doc,target.name,target.value)}}
								/>
							</td>
							}
							<td>
								<div className={'form-check'}>
									<input
										type={'checkbox'}
										className={'form-check-input position-static pointer'}
										checked={parseInt(doc.view_online) === 1}
										onChange={()=>{changeCheckedDocs(doc,'view_online',parseInt(doc.view_online) === 1 ? 0 : 1)}}
									/>
								</div>
							</td>
						</tr>
					))}
					</tbody>
				</table>
			</div>
			<Footer>
				<button
					className={'btn btn-primary'}
					onClick={updateCheckedDocs}
				>
					<span className={'far fa-save'}/> Update
				</button>
			</Footer>
		</Fragment>
	)
}