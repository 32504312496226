import React, {useState, useEffect, Fragment, useRef} from 'react'

export default function EmployeeDropdown({employees,onClick,title,btnStyle,divStyle,disabled,noDropdownToggle,selected = false}) {
	const [ filtered, setFiltered ] = useState([])
	const [ search, setSearch ] = useState('')
	const id = 'employeeDropdown'+Math.random()
	const [ opened, setOpened ] = useState(false)
	const inputEl = useRef(null)

	useEffect(()=>{
		employees?.length > 0 && setFiltered(search.length > 0 ?
			employees.filter(emp=>(emp.FirstName + ' ' + emp.LastName)?.toLowerCase().indexOf(search)>-1)
			:
			employees
		)
	},[employees,search])

	// console.log('opened',opened)
	// useEffect(()=>{
	// 	opened && inputEl.current.focus()
	// },[opened])

	const hide = ()=>{$(`#${id}`).dropdown('hide')}
	function setFocus(){
		inputEl.current.focus()
	}
	// const show = ()=>{$(`#${id}`).dropdown('show')}

	const selectEmp = (empId) => {onClick(empId);setSearch('')}
	const selectedEmp = selected ? employees.find(emp=>(emp.EmpID === selected)) : false

	return (
		<div className={divStyle??'dropdown'}>
			<button
				className={'btn btn-block btn-'+(btnStyle ?? 'default') +(noDropdownToggle?'':' dropdown-toggle ')}
				data-toggle={'dropdown'}
				type={'button'}
				onClick={setFocus}
				id={id}
				disabled={disabled}
			>
				{title ?
					title
					:
					selectedEmp ?
						<Fragment>
							{selectedEmp.FirstName} {selectedEmp.LastName}
							{!noDropdownToggle ?
								false
								:
								<span className={'float-right'}>
									<span className={'fas fa-caret-down'}/>
								</span>
							}
						</Fragment>
						: 'Employees'
				}
			</button>
			<div className={'dropdown-menu short-dropdown-menu'}>
				<div className={'form-group px-3'}>
					<label>Search</label>
					<div className={'input-group'}>
						<input
							className={'form-control'}
							onChange={({target})=>setSearch(target.value)}
							placeholder={'Search for Employee'}
							value={search}
							ref={inputEl}
						/>
						{search.length > 0 &&
						<div className={'input-group-append'}>
							<button
								className={'btn btn-outline-secondary'}
								onClick={()=>{
									setSearch('')
									setFocus()
									console.log('clear',inputEl.current)
									setTimeout(()=>{
										$(`#${id}`).dropdown('show')
									},10)
								}}
							>
								<span className={'far fa-times'}/>
							</button>
						</div>}
					</div>
				</div>
				<div className={'dropdown-divider'}/>
				{search?.length === 0 &&
					<a
						className={'dropdown-item pointer'}
						onClick={() => {
							selectEmp(0)
						}}
					>
						None
					</a>
				}
				{filtered?.length === 0 && search?.length > 0 && employees?.length > 0 ?
					<a className={'dropdown-item'}>No search results</a>
					:
					filtered.map(emp=>(
					<a
						key={emp.EmpID}
						className={'dropdown-item pointer'}
						onClick={()=>{selectEmp(emp.EmpID)}}
					>
						{emp.FirstName} {emp.LastName}
					</a>
				))}
			</div>
		</div>
	)
}