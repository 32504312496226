import React, {useState, useEffect} from 'react'
import axios from '../../../axios';
import filter from "lodash/filter";

export const PhoneListContext = React.createContext({})

export default function GlobalState(props) {
	const [ phoneListLoading, setPhoneListLoading ] = useState(true)
	const [ phoneList, setPhoneList ] = useState([])
	const [ roomNumbers, setRoomNumbers ] = useState([])
	const [ faxNumbers, setFaxNumbers ] = useState([])

	useEffect(()=>{
		axios.get(`/ncs/employee/1/all/json`)
			.then(({data})=>{
				setPhoneListLoading(false)
				const phoneListFiltered = filter(data,list=>{
					return list.ext;
				})
				setPhoneList(phoneListFiltered)
				window?.localStorage.setItem('phoneListTable',JSON.stringify(phoneListFiltered))
			})
		axios.get(`/ncs/employee/1/misc/json`)
			.then(({data})=>{
				const rooms = []
				const fax = []
				data.map(row=>{
					if(row.type === '1'){
						rooms.push(row)
					}
					else{
						fax.push(row)
					}
				})
				setRoomNumbers(rooms)
				setFaxNumbers(fax)
				window?.localStorage.setItem('roomListTable',JSON.stringify(rooms))
				window?.localStorage.setItem('faxListTable',JSON.stringify(fax))
			})
	},[])

	return (
		<PhoneListContext.Provider
			value={{
				phoneListLoading,
				phoneList,
				roomNumbers,
				faxNumbers
			}}
		>
			{props.children}
		</PhoneListContext.Provider>
	)
}