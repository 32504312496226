import _ from 'lodash';
import {
	ROLLBACK_MODAL,
	SET_ROLLBACK,
	CHANGE_PROCESS_ID_ROLLBACK,
	CHANGE_ROLLBACK_EMP
} from './action';

export default function RollbackModalReducer(state = {}, action) {
	switch (action.type) {
		case ROLLBACK_MODAL:
			return {...state,show:action.open};
		case SET_ROLLBACK:
			let process_id;
			let emp_id;
			if(!_.isEmpty(action.stages)) {
				process_id = action.stages[0].process_id;
				emp_id = action.stages[0].emp_id;
			}
			else{
				process_id = 0;
				emp_id = 0;
			}
			return {...state,stages:action.stages,process_id,emp_id};
		case CHANGE_PROCESS_ID_ROLLBACK:
			return {...state,process_id:action.process_id,emp_id:state.stages.filter(stage=>(stage.process_id == action.process_id))[0].emp_id};
		case CHANGE_ROLLBACK_EMP:
			return {...state,emp_id:action.emp_id};
		default:
			return state;
	}
}