import axios from '../axios'
import Cookies from 'js-cookie'
const employee = Cookies.getJSON('employee')
const API_KEY = employee?.api_key

const ACCEPT = {
  JSON: 'application/json',
}

const BASE_URL = process.env.API_HOST

/**
 * define service endpoints
 * @type {String}
 */

/**
 * Create an Axios Client with defaults
 */
axios.defaults.headers.common['X-Api-Key'] = API_KEY;
const client = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json'
	}
});

/**
 * Request Wrapper with default success/error actions
 */
const request = function(options) {
	const onSuccess = function(response) {
		return response.data
	};

	const onError = function(error) {
		if(axios.isCancel(error)){
			// console.log('cancelled')
			return {cancelled:true}
		}
		else if (error.response) {
			// Request was made but server responded with something
			// other than 2xx
			console.error('Status:', error.response.status);
			console.error('Data:', error.response.data);
			console.error('Headers:', error.response.headers);
		} else {
			// Something else happened while setting up the request
			// triggered the error
			console.error('Error Message:', error.message);
		}
		console.error('Request Failed:', error.config);

		return Promise.reject(error.response || error.message);
	};

	return axios(options)
		.then(onSuccess)
		.catch(onError)
};

export default request
