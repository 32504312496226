import Head from 'next/head'
import {useState,useEffect} from "react";
const assets = process.env.ASSETS_URL
const ENV = process.env.ENVIRONMENT

export default function PortalHead(props){
	const [addSupport,setAddSupport] = useState(false)
	const [navbarRight,setNavbarRight] = useState(null)
	const [loaded,setLoaded] = useState(false)

	useEffect(()=>{
		setLoaded(true)
	},[])

	useEffect(()=>{
		if(document) {
			const width = document.getElementById('navbar_right')?.offsetWidth
			if(width){
				setNavbarRight(width)
			}
		}
	},[loaded])

	useEffect(()=>{
		// console.log('nav bar',navbarRight)
		if(navbarRight && !addSupport) {
			setAddSupport(true)
			window.zESettings = {
				webWidget: {
					offset: {horizontal: navbarRight + 'px', vertical: '-5px'},
					position: {horizontal: 'right', vertical: 'top'},

				}
			}
		}
	},[navbarRight,addSupport])

	return (
		<Head>
			<title>Welcome to Portal</title>
			<meta charSet="utf-8"/>
			<meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
			<link href={`${assets}/ncs-bootstrap-4/latest/css/bootstrap.min.css`} rel="stylesheet"/>
			<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"/>
			{/*<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js" integrity="sha384-b/U6ypiBEHpOf/4+1nzFpr53nxSS+GLCkfwBdFNTxtclqqenISfwAzpKaMNFNmj4" crossOrigin="anonymous"/>*/}
			<script src={`${assets}/popper.min.js`}/>
			<script src={`${assets}/ncs-bootstrap-4/latest/js/bootstrap.min.js`} rel="stylesheet"/>
			<script src={`${assets}/moment/latest/min/moment.min.js`}/>
			<link href={`${assets}/bootstrap-datetimepicker/latest/css/bootstrap-datetimepicker.min.css`} rel="stylesheet" type="text/css"/>
			<script src={`${assets}/bootstrap-datetimepicker/latest/js/bootstrap-datetimepicker.min.js`}/>
			<link rel="shortcut icon" href="/images/favicon.ico" type="image/vnd.microsoft.icon"/>
			{addSupport && (ENV == 'PRODUCTION' || ENV == 'TESTING') && <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=b1c90300-8276-4885-9910-6106c42b1ca7"/>}
		</Head>
	)
}