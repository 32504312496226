import {useState} from 'react'
import DocPreviewer from "./DocPreviewer";

export default function DocThumbnail(props) {
	const [ preview, setPreview ] = useState(false)
	const { url, docId, cabinet } = props
	// console.log('preview',preview)
	return (
		<div className={'pointer'} onClick={()=>{setPreview(true)}}>
			<img
				style={{
					border: '1px solid #eaeaea',
					borderBottom: '2px solid #d6d6d6',
					borderRadius: '4px',
					padding: "5px",
					boxShadow: 'rgb(224, 224, 224) 0px 4px 17px',
					maxHeight: "90px",
					transition: '.2s',
					backgroundColor: "white"
				}}
				src={url}
				alt={'Docuware Thumbnail'}
			/>
			<DocPreviewer docId={docId} cabinet={cabinet} show={preview} onClose={()=>{setPreview(false)}}/>
		</div>
	)
}